import { Injectable } from '@angular/core';
import { BazisAuthService } from '@bazis/shared/services/auth.service';
import {
    combineLatest,
    merge,
    Observable,
    of,
    pairwise,
    shareReplay,
    Subject,
    switchMap,
    withLatestFrom,
} from 'rxjs';
import { AUTH_PAGE_URL, ROLE, SHARE_REPLAY_SETTINGS } from '@app/configuration.service';
import { buildFilterStr } from '@bazis/utils';
import { debounceTime, filter, map, startWith, tap } from 'rxjs/operators';
import { EntList } from '@bazis/shared/models/srv.types';

@Injectable({
    providedIn: 'root',
})
export class SwAuthService extends BazisAuthService {
    private _isFirstRedirect = true;

    updateAgencyContract$ = new Subject();

    agencyContract$ = merge(this.updateAgencyContract$, this.organizationId$, this.role$).pipe(
        withLatestFrom(this.organizationId$, this.role$),
        switchMap(([original, organizationId, role]) =>
            organizationId && (role === ROLE.wasteRecycling || role === ROLE.wasteTransportation)
                ? this.srv.fetchAllEntities$('long_term.agency_contract', '', {
                      filter: buildFilterStr({ org_owner: organizationId }),
                      sort: '-dt_created',
                  })
                : of(null),
        ),
        map((list: EntList) =>
            list
                ? list.list.find((v) => v.$snapshot.status !== 'draft') || list.list[0] || null
                : null,
        ),
        tap((contract) => {
            if (contract)
                this.storageService.setItem('long_term.agency_contract', contract, contract.id);
        }),
        startWith(undefined),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    updateBuyerContract$ = new Subject();

    buyerContract$ = merge(this.updateBuyerContract$, this.organizationId$, this.role$).pipe(
        withLatestFrom(this.organizationId$, this.role$),
        switchMap(([original, organizationId, role]) =>
            organizationId && role === ROLE.buyer
                ? this.srv.fetchAllEntities$('sales.sales_agency_contract', '', {
                      filter: buildFilterStr({ org_owner: organizationId }),
                      sort: '-dt_created',
                  })
                : of(null),
        ),
        map((list: EntList) =>
            list
                ? list.list.find((v) => v.$snapshot.status !== 'draft') || list.list[0] || null
                : null,
        ),
        tap((contract) => {
            if (contract)
                this.storageService.setItem('sales.sales_agency_contract', contract, contract.id);
        }),
        startWith(undefined),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    updatePartnerContract$ = new Subject();

    partnerContract$ = merge(this.updatePartnerContract$, this.organizationId$, this.role$).pipe(
        withLatestFrom(this.organizationId$, this.role$),
        switchMap(([original, organizationId, role]) =>
            organizationId && role === ROLE.referral
                ? this.srv.fetchAllEntities$('long_term.partner_contract', '', {
                      filter: buildFilterStr({ org_owner: organizationId }),
                      sort: '-dt_created',
                  })
                : of(null),
        ),
        map((list: EntList) =>
            list
                ? list.list.find((v) => v.$snapshot.status !== 'draft') || list.list[0] || null
                : null,
        ),
        tap((contract) => {
            if (contract)
                this.storageService.setItem('long_term.partner_contract', contract, contract.id);
        }),
        startWith(undefined),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    defaultPageRedirect$ = this.role$.pipe(
        debounceTime(0),
        withLatestFrom(this.user$),
        startWith([null, null]),
        pairwise(),
        filter(([prevData, currentData]) => {
            const [prevRole, prevUser] = [...prevData];
            const [currentRole, currentUser] = [...currentData];
            return prevUser?.id !== currentUser?.id;
        }),
        tap(([prevData, currentData]) => {
            const [prevRole, prevUser] = [...prevData];
            const [currentRole, currentUser] = [...currentData];
            const regexp = /^\/(faq|about|legal\-documents){1}(\/.*)*$/;
            if (
                currentUser &&
                !currentUser.$snapshot.raw_password &&
                !regexp.test(location.pathname) &&
                currentUser.$snapshot.created_type_auth !== 'cert' &&
                currentUser.id !== prevUser?.id
            ) {
                this.router.navigate(['/login/init-password']);
                this._isFirstRedirect = false;
                return;
            }
            if (location.pathname.indexOf(AUTH_PAGE_URL) > -1 && !currentUser) return;
            this.redirectToDefaultPage(currentRole, currentUser);
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    requestRolesForCompanyUser$ = combineLatest([this.roles$, this.role$]).pipe(
        map(([roles, role]) => {
            roles = roles.filter((v) => !v.$snapshot.is_system);
            if (role === ROLE.wasteOperator) return roles;
            return roles.filter((v) => v.$snapshot.slug !== ROLE.director);
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    hasPartnerRole$: Observable<boolean | string> = combineLatest([this.roles$, this.user$]).pipe(
        map(([roles, user]) => {
            if (!user) return false;
            const partnerRole = roles.find((v) => v.$snapshot.slug === ROLE.referral);
            if (!partnerRole) return false;
            if (user.$snapshot.roles.find((role) => role.id === partnerRole.id))
                return partnerRole.id;
            return false;
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    isPartner$: Observable<boolean> = this.role$.pipe(
        map((role) => role === ROLE.referral),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    canBecomePartner$: Observable<boolean> = this.role$.pipe(
        map(
            (role) =>
                [ROLE.anonymous, ROLE.wasteOperator, ROLE.driver, ROLE.wasteAcceptor].indexOf(
                    role,
                ) === -1,
        ),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    // redirect to default page
    redirectToDefaultPage(role = null, user) {
        switch (role) {
            case ROLE.anonymous:
            case ROLE.base:
                // do not redirect from public pages to user-initial
                const regexp = /^\/(faq|about|legal\-documents|referral){1}(\/.*)*$/;
                if (!regexp.test(location.pathname)) {
                    this.router.navigate(['/user-initial']);
                }
                break;
            case ROLE.driver:
            case ROLE.wasteAcceptor:
                this.router.navigate(['/mobile-role']);
                break;
            case ROLE.referral:
                if (location.pathname === '/') {
                    this.router.navigate(['/referral/summary']);
                    break;
                }
            default:
                if (location.pathname.indexOf('access-denied') > -1 && !this._isFirstRedirect) {
                    this.router.navigate(['/']);
                } else if (
                    location.pathname.indexOf(AUTH_PAGE_URL) > -1
                    // to prevent redirect on home page when first entered page was access - denied
                ) {
                    if (user.$snapshot.roles.length === 1) this.router.navigate(['/']);
                    else this.router.navigate(['/login/select-role']);
                }
        }
        this._isFirstRedirect = false;
    }
}
