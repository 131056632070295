import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'bazis-card-value',
    templateUrl: './card-value.component.html',
    styleUrls: ['card-value.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class CardValueElement {
    @Input() active: boolean = false;

    @Input() disabled: boolean = false;

    @HostBinding('class') get cardStyle() {
        return {
            'bazis-card-value--active': this.active,
            'bazis-card-value--disabled': this.disabled,
        };
    }

    constructor() {}
}
