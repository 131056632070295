<div
    class="bazis-context-menu"
    [ngClass]="[
        contextMenu.options.cssClass,
    ]"
    [style.--position-x]="contextMenu.options.positionX"
    [style.--position-y]="contextMenu.options.positionY"
>
    <ng-template contextMenu></ng-template>
</div>
