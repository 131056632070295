import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewEncapsulation,
} from '@angular/core';
import { INITIAL_CONFIG } from 'ngx-mask';

interface SegmentItem {
    id: string | number;
    title?: string;
    titleKey?: string;
    titleParams?: any;
    entityType?: string;
    disabled?: boolean;
    // name иконки или src
    icon?: string;
    // iconPosition?: 'start' | 'end';
    hidden?: boolean;
}

@Component({
    selector: 'bazis-segment',
    templateUrl: './segment.component.html',
    styleUrls: ['segment.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class SegmentElement implements OnInit {
    @Input()
    segmentList: SegmentItem[];

    @Input()
    selectedSegment: string | number;

    @Input()
    hideByZeroCount = false;

    @Input() segmentTemplate: TemplateRef<any>;

    @Input() isEqualParts: boolean = false;

    @Input() scrollable: boolean = false;

    @Output()
    selectedSegmentChange = new EventEmitter();

    ngOnInit(): void {
    }

    selectSegment(segment: string | number) {
        this.selectedSegment = segment;
        this.selectedSegmentChange.emit(this.selectedSegment);

        // if (!this.needScroll) return;
        // setTimeout необходим, чтобы не приходилось кликать дважды на кнопку, если передано Output значение
        // данный баг двойного клика наблюдается в FF MAC OS
        // setTimeout(() => this.viewportScroller.scrollToPosition([0, 0]), 0);
    }
}
