<ng-container *ngIf="{ list: list$ | async } as data"></ng-container>
<div class="bazis-gallery-slider">
    <div class="bh-overhide">
        <div
            #sliderContainer
            class="bazis-list bazis-list_gallery bazis-slider__content"
            [style.transform]="'translateX(' + shift + 'px)'"
            swipe
            (swipeLeft)="slideEnd()"
            (swipeRight)="slideStart()"
        >
            <ng-content></ng-content>
        </div>
    </div>
    <bazis-button
        fill="outline"
        color="action"
        class="bazis-gallery-slider__prev"
        [disabled]="shift === 0"
        (click)="slideStart()"
    >
        <bazis-icon slot="icon-only" name="angle-small-left"></bazis-icon>
    </bazis-button>
    <bazis-button
        fill="outline"
        color="action"
        class="bazis-gallery-slider__next"
        [disabled]="isEndSlider"
        (click)="slideEnd()"
    >
        <bazis-icon slot="icon-only" name="angle-small-right"></bazis-icon>
    </bazis-button>
</div>
