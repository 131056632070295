import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonElement } from './button.component';
import { createCustomElement } from '@angular/elements';
import { IconModule } from '@bazis/shared/components/icon/icon.module';
import { BadgeModule } from '@bazis/shared/components/badge/badge.module';

@NgModule({
    declarations: [ButtonElement],
    imports: [CommonModule, IconModule, BadgeModule],
    exports: [ButtonElement],
})
export class ButtonModule implements DoBootstrap {
    constructor(private injector: Injector) {}

    ngDoBootstrap(): void {
        const el = createCustomElement(ButtonElement, {
            injector: this.injector,
        });

        customElements.define('bazis-button', el);
    }
}
