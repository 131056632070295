import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { auditTime, BehaviorSubject, combineLatest, Observable, shareReplay } from 'rxjs';
import { debounceTime, filter, map, tap } from 'rxjs/operators';
import { SHARE_REPLAY_SETTINGS } from '@bazis/configuration.service';
import { Options } from 'ngx-slider-v2';
import { TemplateObservable } from '@bazis/shared/classes/template-observable';
import moment from 'moment';

@Component({
    selector: 'bazis-timeline',
    templateUrl: './timeline.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineComponent implements OnInit, OnDestroy {
    @HostBinding('class') get hostClass() {
        return {
            'bazis-timeline': true,
        };
    }

    @Output()
    timeChange = new EventEmitter();

    @Input()
    trackerTimeLine$: Observable<number[]>;

    basicStep = 300000;

    @Input()
    sliderOptions: Options = {
        floor: 0,
        ceil: 86400000,
        step: this.basicStep,
    };

    trackerLabels$;

    chart$;

    showChart = true;

    dateTimeBorders = [0, 0];

    speedMultiplier$ = new BehaviorSubject(1);

    basicTimeInterval = 400;

    isPlaying$ = new BehaviorSubject(false);

    emitTimeInterval;

    timeToEmit = new TemplateObservable(null);

    timeToEmit$ = this.timeToEmit.$.pipe(debounceTime(0), shareReplay(SHARE_REPLAY_SETTINGS));

    speedMultipliers = [1, 2, 4];

    playing$ = combineLatest([this.speedMultiplier$, this.isPlaying$]).pipe(
        map(([speedMultiplier, isPlaying]) => {
            this.clearInterval();
            if (!isPlaying) {
                return;
            }
            this.emitTimeInterval = setInterval(() => {
                this.timeToEmit.set(this.timeToEmit._ + this.basicStep);
                if (this.timeToEmit._ >= this.dateTimeBorders[1]) {
                    this.clearInterval();
                    this.isPlaying$.next(false);
                    return;
                }
            }, this.basicTimeInterval / speedMultiplier);
            return true;
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    emitting$ = this.timeToEmit$.pipe(
        tap((timeToEmit) => {
            this.timeChange.emit(timeToEmit);
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    diffValue = 0;

    constructor() {}

    ngOnInit() {
        this.trackerLabels$ = this.trackerTimeLine$.pipe(
            map((period) => {
                this.dateTimeBorders = [period[0], period[1]];
                this.diffValue = (period[1] - period[0]) / 100;
                this.timeToEmit.set(this.dateTimeBorders[1]);
                this.sliderOptions = {
                    ...this.sliderOptions,
                    floor: this.dateTimeBorders[0],
                    ceil: this.dateTimeBorders[1],
                };

                const stepSize = (period[1] - period[0]) / 12;
                const labels = [period[0]];

                while (labels[labels.length - 1] <= period[1] - stepSize) {
                    labels.push(labels[labels.length - 1] + stepSize);
                }
                return labels.map((label) => moment(label).format('HH:mm'));
            }),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );
    }

    setSpeedMultiplier(value) {
        this.speedMultiplier$.next(value);
    }

    clearInterval() {
        if (this.emitTimeInterval) {
            clearInterval(this.emitTimeInterval);
            this.emitTimeInterval = null;
        }
    }

    ngOnDestroy(): void {
        this.clearInterval();
    }

    setPlaying(play) {
        if (play && this.timeToEmit._ >= this.dateTimeBorders[1]) {
            this.timeToEmit.set(this.dateTimeBorders[0]);
        }
        this.isPlaying$.next(play);
    }

    setValueFromSlider(value, isPlaying = false, speedMultiplier = 0) {
        if (!isPlaying) {
            this.timeToEmit.set(value);
            return;
        }
        if (Math.abs(value - this.timeToEmit._) > this.diffValue) {
            this.timeToEmit.set(value);
            this.setPlaying(false);
        }
    }
}
