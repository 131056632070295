<ng-container
    *ngIf="{
        list: list$ | async
    } as data"
>
    <div
        scroll
        (scrollToBottom)="needNextPage$.next(true)"
        class="bazis-infinite-scroll-content"
        *ngIf="data.list?.length > 0 || emptyListKey"
    >
        <ng-container *ngFor="let item of data.list; let i = index; trackBy: trackById">
            <ng-template
                [ngTemplateOutlet]="itemTpl"
                [ngTemplateOutletContext]="{ item: item }"
            ></ng-template>
        </ng-container>
        <div
            *ngIf="data.list?.length === 0 && emptyListKey"
            class="bazis-item bh-events-none"
        >
            {{ emptyListKey | transloco }}
        </div>
    </div>
</ng-container>
