import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-button-print',
    templateUrl: './button-print.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonPrintComponent {
    printPage() {
        window.print();
    }
}
