<ng-container *transloco="let t">
    <div
        *ngIf="{
            required: required$ | async,
        } as data"
        #inputDate
        clickOutside
        (clickOutside)="clickPopperOutside()"
        [excludeElement]="calendar"
    >
        <div class="bazis-control">
            <label
                *ngIf="titleKey"
                class="bazis-control__label"
            >
                {{ t(titleKey) }}
                <span
                    color="danger"
                    *ngIf="data.required"
                >
                    *
                </span>
            </label>

            <div
                class="bazis-control__field-group bh-pointer"
                [class.bazis-control__field-group--disabled]="
                    ngControl.control.disabled || disabled
                "
                (click)="openCalendar($event)"
            >
                <div class="bazis-control__field-wrapper">
                    <div class="bazis-control__field">
                        {{
                            dateValue.value
                                ? dateValue.value
                                : placeholder
                                ? placeholder
                                : placeholderKey
                                ? t(placeholderKey)
                                : ''
                        }}
                    </div>
                </div>
                <div class="bazis-control__field-group__end">
                    <bazis-button
                        *ngIf="ngControl.control.value && hasEraser"
                        fill="clear"
                        (click)="onClear()"
                        class="bazis-eraser"
                    >
                        <bazis-icon
                            slot="icon-only"
                            name="cross-small"
                        ></bazis-icon>
                    </bazis-button>
                    <bazis-button fill="clear">
                        <bazis-icon
                            name="calendar"
                            slot="icon-only"
                        ></bazis-icon>
                    </bazis-button>
                </div>
            </div>

            <div class="bazis-control__error">
                <bazis-form-error
                    [validationErrorMessages]="validationErrorMessages"
                    [formControl]="ngControl.control"
                ></bazis-form-error>

                <!-- для ошибок родительского контрола
                (пример использоавния в input-text-array, предполагается сюда "вложить" bazis-form-error) -->
                <ng-content></ng-content>
            </div>
            <div
                *ngIf="noteKey"
                class="bazis-control__note"
            >
                {{ t(noteKey, noteParams) }}
            </div>
        </div>

        <div
            #calendar
            class="bh-popper-z-index"
            [hidden]="!showCalendar"
            (click)="clickPopper()"
        >
            <bazis-datepicker
                *ngIf="showCalendar"
                [value]="calendarValue"
                (valueChange)="setValueFromCalendar($event)"
                [minDate]="minDate"
                [maxDate]="maxDate"
                [formatOutput]="displayFormat"
                [startYear]="startYear"
                [disabledIntervals]="disabledIntervals"
                [marks$]="marks$"
                typeHeadCalendar="left"
            ></bazis-datepicker>
        </div>
    </div>
</ng-container>
