import { DoBootstrap, Injector, NgModule } from '@angular/core';

import { ColElement } from './col.component';
import { createCustomElement } from '@angular/elements';

@NgModule({
    declarations: [ColElement],
    exports: [ColElement],
})
export class ColModule implements DoBootstrap {
    constructor(private injector: Injector) {}

    ngDoBootstrap(): void {
        const el = createCustomElement(ColElement, {
            injector: this.injector,
        });

        customElements.define('bazis-col', el);
    }
}
