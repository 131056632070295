<ng-container *transloco="let t">
    <ng-container *ngIf="groupData">
        <bazis-select
            class="bh-popper-z-index"
            [formControl]="selectedYear"
            [options]="yearsList"
            [multiple]="false"
        ></bazis-select>

        <bazis-tabs
            class="bh-margin-bottom-3x"
            [tabList]="monthesTabs"
            [(selectedTab)]="selectedMonth"
            [needScroll]="false"
        ></bazis-tabs>

        <div class="bazis-chart-column">
            <ng-container *ngFor="let day of days">
                <ng-container
                    *ngIf="
                        groupData[this.selectedYear.value] &&
                        groupData[this.selectedYear.value][this.selectedMonth] &&
                        groupData[this.selectedYear.value][this.selectedMonth][day] !== undefined
                    "
                >
                    <div
                        class="bazis-chart-column__item"
                        [class.bazis-chart-column__item--current]="
                            +selectedYear.value === currentYear &&
                            selectedMonth === currentMonth + 1 &&
                            day === currentDay
                        "
                        [class.bazis-chart-column__item--selected]="
                            formattedSelected.indexOf(
                                this.selectedYear.value + '-' + this.selectedMonth + '-' + day
                            ) > -1
                        "
                        (click)="selectDate(day)"
                    >
                        <!--chart bars -->

                        <div class="bazis-chart-column__item__value">
                            {{
                                groupData[this.selectedYear.value][this.selectedMonth][day]
                                    | localNumber
                                        : (groupData[this.selectedYear.value][this.selectedMonth][
                                              day
                                          ] > 10
                                              ? '1.0-0'
                                              : '1.0-2')
                            }}
                        </div>
                        <div
                            class="bazis-chart-column__item__bar"
                            [style]="{
                                height:
                                    (groupData[this.selectedYear.value][this.selectedMonth][day] /
                                        max) *
                                        100 +
                                    '%',
                            }"
                        ></div>

                        <!--chart bottom -->
                        <div class="bazis-chart-column__item__order">
                            {{ day }}
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>

    <ng-template #empty>
        <div> {{ t('analytics.groups.empty') }}</div>
    </ng-template>

    <ng-container *ngIf="{ change: changes$ | async }"></ng-container>
</ng-container>
