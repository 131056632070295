import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

interface IButtonOptions {
    titleKey: string;
    fill?: string;
    color?: string;
    size?: string;
    iconStart?: string;
    iconEnd?: string;
}

@Component({
    selector: 'bazis-empty',
    templateUrl: './empty.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyComponent implements OnInit {
    @Input() titleKey: string = null;

    @Input() titleParams: any;

    @Input() messageKey: string = null;

    @Input() messageParams: any;

    @Input() cssClass: string = '';

    @Input() pathImg: string = '';

    @Input() buttonOptions: IButtonOptions;

    @Output()
    buttonClick = new EventEmitter();

    constructor() {}

    ngOnInit() {}

    addAction(event) {
        this.buttonClick.emit(event);
    }
}
