<ng-container *transloco="let t">
    <bazis-accordion
        [isOpen]="isOpen"
        [hasAngleIcon]="false"
        class="bazis-accordion_doc"
    >
        <div slot="title">
            <bazis-file-inline
                [file]="document"
                [hasLink]="false"
            ></bazis-file-inline>
        </div>

        <div
            slot="btns"
            class="bh-hide-xl-down bh-line-elements"
        >
            <bazis-button
                size="small"
                fill="clear"
                [href]="document.$snapshot.file_upload_url"
                [download]="document.$snapshot.name"
                target="_blank"
                color="action"
            >
                <bazis-icon
                    slot="icon-only"
                    name="download"
                ></bazis-icon>
            </bazis-button>
            <bazis-button
                size="small"
                fill="clear"
            >
                <bazis-icon
                    slot="icon-only"
                    name="print"
                ></bazis-icon>
            </bazis-button>
        </div>
        <div
            slot="btns"
            class="bh-hide-xl-up"
        >
            <div class="bazis-dropdown">
                <bazis-button
                    class="bazis-dropdown__trigger"
                    fill="clear"
                    size="small"
                    (click)="openDropdownMenu = !openDropdownMenu; $event.stopPropagation()"
                >
                    <bazis-icon
                        slot="icon-only"
                        name="menu-dots"
                        size="s"
                    ></bazis-icon>
                </bazis-button>

                <div
                    *ngIf="openDropdownMenu"
                    class="bazis-dropdown__body"
                >
                    <div class="bazis-list bazis-list_select">
                        <a
                            [href]="document.$snapshot.file_upload_url"
                            [download]="document.$snapshot.name"
                            target="_blank"
                            class="bazis-item"
                        >
                            {{ t('action.download') }}
                        </a>
                        <a
                            [href]="document.$snapshot.file_upload_url"
                            class="bazis-item"
                            target="_blank"
                        >
                            {{ t('action.print') }}
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <iframe
            #iframePdf
            slot="body"
            class="iframe_desktop"
            [src]="(isPdf ? document.$snapshot.file_upload_url : nonPdfUrl) | safe : 'resourceUrl'"
            [attr.type]="document.$snapshot.isPdf ? 'application/pdf' : ''"
            (load)="onloadFrame(iframePdf)"
        ></iframe>
        <iframe
            #iframePdfMobile
            slot="body"
            class="iframe_mobile"
            [src]="nonPdfUrl | safe : 'resourceUrl'"
            [attr.type]="document.$snapshot.isPdf ? 'application/pdf' : ''"
            (load)="onloadFrame(iframePdfMobile)"
        ></iframe>
    </bazis-accordion>
</ng-container>
