import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';
import { AnalyticGroupData } from '@bazis/analytics/shared/models/analytic.types';

@Component({
    selector: 'bazis-analytics-horizontal-percentage-bars',
    templateUrl: './horizontal-percentage-bars.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisHorizontalPercentageBarsComponent implements OnInit {
    @Input()
    settings: { mode: 'full' | 'short' };

    @Input()
    groupData: AnalyticGroupData[];

    @Input()
    selectedValues: string[];

    @Output()
    select = new EventEmitter();

    constructor() {}

    ngOnInit(): void {
        this.settings.mode = this.settings?.mode || 'full';
    }
}
