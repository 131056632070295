import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'bazis-toolbar',
    template: `
        <div part="start">
            <slot name="start"></slot>
        </div>
        <div part="end">
            <slot name="end"></slot>
        </div>
    `,
    styleUrls: ['toolbar.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class ToolbarElement implements OnInit {
    @Input() direction: 'horizontal' | 'vertical' = 'horizontal';

    @Input() outline: boolean;

    @Input() align: 'top' | 'bottom' | 'center' | 'baseline' = 'center';

    @Input() adaptiveMode: 'nowrap' | 'wrap' | 'column' | 'column-reverse' = 'nowrap';

    @HostBinding('class') get toolbarStyle() {
        return {
            'bazis-toolbar_outline': this.outline,
            'bazis-toolbar_vertical': this.direction === 'vertical',
            [`bazis-toolbar_${this.align}`]: !!this.align,
            [`bazis-toolbar_${this.adaptiveMode}`]: !!this.adaptiveMode,
        };
    }

    constructor() {}

    ngOnInit(): void {
        this.outline = this.outline !== undefined && this.outline !== false;
    }
}
