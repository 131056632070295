import { Injectable } from '@angular/core';
import { BazisSrvService } from '@bazis/shared/services/srv.service';
import { shareReplay, withLatestFrom } from 'rxjs';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { map } from 'rxjs/operators';
import { BazisToastService } from '@bazis/shared/services/toast.service';
import { BazisDocumentService } from '@bazis/shared/services/document.service';
import { BazisWebSocketService } from '@bazis/shared/services/web-socket.service';
import { ConfigurationService, SHARE_REPLAY_SETTINGS } from '@app/configuration.service';
import { Router } from '@angular/router';
import { BazisPaymentService } from '@bazis/shared/services/payment.service';
import { SwAuthService } from '@shared/services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class SwPaymentService extends BazisPaymentService {
    public remainSum$ = this.paymentAccount$.pipe(
        withLatestFrom(this.authService.accountType$),
        map(([account, accountType]) => {
            if (!account) return 0;

            if (accountType === 'customer' && this.configurationService.isNominalAccountAvailable) {
                return account.$snapshot.sber_balance?.free_balance || 0;
            }

            if (accountType === 'executor' && this.configurationService.isNominalAccountAvailable) {
                return account.$snapshot.balance_info?.all || 0;
            }

            if (accountType === 'system') {
                return account.$snapshot.amount_common || 0;
            }

            return (
                (account.$snapshot?.amount_common || 0) - (account.$snapshot?.amount_reserved || 0)
            );
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    constructor(
        protected documentService: BazisDocumentService,
        protected entityService: BazisEntityService,
        protected srv: BazisSrvService,
        protected authService: SwAuthService,
        protected toastService: BazisToastService,
        protected socketService: BazisWebSocketService,
        protected router: Router,
        protected configurationService: ConfigurationService,
    ) {
        super(
            documentService,
            entityService,
            srv,
            authService,
            toastService,
            socketService,
            router,
        );
    }
}
