import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { createCustomElement } from '@angular/elements';
import { IconModule } from '@bazis/shared/components/icon/icon.module';
import { SegmentElement } from './segment.component';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';

@NgModule({
    declarations: [SegmentElement],
    imports: [CommonModule, RouterModule, IconModule, TranslocoModule],
    exports: [SegmentElement],
})
export class SegmentModule implements DoBootstrap {
    constructor(private injector: Injector) {}

    ngDoBootstrap(): void {
        const el = createCustomElement(SegmentElement, {
            injector: this.injector,
        });

        customElements.define('bazis-segment', el);
    }
}
