import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'bazis-entity-status-list-skeleton',
    templateUrl: './entity-status-list-skeleton.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityStatusListSkeletonComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
