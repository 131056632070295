<div
    [class.bazis-modal]="!modal.options.styleAlert"
    [ngClass]="[
        modal.options.cssClass,
        isScrollingContent ? 'bazis-modal--scrolling' : '',
        modal.options.modalType === 'fullscreen' ? 'bazis-container' : '',
        modal.options.modalType
    ]"
>

    <bazis-button fill="clear"
        *ngIf="modal.options.hasCloseIcon"
        class="bazis-modal__close"
        (click)="close()"
    >
        <bazis-icon name="cross" slot="icon-only"></bazis-icon>
        <!-- <bazis-icon class="bh-hide-sm-up" name="arrow-left" slot="icon-only"></bazis-icon> -->
    </bazis-button>

    <ng-template modal></ng-template>
</div>
<div class="bazis-backdrop"
    *ngIf="modal.options.hasBackdrop"
    (click)="closeFromBackdrop()"
></div>
