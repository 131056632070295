<ng-container *transloco="let t">
    <section>
        <h6>
            {{ t('profile.personalInfo.organizationDetails') }}
        </h6>
        <bazis-grid class="sl-grid_accordion">
            <bazis-row>
                <bazis-col size="12">
                    <bazis-browse-default
                        titleKey="profile.personalInfo.organization.title.fullName"
                        [value]="organizationSnapshot.name_full || organizationSnapshot.name_short"
                    ></bazis-browse-default>
                </bazis-col>
            </bazis-row>
            <bazis-row>
                <bazis-col size="4">
                    <bazis-browse-default
                        titleKey="title.inn"
                        [value]="organizationSnapshot.tin || emptyInputValue"
                    ></bazis-browse-default>
                </bazis-col>
                <bazis-col size="4">
                    <bazis-browse-default
                        titleKey="title.kpp"
                        [value]="organizationSnapshot.rrc || emptyInputValue"
                    ></bazis-browse-default>
                </bazis-col>
                <bazis-col size="4">
                    <bazis-browse-default
                        titleKey="title.ogrn"
                        [value]="organizationSnapshot.msrn || emptyInputValue"
                    ></bazis-browse-default>
                </bazis-col>
            </bazis-row>
            <bazis-row>
                <bazis-col sizeXs="12">
                    <bazis-browse-default
                        *ngIf="!organizationSnapshot.license_file"
                        titleKey="profile.personalInfo.organization.title.license"
                        [value]="emptyInputValue"
                    ></bazis-browse-default>
                    <div
                        *ngIf="organizationSnapshot.license_file | entity | async as file"
                        class="bazis-browse-default"
                    >
                        <div class="bazis-browse-default__title">
                            {{ t('profile.personalInfo.organization.title.license') }}
                        </div>
                        <bazis-file-inline [file]="file"></bazis-file-inline>
                    </div>
                </bazis-col>
            </bazis-row>
            <bazis-row>
                <bazis-col size="12">
                    <bazis-browse-default
                        titleKey="profile.personalInfo.organization.title.addressLegal"
                        [value]="organizationSnapshot.address_legal || emptyInputValue"
                    ></bazis-browse-default>
                </bazis-col>
            </bazis-row>
            <bazis-row>
                <bazis-col size="12">
                    <bazis-browse-default
                        titleKey="profile.personalInfo.organization.title.addressPost"
                        [value]="organizationSnapshot.address_post || emptyInputValue"
                    ></bazis-browse-default>
                </bazis-col>
            </bazis-row>
            <bazis-row>
                <bazis-col size="12">
                    <bazis-browse-default
                        titleKey="profile.personalInfo.organization.title.director"
                        [value]="
                            (organizationSnapshot.director_name || emptyInputValue) +
                            (organizationSnapshot.director_position
                                ? ', ' + organizationSnapshot.director_position
                                : '')
                        "
                    ></bazis-browse-default>
                </bazis-col>
            </bazis-row>
        </bazis-grid>
    </section>
</ng-container>
