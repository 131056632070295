import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'bazis-simple-context-menu',
    templateUrl: './simple-context-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleContextMenuComponent {
    @Input() menu: [
        {
            titleKey: string;
            titleParams?: { [index: string]: any };
            clickFn: () => void;
        },
    ];
}
