import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { TemplateObservable } from '@bazis/shared/classes/template-observable';
import { Loader, LoaderOptions } from '@bazis/shared/models/loader.types';

@Injectable({
    providedIn: 'root',
})
export class BazisLoaderService {
    private _defaultParams: LoaderOptions = {
        message: '',
    };

    loaders = new TemplateObservable([]);

    constructor() {}

    create(params: Partial<LoaderOptions>) {
        const loaderParams: LoaderOptions = {
            ...this._defaultParams,
            ...params,
        };

        const id = uuidv4();

        const loader: Loader = {
            options: loaderParams,
            id,
        };

        const loaders = this.loaders._;
        loaders.push(loader);

        this.loaders.set(loaders);

        return loader;
    }

    dismiss(loader) {
        let loaders = this.loaders._;
        loaders = loaders.filter((v) => v.id !== loader.id);
        this.loaders.set(loaders);
    }
}
