import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BazisLoaderService } from '@bazis/shared/services/loader.service';

@Component({
    selector: 'bazis-global-loader',
    templateUrl: './global-loader.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalLoaderComponent implements OnInit {
    @Input() centerX = 25;

    @Input() centerY = 25;

    @Input() radius = 14;

    constructor(public loaderService: BazisLoaderService) {}

    ngOnInit(): void {}
}
