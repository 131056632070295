import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';
import { BazisLocaleService } from '@bazis/shared/services/locale.service';

@Pipe({
    name: 'localNumber',
    pure: false,
})
export class LocalNumberPipe implements PipeTransform {
    constructor(private localeService: BazisLocaleService) {}

    transform(value: any, format: string, locale = this.localeService.locale) {
        return formatNumber(value, locale, format);
    }
}
