<ng-container *ngIf="{ list: list$ | async } as data"></ng-container>
<div
    class="bazis-slider"
    swipe
    (swipeLeft)="slideEnd()"
    (swipeRight)="slideStart()"
>
    <div class="bazis-slider__wrapper">
        <div
            class="bazis-slider__content"
            #sliderContainer
            [style.transform]="'translateX(' + shift + 'px)'"
        >
            <ng-content></ng-content>
        </div>
    </div>

    <div
        *ngIf="containerWidth < allSlidesWidth"
        class="bazis-slider__controls"
    >
        <bazis-button
            [fill]="typeButton"
            color="action"
            [size]="sizeButton"
            class="bazis-slider__prev"
            [disabled]="shift === 0"
            (click)="slideStart()"
        >
            <bazis-icon
                slot="icon-only"
                [icon]="iconPrev"
            ></bazis-icon>
        </bazis-button>

        <bazis-button
            [fill]="typeButton"
            color="action"
            [size]="sizeButton"
            class="bazis-slider__next"
            [disabled]="isEndSlider"
            (click)="slideEnd()"
        >
            <bazis-icon
                slot="icon-only"
                [icon]="iconNext"
            ></bazis-icon>
        </bazis-button>
    </div>

    <!-- {{ swipesCounter }} [disabled]="zoomMinExceeded.$ | async" -->
    <ng-container *ngIf="list && hasIndicator">
        <div
            *ngIf="swipesCounter.$ | async as counter"
            class="bazis-slider__indicator"
        >
            <div
                *ngFor="let indicator of [].constructor(counter); let dot = index"
                class="bazis-slider__indicator__item"
                [class.bazis-slider__indicator__item--active]="dot === currentSwipe"
            >
            </div>
        </div>
    </ng-container>
</div>
