import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { BazisModalService } from '@bazis/shared/services/modal.service';

@Component({
    selector: 'bazis-modals',
    templateUrl: './modals.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalsComponent implements OnDestroy, OnInit {
    constructor(public modalService: BazisModalService) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}
}
