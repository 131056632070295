import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LinkElement } from './link.component';
import { createCustomElement } from '@angular/elements';
import { IconModule } from '@bazis/shared/components/icon/icon.module';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [LinkElement],
    imports: [CommonModule, IconModule, RouterModule],
    exports: [LinkElement],
})
export class LinkModule implements DoBootstrap {
    constructor(private injector: Injector) {}

    ngDoBootstrap(): void {
        const el = createCustomElement(LinkElement, {
            injector: this.injector,
        });

        customElements.define('bazis-link', el);
    }
}
