import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BazisModalService } from '@bazis/shared/services/modal.service';

// temporary component

@Component({
    selector: 'bazis-test-modal',
    templateUrl: './test-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestModalComponent implements OnDestroy, OnInit {
    @Input()
    message = '';

    @Input()
    title = '';

    constructor(private modalService: BazisModalService) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}

    closeModal() {
        this.modalService.dismiss({ prop1: 'any from inner comp' });
    }
}
