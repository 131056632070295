import { DoBootstrap, Injector, NgModule } from '@angular/core';

import { ToggleElement } from './toggle.component';
import { createCustomElement } from '@angular/elements';

@NgModule({
    declarations: [ToggleElement],
    exports: [ToggleElement],
})
export class ToggleModule implements DoBootstrap {
    constructor(private injector: Injector) {}

    ngDoBootstrap(): void {
        const el = createCustomElement(ToggleElement, {
            injector: this.injector,
        });

        customElements.define('bazis-toggle', el);
    }
}
