<ng-container *transloco="let t">
    <ng-container *ngIf="parts[0] === ''; else tplNumber">
        <div
            [class]="
                'bazis-vehicle-number bazis-vehicle-number--disabled bazis-vehicle-number_' +
                regionSuffix
            "
        >
            <span class="bazis-vehicle-number__noparts">
                {{ t('title.noVehicleNumber') }}
            </span>
            <span class="bazis-vehicle-number__part bazis-vehicle-number__part_3">---</span>
        </div>
    </ng-container>
    <ng-template #tplNumber>
        <div [class]="'bazis-vehicle-number bazis-vehicle-number_' + regionSuffix">
            <span
                *ngFor="let part of parts; let i = index"
                class="bazis-vehicle-number__part"
                [ngClass]="['bazis-vehicle-number__part_' + i]"
                >{{ part }}</span
            >
        </div>
    </ng-template>
</ng-container>
