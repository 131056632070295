import { Directive, Input, HostBinding } from '@angular/core';

@Directive({
    selector: '[sticky]',
})
export class StickyDirective {
    @Input() stickyTop: number | string;

    @Input() stickyBottom: number | string;

    @Input() stickyStart: number | string;

    @Input() stickyEnd: number | string;

    @Input() stickyZIndex: number;

    @HostBinding('class.bh-sticky') sticky: boolean = true;

    @HostBinding('style.--bazis-sticky-top') get topPosition() {
        if (this.stickyTop !== undefined) return `${this.stickyTop}px`;
    }

    @HostBinding('style.--bazis-sticky-bottom') get bottomPosition() {
        if (this.stickyBottom !== undefined) return `${this.stickyBottom}px`;
    }

    @HostBinding('style.--bazis-sticky-start') get startPosition() {
        if (this.stickyStart !== undefined) return `${this.stickyStart}px`;
    }

    @HostBinding('style.--bazis-sticky-end') get endPosition() {
        if (this.stickyEnd !== undefined) return `${this.stickyEnd}px`;
    }

    @HostBinding('style.--bazis-sticky-z') get zIndexLayer() {
        if (this.stickyZIndex !== undefined) return this.stickyZIndex;
    }

    constructor() {}
}
