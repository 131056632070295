<ng-container *transloco="let t">
    <div>
        <p class="bazis-p_major bh-font-medium">{{ t('crypto.info.title') }}</p>
        <p class="bazis-p_minor" [innerHTML]="t('crypto.info.description')"></p>
        <bazis-button
            class="bh-margin-top-6x"
            color="action"
            href="https://www.cryptopro.ru/products/cades/plugin"
            target="_blank"
        >
            {{ t('crypto.info.btn') }}
        </bazis-button>
    </div>
</ng-container>
