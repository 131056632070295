import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { BazisInputSearchComponent } from '@bazis/form/components/input-search.component';

@Component({
    selector: 'bazis-select-search',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisSelectSearchComponent extends BazisInputSearchComponent {
    // иконка, которую надо вывести слева
    @Input() leftIcon: string = null;

    // внешний вид, secondary - серый
    @Input() type: 'default' | 'secondary' = 'default';

    // отображение иконки лупы, вместо птички дропдауна
    @Input() hasSearchIcon: boolean = false;

    @Input() selectedValueTpl: TemplateRef<any>;

    isFocused = false;

    onClear() {
        this.values._.forEach((val) => {
            this.toggleValue(val);
        });
        this.ngControl.control.setValue(this.emptyValue || (this.multiple ? [] : null));
    }
}
