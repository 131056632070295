<ng-container *ngIf="isPreview; else tplGallery">
    <div
        *ngIf="imageEntities.length"
        class="bazis-gallery-preview"
    >
        <div
            *ngIf="
                imageEntities[0].id | entity : 'uploadable.file_upload' | async as image;
                else: tplLoading
            "
            class="bazis-figure"
            (click)="openGallery(imageEntities, 0)"
        >
            <img
                [src]="image.$snapshot.file"
                [alt]="imagesAlt ? imagesAlt[0] : image.$snapshot.name"
            />
        </div>
        <div
            *ngIf="imageEntities.length > 1"
            class="bazis-gallery-preview__more"
        >
            + {{ imageEntities.length - 1 }}
        </div>
    </div>
</ng-container>

<ng-template #tplGallery>
    <div class="bazis-list bazis-list_gallery">
        <div
            *ngFor="let imageEntity of imageEntities; let i = index"
            class="bazis-item"
        >
            <div
                *ngIf="imageEntity.id | entity : 'uploadable.file_upload' | async as image"
                class="bazis-figure bh-pointer"
                (click)="openGallery(imageEntities, i)"
            >
                <img
                    [src]="image.$snapshot.file"
                    [title]="image.$snapshot.title"
                    [alt]="imagesAlt ? imagesAlt[i] : image.$snapshot.name"
                />
            </div>
        </div>
    </div>
</ng-template>

<ng-template #tplLoading>
    <bazis-gallery-skeleton [isPreview]="isPreview"></bazis-gallery-skeleton>
</ng-template>
