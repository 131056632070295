import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SignStatusElement } from './sign-status.component';
import { createCustomElement } from '@angular/elements';
import { IconModule } from '@bazis/shared/components/icon/icon.module';

@NgModule({
    declarations: [SignStatusElement],
    imports: [CommonModule, IconModule],
    exports: [SignStatusElement],
})
export class SignStatusModule implements DoBootstrap {
    constructor(private injector: Injector) {}

    ngDoBootstrap(): void {
        const el = createCustomElement(SignStatusElement, {
            injector: this.injector,
        });

        customElements.define('bazis-sign-status', el);
    }
}
