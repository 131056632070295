import { Injectable } from '@angular/core';
import { BazisDocusignService } from '@bazis/signature/docusign/docusign.service';

@Injectable({
    providedIn: 'root',
})
export class SwDocusignService extends BazisDocusignService {
    integrationKey = 'c08621ca-9e7c-4edc-aa13-e7772ce73ff6';

    docuSignStyle = {
        /** High-level variables that mirror our existing branding APIs. Reusing the branding name here for familiarity. */
        /** does not support CSS variables **/
        branding: {
            primaryButton: {
                /** Background color of primary button */
                backgroundColor: '#164982',
                /** Text color of primary button */
                color: '#ffffff',
            },
        },

        /** High-level components we allow specific overrides for */
        signingNavigationButton: {
            finishText: 'You have finished the document!',
            position: 'bottom-center',
        },
    };
}
