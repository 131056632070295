<div
    part="header"
    class="bazis-accordion__header"
    (click)="toggle($event)"
>
    <bazis-toolbar>
        <div slot="start">
            <slot name="icon"></slot>
            <slot name="title"></slot>
        </div>
        <div slot="end">
            <slot name="btns"></slot>
            <div
                *ngIf="hasAngleIcon && collapsable"
                class="bazis-accordion__open-close"
            >
                <bazis-icon
                    color="action"
                    [icon]="isOpen ? iconUp : iconDown"
                    [size]="iconSize"
                ></bazis-icon>
            </div>
        </div>
    </bazis-toolbar>
    <div part="title-mobile">
        <slot name="title-mobile"></slot>
    </div>
</div>
<div
    *ngIf="isOpen && collapsable"
    part="body"
    class="bazis-accordion__body"
>
    <slot name="body"></slot>
</div>
