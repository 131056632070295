<div
    sticky
    [stickyTop]="50"
    class="bazis-list bazis-list_step"
>
    <div
        class="bazis-item"
        *ngFor="let item of [].constructor(10)"
    >
        <bazis-skeleton></bazis-skeleton>
    </div>
</div>
