import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { EntityFormControl, EntityFormGroup, EntityFormArray } from '@bazis/form/models/form.types';

const defaultRequiredFunction = (control: AbstractControl) => {
    if (
        control instanceof EntityFormControl ||
        control instanceof EntityFormArray ||
        control instanceof EntityFormGroup
    ) {
        return control.$config.required?._;
    }
    return false;
};

export class ConditionalValidators {
    public static required(
        condFn: (control: AbstractControl) => boolean = defaultRequiredFunction,
    ): ValidatorFn {
        return (control) => {
            if (!condFn(control)) {
                return null;
            }
            return Validators.required(control);
        };
    }

    public static custom(
        condFn: (control: AbstractControl) => boolean,
        validators: ValidatorFn | ValidatorFn[],
    ): ValidatorFn {
        return (control) => {
            if (!condFn(control)) {
                return null;
            }

            if (!Array.isArray(validators)) {
                return validators(control);
            }

            return validators
                .map((v) => v(control))
                .reduce((errors, result) =>
                    result === null ? errors : Object.assign(errors || {}, result),
                );
        };
    }
}
