<!-- TODO: diagram -->
<ng-container *ngIf="groupData">
    <div
        *ngIf="groupData?.length > 0"
        class="bazis-chart-circle"
        [chart]="chart"
    >
    </div>
    <div
        *ngFor="let item of groupData"
        class="bazis-percentage-block_medium"
        [class.bazis-percentage-block--active]="
            selectedValues && selectedValues.indexOf(item.id) > -1
        "
        (click)="select.emit(item.id)"
    >
        <div class="bh-flex bh-justify-content-between">
            <div>
                <bazis-badge
                    size="xs"
                    [color]="item.color_label"
                    [style.background]="''"
                    class="bh-margin-end-2x"
                ></bazis-badge>
                <span>{{ item.name || ('analytics.groups.emptyName' | transloco) }}</span>
            </div>
            <div>
                <span class="bh-padding-end-2x">{{ item.value | localNumber : '1.0-3' }}</span>
                <span class="bazis-percentage-block__percent"
                    >{{ item.fraction | localNumber : '1.0-2' }}%</span
                >
            </div>
        </div>
    </div>

    <ng-container
        [ngTemplateOutlet]="empty"
        *ngIf="groupData?.length === 0"
    >
    </ng-container>
</ng-container>

<ng-template #empty>
    <div *transloco="let t"> {{ t('analytics.groups.empty') }}</div>
</ng-template>
