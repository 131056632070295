<ng-container *transloco="let t">
    <ng-container
        *ngIf="{
            required: required$ | async,
            values: values.$ | async
        } as data"
    >
        <ng-container *ngIf="ngControl.control; let control">
            <div
                class="bazis-control"
                [class.bazis-control--activated]="showList"
                [class.bazis-control--dirty]="ngControl.control.value"
                clickOutside
                (clickOutside)="hideList()"
            >
                <span
                    class="bazis-control__label"
                    *ngIf="titleKey"
                >
                    {{ t(titleKey, titleParams) }}
                    <span
                        color="danger"
                        *ngIf="data.required"
                    >
                        *
                    </span>
                </span>

                <div
                    class="bazis-control__field-group"
                    [class.bazis-control__field-group--activated]="showList"
                    [class.bazis-control__field-group--disabled]="isDisabled"
                >
                    <div
                        *ngIf="leftIcon"
                        class="bazis-control__field-group__start"
                    >
                        <bazis-icon [icon]="leftIcon"></bazis-icon>
                    </div>
                    <ng-container *ngIf="tooltipSettings?.targetSide === 'left' && tooltipKey">
                        <div
                            #tooltipLeft
                            class="bazis-control__field-group__start"
                        >
                            <bazis-icon name="interrogation"></bazis-icon>
                        </div>
                        <bazis-tooltip
                            [reference]="tooltipLeft"
                            [eventType]="tooltipSettings?.eventType || 'hover'"
                            [placement]="tooltipSettings?.placement || 'bottom-start'"
                        >
                            {{ t(tooltipKey, tooltipParams) }}
                        </bazis-tooltip>
                    </ng-container>
                    <div class="bazis-control__field">
                        <!-- Прекрасный кастомный селект -->
                        <div
                            class="bazis-select"
                            [class.bazis-select_secondary]="type === 'secondary'"
                            (click)="toggleList()"
                        >
                            <div class="bazis-select__trigger">
                                <span>
                                    <ng-container
                                        *ngFor="let value of values.$ | async; let i = index"
                                    >
                                        <ng-container *ngIf="!value">{{
                                            placeholderKey ? t(placeholderKey) : ''
                                        }}</ng-container>
                                        <ng-container
                                            *ngIf="
                                                searchSettings.entityType !==
                                                'organization.organization_info'
                                            "
                                        >
                                            <ng-container
                                                *ngIf="
                                                    value | entity : entityType | async as entity
                                                "
                                            >
                                                <ng-container
                                                    *ngIf="infiniteListTpl && !selectedValueTpl"
                                                    [ngTemplateOutlet]="infiniteListTpl"
                                                    [ngTemplateOutletContext]="{ item: entity }"
                                                ></ng-container>

                                                <ng-container
                                                    *ngIf="selectedValueTpl"
                                                    [ngTemplateOutlet]="selectedValueTpl"
                                                    [ngTemplateOutletContext]="{ item: entity }"
                                                ></ng-container>

                                                <ng-container *ngIf="!infiniteListTpl">
                                                    <ng-container *ngIf="entity.$snapshot.name">
                                                        {{ entity.$snapshot.name }}
                                                    </ng-container>
                                                    <ng-container *ngIf="entity.$snapshot.nameKey">
                                                        {{
                                                            t(
                                                                entity.$snapshot.nameKey,
                                                                entity.$snapshot.nameParams
                                                            )
                                                        }}
                                                    </ng-container>
                                                </ng-container>
                                                {{ i < values._.length - 1 ? ',' : '' }}
                                            </ng-container>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="
                                                searchSettings.entityType ===
                                                'organization.organization_info'
                                            "
                                        >
                                            <ng-container
                                                *ngIf="value | organization | async as entity"
                                            >
                                                <ng-container
                                                    *ngIf="infiniteListTpl && !selectedValueTpl"
                                                    [ngTemplateOutlet]="infiniteListTpl"
                                                    [ngTemplateOutletContext]="{ item: entity }"
                                                ></ng-container>

                                                <ng-container
                                                    *ngIf="selectedValueTpl"
                                                    [ngTemplateOutlet]="selectedValueTpl"
                                                    [ngTemplateOutletContext]="{ item: entity }"
                                                ></ng-container>

                                                <ng-container *ngIf="!infiniteListTpl">
                                                    <ng-container *ngIf="entity.$snapshot.name">
                                                        {{ entity.$snapshot.name }}
                                                    </ng-container>
                                                    <ng-container *ngIf="entity.$snapshot.nameKey">
                                                        {{
                                                            t(
                                                                entity.$snapshot.nameKey,
                                                                entity.$snapshot.nameParams
                                                            )
                                                        }}
                                                    </ng-container>
                                                </ng-container>
                                                {{ i < values._.length - 1 ? ',' : '' }}
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </span>
                            </div>
                            <input
                                class="bazis-control__field"
                                [class.bazis-control__field--focused]="isFocused"
                                [formControl]="searchControl"
                                (focus)="isFocused = true; showList = true"
                                (blur)="isFocused = false"
                                [placeholder]="placeholderKey ? t(placeholderKey) : ''"
                            />
                        </div>
                    </div>
                    <ng-container *ngIf="tooltipSettings?.targetSide === 'right' && tooltipKey">
                        <div
                            #tooltipRight
                            class="bazis-control__field-group__end"
                        >
                            <bazis-icon name="interrogation"></bazis-icon>
                        </div>
                        <bazis-tooltip
                            [reference]="tooltipRight"
                            [eventType]="tooltipSettings?.eventType || 'hover'"
                            [placement]="tooltipSettings?.placement || 'bottom-end'"
                            >{{ t(tooltipKey, tooltipParams) }}</bazis-tooltip
                        >
                    </ng-container>
                    <div class="bazis-control__field-group__end">
                        <bazis-button
                            *ngIf="(searchValue$ | async) && !isDisabled && hasEraser"
                            fill="clear"
                            (click)="onClear()"
                            class="bazis-eraser"
                        >
                            <bazis-icon
                                size="s"
                                name="cross-small"
                            ></bazis-icon>
                        </bazis-button>
                        <bazis-button
                            *ngIf="hasSearchIcon"
                            fill="clear"
                            (click)="toggleList()"
                        >
                            <bazis-icon
                                size="s"
                                name="search"
                            ></bazis-icon>
                        </bazis-button>
                        <button
                            *ngIf="!hasSearchIcon"
                            (click)="toggleList()"
                        >
                            <bazis-icon
                                [name]="
                                    showList
                                        ? mqSrv.inIntervalBreakpoint('xs', 'lg')
                                            ? 'cross-small'
                                            : 'caret-up'
                                        : 'caret-down'
                                "
                                size="s"
                            ></bazis-icon>
                        </button>
                    </div>

                    <bazis-infinite-list
                        [hidden]="!showList"
                        [searchSettings]="searchSettings"
                        [emptyValue]="emptyValueEntity"
                        [excludeIds$]="excludeIds$"
                        emptyListKey="title.emptySearch"
                        [search$]="searchValue$"
                    >
                        <ng-template let-item="item">
                            <div
                                class="bazis-item"
                                [class.bazis-item--selected]="
                                    valuesMap &&
                                    valuesMap.has(
                                        searchSettings.fieldForId
                                            ? item.$snapshot[searchSettings.fieldForId]?.id
                                            : item.id
                                    )
                                "
                                [class.bazis-checkbox]="
                                    !mqSrv.inIntervalBreakpoint('xs', 'lg') && multiple
                                "
                                [class.bazis-radio]="
                                    mqSrv.inIntervalBreakpoint('xs', 'lg') && !multiple
                                "
                                (click)="
                                    toggleValue(
                                        searchSettings.fieldForId
                                            ? item.$snapshot[searchSettings.fieldForId]?.id
                                            : item.id
                                    )
                                "
                            >
                                <span
                                    [class.bazis-checkbox__label]="
                                        mqSrv.inIntervalBreakpoint('xs', 'lg') && multiple
                                    "
                                    [class.bazis-radio__label]="
                                        mqSrv.inIntervalBreakpoint('xs', 'lg') && !multiple
                                    "
                                >
                                    <ng-container
                                        *ngIf="infiniteListTpl"
                                        [ngTemplateOutlet]="infiniteListTpl"
                                        [ngTemplateOutletContext]="{ item: item }"
                                    ></ng-container>
                                    <ng-container *ngIf="!infiniteListTpl">
                                        {{ item.$snapshot.name || t(item.$snapshot.nameKey) }}
                                    </ng-container>
                                </span>
                            </div>
                        </ng-template>
                    </bazis-infinite-list>
                </div>

                <div class="bazis-control__error">
                    <bazis-form-error [formControl]="ngControl.control"></bazis-form-error>
                </div>

                <div
                    *ngIf="noteKey"
                    class="bazis-control__note"
                >
                    {{ noteKey }}
                </div>
            </div>
        </ng-container>
    </ng-container>
</ng-container>
