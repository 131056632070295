import { Injectable } from '@angular/core';
import { BazisModalService } from '@bazis/shared/services/modal.service';
import { ModalOrganizationRequisitesComponent } from '@pages/shared/components/requisites/modal-organization-requisites.component';

@Injectable({
    providedIn: 'root',
})
export class OrganizationService {
    constructor(private modalService: BazisModalService) {}

    openRequisites(organizationId = null) {
        if (!organizationId) return;
        this.modalService.create({
            component: ModalOrganizationRequisitesComponent,
            componentProperties: {
                organizationId,
            },
        });
    }
}
