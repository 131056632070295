<ng-container *transloco="let t">
    <ng-container
        *ngIf="{
            required: required$ | async,
            optionList: options$ | async,
            disabled: disabled.$ | async,
            values: values.$ | async
        } as data"
    >
        <ng-container *ngIf="ngControl.control; let control">
            <div
                class="bazis-control"
                clickOutside
                (clickOutside)="hideList()"
            >
                <span
                    class="bazis-control__label"
                    [id]="attrId"
                    *ngIf="titleKey || title"
                >
                    {{ title || t(titleKey, titleParams) }}
                    <span
                        color="danger"
                        *ngIf="data.required"
                    >
                        *
                    </span>
                </span>
                <span
                    class="bazis-control__label"
                    [id]="attrId"
                    *ngIf="isEmptyLabel"
                >&nbsp;</span>

                <div
                    class="bazis-control__field-group"
                    [class.bazis-control__field-group--activated]="showList"
                    [class.bazis-control__field-group--disabled]="data.disabled"
                >
                    <div
                        *ngIf="leftIcon"
                        class="bazis-control__field-group__start"
                    >
                        <bazis-icon [icon]="leftIcon"></bazis-icon>
                    </div>
                    <ng-container *ngIf="tooltipSettings?.targetSide === 'left' && tooltipKey">
                        <div
                            #tooltipLeft
                            class="bazis-control__field-group__start"
                        >
                            <bazis-icon name="interrogation"></bazis-icon>
                        </div>
                        <bazis-tooltip
                            [reference]="tooltipLeft"
                            [eventType]="tooltipSettings?.eventType || 'hover'"
                            [placement]="tooltipSettings?.placement || 'bottom-start'"
                            >{{ t(tooltipKey, tooltipParams) }}</bazis-tooltip
                        >
                    </ng-container>
                    <div class="bazis-control__field">
                        <!-- Нативный селект, для поддержки всяческих девайсов и ограниченных возможностей -->
                        <!-- TODO: подумать, как лучше оформить, так как иконка может быть не всегда
                        запас отступа под иконку в кастоме -->
                        <select
                            class="bazis-select-native"
                            [attr.aria-labelledby]="attrId"
                            placeholder="placeholder..."
                        >
                            <option *ngFor="let option of data.optionList">
                                <!-- TODO: icon or text version of icon?? -->
                                <ng-container *ngIf="option.$snapshot.name">
                                    {{ option.$snapshot.name }}
                                </ng-container>
                                <ng-container *ngIf="option.$snapshot.nameKey">
                                    {{ t(option.$snapshot.nameKey, option.$snapshot.nameParams) }}
                                </ng-container>
                            </option>
                        </select>

                        <!-- Прекрасный кастомный селект -->
                        <div
                            class="bazis-select"
                            [class.bazis-select_secondary]="type === 'secondary'"
                            aria-hidden="true"
                        >
                            <div
                                *ngIf="selectedValues$ | async as selectedValues"
                                class="bazis-select__trigger"
                                [class.bazis-select__trigger_noempty]="selectedValues.length"
                                (click)="toggleList()"
                            >
                                <span *ngIf="selectedValues.length; else tplNotSelectedOptions">
                                    <ng-container
                                        *ngFor="let selected of selectedValues; let i = index"
                                    >
                                        <bazis-icon
                                            *ngIf="selected.$snapshot.icon"
                                            [icon]="selected.$snapshot.icon"
                                        ></bazis-icon>
                                        <ng-container *ngIf="selected.$snapshot.name">
                                            {{ selected.$snapshot.name }}
                                        </ng-container>
                                        <ng-container *ngIf="selected.$snapshot.nameKey">
                                            {{
                                                t(
                                                    selected.$snapshot.nameKey,
                                                    selected.$snapshot.nameParams
                                                )
                                            }}
                                        </ng-container>
                                        {{ i < selectedValues.length - 1 ? ',' : '' }}
                                    </ng-container>
                                </span>
                                <ng-template #tplNotSelectedOptions>
                                    <span *ngIf="optionLabel">{{ t(optionLabel) }}</span>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="tooltipSettings?.targetSide === 'right' && tooltipKey">
                        <div
                            #tooltipRight
                            class="bazis-control__field-group__end"
                        >
                            <bazis-icon name="interrogation"></bazis-icon>
                        </div>
                        <bazis-tooltip
                            [reference]="tooltipRight"
                            [eventType]="tooltipSettings?.eventType || 'hover'"
                            [placement]="tooltipSettings?.placement || 'bottom-end'"
                            >{{ t(tooltipKey, tooltipParams) }}</bazis-tooltip
                        >
                    </ng-container>
                    <div class="bazis-control__field-group__end">
                        <button (click)="toggleList()">
                            <bazis-icon
                                [name]="showList ? 'caret-up' : 'caret-down'"
                                size="s"
                            ></bazis-icon>
                        </button>
                    </div>

                    <ul
                        class="bazis-list bazis-list_control-dd"
                        [hidden]="!showList"
                    >
                        <li
                            class="bazis-item"
                            *ngFor="let option of data.optionList"
                            [class.bazis-item--selected]="data.values.indexOf(option.id) > -1"
                            [class.bazis-checkbox]="byCheckbox && multiple"
                            (click)="toggleValue(option.id)"
                        >
                            <bazis-icon
                                *ngIf="option.$snapshot.icon && !byCheckbox"
                                [icon]="option.$snapshot.icon"
                            ></bazis-icon>

                            <span [class.bazis-checkbox__label]="byCheckbox && multiple">
                                <ng-container *ngIf="option.$snapshot.name">
                                    {{ option.$snapshot.name }}
                                </ng-container>
                                <ng-container *ngIf="option.$snapshot.nameKey">
                                    {{ t(option.$snapshot.nameKey, option.$snapshot.nameParams) }}
                                </ng-container>
                            </span>
                        </li>
                    </ul>
                </div>

                <div class="bazis-control__error">
                    <bazis-form-error [formControl]="ngControl.control"></bazis-form-error>
                </div>

                <div
                    *ngIf="noteKey"
                    class="bazis-control__note"
                >
                    {{ noteKey }}
                </div>

                <ng-content select="comment"></ng-content>
            </div>
        </ng-container>
    </ng-container>
</ng-container>
