import { Injectable } from '@angular/core';
import { catchError, filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { combineLatest, finalize, from, Observable, of, shareReplay, switchMap } from 'rxjs';
import { BazisSrvService } from '@bazis/shared/services/srv.service';
import { BazisStorageService } from '@bazis/shared/services/storage.service';
import {
    EntData,
    EntDescription,
    EntList,
    EntPriority,
    EntSchema,
    IncludedEntData,
    relationshipsEntData,
    SchemaType,
    SimpleData,
    SrvEntRelationshipsData,
} from '@bazis/shared/models/srv.types';
import { BazisModalService } from '@bazis/shared/services/modal.service';
import { BazisTransitModalComponent } from '@bazis/form/components/helpers/transit-modal.component';
import { BazisConfigurationService, SHARE_REPLAY_SETTINGS } from '@bazis/configuration.service';
import { buildFilterStr } from '@bazis/utils';
import { BazisToastService } from '@bazis/shared/services/toast.service';

@Injectable({
    providedIn: 'root',
})
export class BazisEntityService {
    constructor(
        private srv: BazisSrvService,
        private storage: BazisStorageService,
        private modalService: BazisModalService,
        private toastService: BazisToastService,
        private configurationService: BazisConfigurationService,
    ) {}

    private _getRequests = {};

    private _getSchemaRequests = {};

    // doNotInitLoad - только если в логике 100% будет одновременно вызываться forceLoad
    getEntity$(
        entityType: string,
        id: string = '',
        {
            forceLoad = false,
            doNotInitLoad = false,
            include = [],
        }: { forceLoad?: boolean; doNotInitLoad?: boolean; include?: string[] } = {},
    ): Observable<EntData> {
        // проверяем существование сущности
        const isExistedItem = this.storage.isExistedItem(entityType, id);
        // если ее нет или надо обязательно перезапросить или сейчас в процессе запроса
        const path = `${entityType}-${id}`;
        if (this._getRequests[path] && !forceLoad) {
            return this._getRequests[path];
        }

        if (
            (!isExistedItem || forceLoad || !this.storage.getItem(entityType, id)) &&
            !doNotInitLoad
        ) {
            // создаем в storage item$, в который после получения сущности ее отправим
            this.storage.getItem$(entityType, id);

            // если это обычная сущность, то просто ее получаем
            // сохраняем запрос, чтобы не "потерять сущность при отписке async", если запрос еще не был к моменту отписки завершен
            this._getRequests[path] = this.srv.fetchEntity$(entityType, id, include).pipe(
                mergeMap((r) => {
                    // сохраняем в storage
                    if (r.included && r.included.length > 0) {
                        r.included.forEach((includedItem) => {
                            this.storage.getItem$(includedItem.type, includedItem.id);
                            this.storage.setItem(includedItem.type, includedItem, includedItem.id);
                        });
                        delete r.included;
                    }
                    this.storage.setItem(entityType, r, id);
                    delete this._getRequests[path];
                    return this.storage.getItem$(entityType, id);
                }),
                tap((v) => {
                    delete this._getRequests[path];
                }),
                finalize(() => {
                    // если в момент finalize нет данных по объекту, то удаляем и информацию о запросе, и данные в storage
                    if (!this.storage.getItem(entityType, id)) {
                        this.storage.removeItem('entity', entityType, id);
                        delete this._getRequests[path];
                    }
                }),
                shareReplay(1),
            );

            // + возвращаем этот запрос, если
            return this._getRequests[path];
        }

        // если уже есть сущность, значит, просто ее возвращаем
        return this.storage.getItem$(entityType, id);
    }

    getOrganizationEntity$(orgOwnerId: string, forceLoad = false): Observable<EntData> {
        // проверяем существование сущности
        const entityType = 'organization.organization_info';
        const id = orgOwnerId;
        const isExistedItem = this.storage.isExistedItem(entityType, orgOwnerId);
        // если ее нет или надо обязательно перезапросить или сейчас в процессе запроса
        const path = `${entityType}-${id}`;
        if (this._getRequests[path] && !forceLoad) {
            return this._getRequests[path];
        }

        if (!isExistedItem || forceLoad) {
            // создаем в storage item$, в который после получения сущности ее отправим
            this.storage.getItem$(entityType, id);

            // если это обычная сущность, то просто ее получаем
            // сохраняем запрос, чтобы не "потерять сущность при отписке async", если запрос еще не был к моменту отписки завершен
            this._getRequests[path] = this.getEntityList$('organization.organization_info', {
                params: {
                    filter: buildFilterStr({ org_owner: id }),
                },
                limit: 1,
            }).pipe(
                map((v) => (v.list && v.list.length > 0 ? v.list[0] : null)),
                mergeMap((r) => {
                    this.storage.setItem(entityType, r, id);
                    delete this._getRequests[path];
                    return this.storage.getItem$(entityType, id);
                }),
                tap((v) => {
                    delete this._getRequests[path];
                }),
                finalize(() => {
                    if (!this.storage.getItem(entityType, id)) {
                        this.storage.removeItem('entity', entityType, id);
                        delete this._getRequests[path];
                    }
                }),
                shareReplay(1),
            );
            return this._getRequests[path];
        }

        // если уже есть сущность, значит, просто ее возвращаем
        return this.storage.getItem$(entityType, id);
    }

    // sync method to get entity from storage
    getEntity(entityType: string, id: string = ''): EntData {
        return this.storage.getItem(entityType, id);
    }

    setEntity(entity, desiredId = null) {
        this.storage.getItem$(entity.type, desiredId || entity.id);
        this.storage.setItem(entity.type, entity, desiredId || entity.id);
    }

    deleteEntity$(entityType: string, id: string = '') {
        return this.srv.deleteEntity$(entityType, id).pipe(
            tap(() => {
                this.storage.removeItem('entity', entityType, id);
            }),
            take(1),
        );
    }

    setPriority$(entityType: string, id: string, prioritySettings: EntPriority) {
        return this.srv
            .setPriority$(entityType, id, prioritySettings.value, prioritySettings.id)
            .pipe(
                tap(() => {
                    this.storage.patchItem(entityType, id, {
                        [prioritySettings.property]: prioritySettings.value,
                    });
                }),
                take(1),
            );
    }

    // sync method to get entity from storage
    getSchema(schemaType: SchemaType, entityType: string, id: string = ''): EntSchema {
        return this.storage.getSchema(schemaType, entityType, id);
    }

    getSchema$(
        schemaType: SchemaType,
        entityType: string,
        id: string = '',
        forceLoad = false,
        include: string[] = [],
    ): Observable<EntSchema> {
        // проверяем существование сущности
        const isExistedSchema = this.storage.isExistedSchema(schemaType, entityType, id);
        const path = `${schemaType}-${entityType}-${id}`;
        if (this._getSchemaRequests[path] && !forceLoad) {
            return this._getSchemaRequests[path];
        }
        // если ее нет или надо обязательно перезапросить
        if (!isExistedSchema || forceLoad) {
            // создаем в storage item$, в который после получения сущности ее отправим
            this.storage.getSchema$(schemaType, entityType, id);

            // получаем
            this._getSchemaRequests[path] = this.srv
                .fetchSchema$(schemaType, entityType, id, include)
                .pipe(
                    mergeMap((r: EntSchema) => {
                        // сохраняем в storage
                        if (r.included) {
                            r.included.forEach((schema) => {
                                if (schema.schemaType === 'schema_create' || schema.id) {
                                    this.storage.getSchema$(
                                        schema.schemaType,
                                        schema.entityType,
                                        schema.id,
                                    );
                                    this.storage.setSchema(
                                        schema.schemaType,
                                        schema.entityType,
                                        schema,
                                        schema.id,
                                    );
                                } else {
                                    this.storage.getSchemaFromParent$(
                                        schema.schemaType,
                                        schema.entityType,
                                        entityType,
                                        id,
                                    );
                                    this.storage.setSchemaFromParent(
                                        schema.schemaType,
                                        schema.entityType,
                                        entityType,
                                        id,
                                        schema,
                                    );
                                }
                            });
                            delete r.included;
                        }
                        this.storage.setSchema(schemaType, entityType, r, id);
                        return this.storage.getSchema$(schemaType, entityType, id);
                    }),
                    tap((v) => {
                        delete this._getSchemaRequests[path];
                    }),
                    finalize(() => {
                        // если в момент finalize нет данных по объекту, то удаляем и информацию о запросе, и данные в storage
                        if (!this.storage.getSchema(schemaType, entityType, id)) {
                            this.storage.removeItem('schema', entityType, id, schemaType);
                            delete this._getSchemaRequests[path];
                        }
                    }),
                    shareReplay(1),
                );

            return this._getSchemaRequests[path];
        }

        // если уже есть сущность, значит, просто ее возвращаем
        return this.storage.getSchema$(schemaType, entityType, id);
    }

    getParentSchema$(
        schemaType: SchemaType,
        entityType: string,
        id: string,
        parentType: string,
        parentId: string,
    ): Observable<EntSchema> {
        // проверяем существование схемы
        const isExistedSchema = this.storage.isExistedSchemaFromParent(
            schemaType,
            entityType,
            parentType,
            parentId,
        );

        if (isExistedSchema) {
            return this.storage.getSchemaFromParent$(schemaType, entityType, parentType, parentId);
        }

        return this.getSchema$(schemaType, entityType, id);
    }

    getParentSchema(
        schemaType: SchemaType,
        entityType: string,
        id: string,
        parentType: string,
        parentId: string,
    ): EntSchema {
        // проверяем существование схемы
        const isExistedSchema = this.storage.isExistedSchemaFromParent(
            schemaType,
            entityType,
            parentType,
            parentId,
        );

        if (isExistedSchema) {
            return this.storage.getSchemaFromParent(schemaType, entityType, parentType, parentId);
        }

        return this.getSchema(schemaType, entityType, id);
    }

    getAllEntitiesList$(
        entityType: string,
        forceLoad = false,
        saveAsIndividualEntities = false,
    ): Observable<EntList> {
        // проверяем существование списка
        const isExistedList = this.storage.isExistedList(entityType);

        if (this._getRequests[entityType] && !forceLoad) {
            return this._getRequests[entityType];
        }

        // если списка нет или его надо в обязательном порядке перегрузить
        if (!isExistedList || forceLoad) {
            this.storage.getList$(entityType);
            this._getRequests[entityType] = this.srv.fetchAllEntities$(entityType).pipe(
                mergeMap((r) => {
                    // сохраняем в storage
                    this.storage.setList(entityType, r);
                    if (saveAsIndividualEntities) {
                        r.list.forEach((v) => {
                            this.storage.getItem$(v.type, v.id);
                            this.storage.setItem(v.type, v, v.id);
                        });
                    }
                    return this.storage.getList$(entityType);
                }),
                tap(() => {
                    delete this._getRequests[entityType];
                }),
                catchError((e) => {
                    delete this._getRequests[entityType];
                    return e;
                }),
                shareReplay(1),
            );

            return this._getRequests[entityType];
        }

        // если уже есть список, значит, просто его возвращаем
        return this.storage.getList$(entityType);
    }

    // не использует storage - для построения динамических списков с подскроллом, зависящих от внешних параметров
    getEntityList$(
        entityType: string,
        {
            suffix = '',
            search = '',
            params = null,
            offset = 0,
            limit = 20,
            meta = [],
            saveSeparateItems = false,
        }: {
            suffix?: string;
            search?: string;
            params?: any;
            offset?: number;
            limit?: number;
            meta?: string[];
            saveSeparateItems?: boolean;
        } = {},
    ): Observable<EntList> {
        params = { ...params };
        if (params.sort === null || entityType.indexOf('classifier') === 0) {
            delete params.sort;
        } else if (!params.sort) {
            params.sort = '-dt_created';
        }

        return this.srv.fetchPortion$(entityType, suffix, offset, limit, search, params, meta).pipe(
            tap((list) => {
                if (saveSeparateItems) {
                    list.list.forEach((entity) => {
                        this.setEntity(entity);
                    });
                }
            }),
        );
    }

    // полученные в листе данные сохраняем как отдельные сущности и их возвращаем
    // (!!! следить самостоятельно за мета, листы не всю возвращают)
    getEntityListAsSeparateItems$(
        entityType: string,
        {
            suffix = '',
            search = '',
            params = null,
            offset = 0,
            limit = 20,
            meta = [],
        }: {
            suffix?: string;
            search?: string;
            params?: any;
            offset?: number;
            limit?: number;
            meta?: string[];
        } = {},
    ): Observable<EntData[]> {
        return this.getEntityList$(entityType, {
            suffix,
            search,
            params,
            offset,
            limit,
            meta,
        }).pipe(
            tap((list) => {
                list.list.forEach((entity) => {
                    this.setEntity(entity);
                });
            }),
            switchMap((list) =>
                combineLatest(
                    list.list.map((v) =>
                        this.getEntity$(entityType, v.id, { doNotInitLoad: true }),
                    ),
                ),
            ),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );
    }

    getListsCount$(
        settings: { entityType: string; filters: any; filterParams?: any; params?: any }[],
    ): Observable<number[]> {
        return this.srv
            .countRequest$(settings)
            .pipe(map((responses) => responses.map((response) => response.count || 0)));
    }

    toEntityItem(data: SimpleData | EntData): EntData {
        return data.$snapshot
            ? (data as EntData)
            : {
                  id: data.id,
                  $snapshot: {
                      ...data,
                  },
                  type: 'any',
              };
    }

    toEntitiesList(array: SimpleData[] | EntData[]): EntList {
        return {
            list: array.map((arrayItem) => this.toEntityItem(arrayItem)),
        };
    }

    protected _buildTransitData$(
        settings: {
            entityType?: string;
            entityId?: string;
            url?: string;
            transit: string;
            payload?: any;
            hint?: string;
            modalComponent?;
        }[],
        transitSettings = [],
    ): Observable<{ status: string; transitSettings: any }> {
        for (let i = transitSettings.length; i < settings.length; i++) {
            const transitItem = settings[i];

            if (transitItem.payload || transitItem.hint) {
                if (!transitItem.modalComponent) {
                    transitItem.modalComponent = BazisTransitModalComponent;
                }
                const modal = this.modalService.create({
                    component: transitItem.modalComponent,
                    componentProperties: {
                        payloadMap: transitItem.payload,
                        hint: transitItem.hint,
                        transit: transitItem.transit,
                    },
                    hasCloseIcon: false,
                    styleAlert: true,
                });

                return from(modal.onDidDismiss()).pipe(
                    switchMap((payloadBody) => {
                        if (payloadBody === null) {
                            return of({
                                status: 'cancel',
                                transitSettings: null,
                            });
                        }
                        transitSettings.push({
                            entityType: transitItem.entityType,
                            entityId: transitItem.entityId,
                            url: transitItem.url,
                            transitParams: {
                                transit: transitItem.transit,
                                payload: payloadBody,
                            },
                        });
                        return this._buildTransitData$(settings, transitSettings);
                    }),
                    take(1),
                );
            }

            transitSettings.push({
                entityType: transitItem.entityType,
                entityId: transitItem.entityId,
                url: transitItem.url,
                transitParams: {
                    transit: transitItem.transit,
                    payload: null,
                },
            });
        }

        if (transitSettings.length === settings.length) {
            return of({
                status: 'ready',
                transitSettings,
            });
        }
    }

    transitEntity$(
        settings: {
            entityType?: string;
            entityId?: string;
            url?: string;
            transit: string;
            payload?: any;
            hint?: string;
            modalComponent?: any;
        }[],
    ) {
        return this._buildTransitData$(settings).pipe(
            filter((response) => response.status === 'cancel' || response.status === 'ready'),
            switchMap((response) =>
                response.status === 'cancel'
                    ? of(null)
                    : this.srv.transitEntity$(response.transitSettings),
            ),
        );
    }

    copyEntity$(
        entity,
        entitySchema,
        fields,
        count = 1,
        valueModifiers = [],
    ): Observable<EntData[]> {
        const newEntity = {
            data: {
                type: entity.type,
                attributes: {},
                relationships: {},
            },
        };
        fields.forEach((field) => {
            if (entitySchema.attributes[field]) {
                newEntity.data.attributes[field] = entity.$snapshot[field];
            } else {
                newEntity.data.relationships[field] = {
                    data: entity.$snapshot[field],
                };
            }
        });
        const items = [];
        count = Math.max(count, valueModifiers.length);

        for (let i = 0; i < count; i++) {
            items.push(JSON.parse(JSON.stringify(newEntity)));
        }

        valueModifiers.forEach((item, i) => {
            const fields = Object.keys(item);
            fields.forEach((field) => {
                if (entitySchema.attributes[field]) {
                    items[i].data.attributes[field] = item[field];
                } else {
                    items[i].data.relationships[field] = {
                        data: item[field],
                    };
                }
            });
        });

        return this.srv
            .groupRequest$(
                items.map((item) => {
                    return {
                        entityId: null,
                        entityType: item.data.type,
                        requestType: 'item',
                        body: item,
                        method: 'POST',
                        params: null,
                        meta: [],
                    };
                }),
            )
            .pipe(
                tap((v) => {
                    this.toastService.create({
                        type: 'success',
                        titleKey: 'copyEntity.success.title.' + entity.type,
                        messageKey: 'copyEntity.success.description.' + entity.type,
                    });
                }),
            );
    }

    apiCopyEntity$(entity: EntDescription) {
        return this.srv
            .sendFormRequest$(`${entity.type.split('.').join('/')}/${entity.id}/copy`)
            .pipe(
                tap((v) => {
                    this.toastService.create({
                        type: 'success',
                        titleKey: 'copyEntity.success.title.' + entity.type,
                        messageKey: 'copyEntity.success.description.' + entity.type,
                    });
                }),
            );
    }

    // TODO: included entities need to be tested!!!
    createEntity$(
        entityType,
        attributes = {},
        relationships: { [index: string]: string | string[] } = {},
        include = [],
        desiredId = null,
        includedEntities: IncludedEntData[] = [],
    ) {
        const relationshipsData = this.buildEntityRelationships(entityType, relationships);
        const data = this.srv.generateEntityBody(
            entityType,
            desiredId,
            attributes,
            relationshipsData,
        );
        const included = this.buildEntityIncluded(includedEntities);
        const body = !included.length ? { ...data } : { ...data, included };
        return this.srv.createEntity$(entityType, body, include);
    }

    // TODO: included entities need to be tested!!!
    updateEntity$(
        entityType,
        id,
        attributes = {},
        relationships: relationshipsEntData = {},
        include = [],
        includedEntities: IncludedEntData[] = [],
    ) {
        const relationshipsData = this.buildEntityRelationships(entityType, relationships);
        const data = this.srv.generateEntityBody(entityType, id, attributes, relationshipsData);
        const included = this.buildEntityIncluded(includedEntities);
        const body = !included.length ? { ...data } : { ...data, included };
        return this.srv.saveEntity$(entityType, id, body, include);
    }

    buildEntityIncluded(includedEntities: IncludedEntData[] = []): any[] {
        return includedEntities.map((entity) => {
            return {
                id: entity.id,
                type: entity.entityType,
                action: entity.action,
                attributes: entity.attributes,
                relationships: this.buildEntityRelationships(
                    entity.entityType,
                    entity.relationships,
                ),
            };
        });
    }

    buildEntityRelationships(
        entityType: string,
        relationships: relationshipsEntData,
    ): SrvEntRelationshipsData {
        const relationshipsData = {};
        const schema = this.configurationService.getSchemaByEntityType(entityType);

        Object.keys(relationships).forEach((key) => {
            if (schema.relationships[key].type === 'object') {
                relationshipsData[key] = {
                    data: relationships[key]
                        ? {
                              id: relationships[key],
                              type: schema.relationships[key].entityType,
                          }
                        : null,
                };
            } else {
                relationshipsData[key] = {
                    data: (relationships[key] as string[])
                        .filter((v) => !!v)
                        .map((id) => ({
                            id: id,
                            type: schema.relationships[key].entityType,
                        })),
                };
            }
        });

        return relationshipsData;
    }
}
