import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EntDescription } from '@bazis/shared/models/srv.types';

@Component({
    selector: 'bazis-gallery-single-slider',
    templateUrl: './gallery-single-slider.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GallerySingleSliderComponent {
    @Input()
    images: EntDescription[];

    //  Если необходимо задать отличный от имени файла alt картинкам
    @Input() imagesAlt?: string[];

    currentIndex = 0;

    constructor() {}
}
