<div
    class="bazis-container bh-hide-print"
    *transloco="let t"
>
    <ng-container
        *ngIf="{
            text: textSettings$ | async,
            role: role$ | async,
            agencyContract: agencyContract$ | async,
            partnerContract: partnerContract$ | async,
            buyerContract: buyerContract$ | async,
            organization: organization$ | async
        } as data"
    >
        <ng-container
            *ngIf="
                notificationsToShow['requisites'] &&
                data.text &&
                data.text['requisites'] &&
                data.organization?.$snapshot?.status?.id !== 'accepted'
            "
            [ngTemplateOutlet]="notificationTpl"
            [ngTemplateOutletContext]="{
                id: 'requisites',
                text: data.text['requisites']
            }"
        ></ng-container>
        <ng-container
            *ngIf="
                notificationsToShow['agencyContract'] &&
                (!notificationsToShow['requisites'] ||
                    !data.text['requisites'] ||
                    data.organization?.$snapshot?.status?.id === 'accepted') &&
                data.text &&
                data.text['agencyContract'] &&
                (data.role === ROLE.wasteRecycling || data.role === ROLE.wasteTransportation) &&
                ((!data.agencyContract && data.agencyContract !== undefined) ||
                    (data.agencyContract && data.agencyContract.$snapshot.status.id !== 'active'))
            "
            [ngTemplateOutlet]="notificationTpl"
            [ngTemplateOutletContext]="{
                id: 'agencyContract',
                text: data.text['agencyContract']
            }"
        ></ng-container>
        <ng-container
            *ngIf="
                notificationsToShow['buyerContract'] &&
                (!notificationsToShow['requisites'] ||
                    !data.text['requisites'] ||
                    data.organization?.$snapshot?.status?.id === 'accepted') &&
                data.text &&
                data.text['buyerContract'] &&
                data.role === ROLE.buyer &&
                ((!data.buyerContract && data.buyerContract !== undefined) ||
                    (data.buyerContract && data.buyerContract.$snapshot.status.id !== 'active'))
            "
            [ngTemplateOutlet]="notificationTpl"
            [ngTemplateOutletContext]="{
                id: 'buyerContract',
                text: data.text['buyerContract']
            }"
        ></ng-container>
        <ng-container
            *ngIf="
                notificationsToShow['partnerContract'] &&
                (!notificationsToShow['requisites'] ||
                    !data.text['requisites'] ||
                    data.organization?.$snapshot?.status?.id === 'accepted') &&
                data.text &&
                data.text['partnerContract'] &&
                data.role === ROLE.referral &&
                ((!data.partnerContract && data.partnerContract !== undefined) ||
                    (data.partnerContract && data.partnerContract.$snapshot.status.id !== 'active'))
            "
            [ngTemplateOutlet]="notificationTpl"
            [ngTemplateOutletContext]="{
                id: 'partnerContract',
                text: data.text['partnerContract']
            }"
        ></ng-container>
    </ng-container>
</div>

<ng-template
    #notificationTpl
    let-id="id"
    let-text="text"
    let-textParams="textParams"
    let-routerLink="routerLink"
>
    <div
        *transloco="let t"
        class="bazis-block bazis-block_warning bh-margin-bottom-4x"
    >
        <bazis-icon
            color="warning"
            name="exclamation"
            size="md"
        ></bazis-icon>
        <div class="bh-flex bh-justify-content-between">
            <div class="bazis-block__content">
                <p class="bh-no-margin">
                    <span [innerHTML]="t('topNotifications.' + id + '.' + text, textParams)"></span>
                    <app-open-entity
                        *ngIf="routerLink"
                        type="link"
                        linkSize="small"
                        class="bh-margin-start-2x"
                        [href]="routerLink"
                    >
                    </app-open-entity>
                </p>
            </div>

            <bazis-button
                size="small"
                fill="clear"
                (click)="hideNotification(id)"
            >
                <bazis-icon
                    slot="icon-only"
                    name="cross"
                ></bazis-icon>
            </bazis-button>
        </div>
    </div>
</ng-template>
