import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable, shareReplay } from 'rxjs';
import * as m from 'moment';
import { SHARE_REPLAY_SETTINGS } from '@bazis/configuration.service';
const moment = m;

@Component({
    selector: 'app-crypto-certificates',
    templateUrl: './certificates.component.html',
})
export class CryptoCertificatesComponent implements OnInit {
    @Input()
    certificates$: Observable<any>;

    @Input()
    certificate: any;

    @Output()
    certificateChange = new EventEmitter();

    list$: Observable<any>;

    public nowDate = new Date().toString();

    constructor() {}

    ngOnInit() {
        this.list$ = this.certificates$.pipe(
            map((certificates) => {
                return certificates?.map
                    ? certificates.map((certificate) => {
                          const valueMap = {};
                          certificate.value.split(', ').forEach((pare) => {
                              const arr = pare.split('=');
                              valueMap[arr[0]] = arr[1];
                          });
                          return {
                              ...certificate,
                              valueMap,
                              disabled: moment(this.nowDate).isAfter(certificate.toDate),
                          };
                      })
                    : [];
            }),
            catchError((err) => {
                return err;
            }),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );
    }

    select(certificate) {
        this.certificateChange.emit(certificate);
    }

    preventDefault(e) {
        e.preventDefault();
        e.stopImmediatePropagation();
    }
}
