import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    ElementRef,
    HostListener,
    Input,
    QueryList,
    ViewChild,
} from '@angular/core';
import { tap } from 'rxjs';

@Component({
    selector: 'bazis-tabs-slider',
    templateUrl: './tabs-slider.component.html',
    styleUrls: ['tabs-slider.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsSliderComponent implements AfterViewInit {
    @ViewChild('tabsContainer', { static: true }) set contentWrapEl(el: ElementRef) {
        if (!el) return;

        this.wrapper = el.nativeElement;
    }

    @ContentChildren('tab') tabList: QueryList<ElementRef>;

    @HostListener('window:resize') onResize() {
        if (this.tabList.length) {
            this.init(this.tabList.toArray());
        }
    }

    @Input() controlsWidth = 91;

    wrapper;

    allTabsWidth: number = 0;

    containerWidth: number;

    shift: number = 0;

    isEnd = false;

    tablist$;

    constructor(private cdr: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        if (this.tabList.length) {
            this.init(this.tabList.toArray());
        }

        this.tablist$ = this.tabList.changes.pipe(tap((list) => this.init(list)));
    }

    init(list) {
        let tabsListWidth = 0;
        list.forEach((tab) => {
            tabsListWidth = tabsListWidth + tab.nativeElement.clientWidth;
        });
        this.allTabsWidth = tabsListWidth;

        this.containerWidth =
            this.wrapper.parentNode.clientWidth < this.allTabsWidth
                ? this.wrapper.parentNode.clientWidth - this.controlsWidth
                : this.wrapper.parentNode.clientWidth;
        this.cdr.detectChanges();
    }

    slideStart() {
        this.shift = this.shift + this.containerWidth;
        this.shiftCorrect();
    }

    slideEnd() {
        this.shift = this.shift - this.containerWidth;
        this.shiftCorrect();
    }

    shiftCorrect() {
        if (this.shift <= this.containerWidth - this.allTabsWidth) {
            this.shift = this.containerWidth - this.allTabsWidth;
            this.isEnd = true;
        } else {
            this.isEnd = false;
        }
        if (this.shift > 0) this.shift = 0;
    }
}
