<ng-container *ngIf="assembly.production">
    <!-- Yandex.Metrika counter -->
    <noscript
        ><div
            ><img
                src="https://mc.yandex.ru/watch/93017534"
                style="position: absolute; left: -9999px"
                alt="" /></div
    ></noscript>
    <!-- /Yandex.Metrika counter -->
</ng-container>

<div
    [ngClass]="['ua-' + deviceService.browser, 'os-' + deviceService.os, 'dt-' + deviceService.deviceType]"
    class="bh-contents"
>
    <router-outlet></router-outlet>
    <bazis-modals></bazis-modals>
    <bazis-alert></bazis-alert>
    <bazis-context-menus></bazis-context-menus>
    <bazis-toast></bazis-toast>
    <bazis-global-loader></bazis-global-loader>
</div>
