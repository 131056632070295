import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { BazisBreadcrumbsService } from '@bazis/shared/services/breadcrumbs.service';
import { BazisMediaQueryService } from '@bazis/shared/services/media-query.service';

@Component({
    selector: 'bazis-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent implements OnDestroy, OnInit {
    // TODO: (пометка для себя)
    // можно доработать компонент по разнообразию разделителей и иконок рядом с ссылкой. Идеи из https://ionicframework.com/docs/api/breadcrumbs
    // посмотреть вариант доработки до web- компонента

    list$ = this.breadcrumbs.breadcrumbs$;

    constructor(
        public breadcrumbs: BazisBreadcrumbsService,
        public mqSrv: BazisMediaQueryService,
    ) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}
}
