import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

/**
 * Add the template content to the DOM if property !== undefined
 */
@Directive({ selector: '[ifProperty]' })
export class IfPropertyDirective {
    private hasView = false;

    constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

    private _ifProperty;

    @Input() set ifProperty(property: any) {
        this._ifProperty = property;
        this.calculate();
    }

    private _ifPropertyCondition = 'or';

    @Input() set ifPropertyCondition(propertyCondition: any) {
        this._ifPropertyCondition = propertyCondition;
        this.calculate();
    }

    calculate() {
        const props = Array.isArray(this._ifProperty) ? this._ifProperty : [this._ifProperty];
        let show;
        if (this._ifPropertyCondition === 'or') {
            // show if at least one value is not undefined
            show = props.reduce((acc, current) => acc || current !== undefined, false);
        } else if (this._ifPropertyCondition === 'and') {
            // show if all values are not undefined
            show = props.reduce((acc, current) => acc && current !== undefined, true);
        }

        if (show && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else if (!show && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
        }
    }
}
