import { AfterViewInit, Component, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import * as THREE from 'three';
import ParticleFactory from '@assets/js/model.min';
import { STATE } from './const';

@Component({
    selector: 'app-entrance-webgl',
    template: '',
})
export class WebglComponent implements AfterViewInit, OnDestroy {
    private readonly factory = new ParticleFactory({ THREE });

    private readonly params = STATE;

    private readonly particles = this.factory.produce(this.params, {
        width: '100%',
        height: '100%',
        left: '0',
        top: '0',
        right: '0',
        bottom: '0',
        position: 'absolute',
        zIndex: '-1',
    });

    constructor(private renderer: Renderer2, private hostEl: ElementRef) {}

    ngAfterViewInit(): void {
        this.renderer.appendChild(this.hostEl.nativeElement, this.particles.container);
        this.factory.animate();
    }

    ngOnDestroy(): void {
        this.factory.destroy();
    }
}
