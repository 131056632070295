<div
    class="bazis-modal-wrap"
    [class.bazis-modal--scrolling]="isScrollingContent"
    *transloco="let t"
>
    <div class="bazis-modal__header">
        <h1 class="bh-ellipsis">{{
            document ? document.$snapshot.name : t('document.modal.h1')
        }}</h1>
        <!-- <h1 *ngIf="document">{{ document.$snapshot.name }}</h1> -->
    </div>

    <div
        (scroll)="onContentScroll($event)"
        class="bazis-modal__content"
    >
        <!-- grey block text -->
        <div class="bazis-block bazis-block_secondary">
            <bazis-icon
                name="exclamation"
                class="bazis-text_placeholder bh-float-sm-left bh-margin-end-3x bh-margin-bottom-3x"
                size="md"
            ></bazis-icon>
            <div class="bh-flex bh-justify-content-between">
                <div class="bazis-block__content">
                    {{ t('document.modal.checkDescription') }}
                </div>
            </div>
        </div>

        <bazis-document-accordeon
            [document]="document"
            [isOpen]="true"
        ></bazis-document-accordeon>

        <p class="bazis-p_major bh-margin-vertical-8x">
            <span
                [innerHTML]="
                    t('document.modal.description', {
                        documentName: document?.$snapshot?.name || documentName
                    })
                "
            ></span>
            <a [href]="'https://' + site">{{ site }}</a>
        </p>

        <div class="bazis-list bazis-list_e-sign">
            <div
                *ngFor="let signatory of signInfo.$snapshot.signatories"
                class="bazis-item"
            >
                <bazis-signature [signatureId]="signatory.signature?.id"></bazis-signature>
            </div>
        </div>
    </div>

    <!-- <div class="bazis-modal__footer">
        <bazis-toolbar>
            <bazis-button
                slot="end"
                color="action"
                (click)="modalService.dismiss()"
            >
                {{ t('action.close') }}
            </bazis-button>
        </bazis-toolbar>
    </div> -->
</div>
