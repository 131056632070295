export const STATE = {
    FPSCapture: 60,
    reverse: false,
    reverseEvent: false,
    globalTimeMultiplier: 0.75,
    time: 0,
    useFixedTimes: false,
    loadTime: 100,
    globalScale: 1,
    scale: 5.5,
    scaleX: 1,
    scaleY: 1,
    scaleZ: 1,
    decay: 0.5,
    speed: 0.25,
    persistence: 0.5,
    decayShrinkingStart: 10,
    decayShrinkingEnd: 0,
    decayEnlargementStart: 100,
    decayEnlargementEnd: 90,
    randomOffset: 0,
    renderingFPS: 300,
    particlesAccumulationCenterPosX: 0,
    particlesAccumulationCenterPosY: 0,
    particlesAccumulationCenterPosZ: 0,
    particleAccumulationRadius: 0,
    particleAccumulationSmoothingDistance: 0,
    clearThreads: true,
    animationType: 'original',
    useKeyframes: false,
    keyframes: [
        {
            active: true,
            t: 0,
            globalScale: 1,
            scale: 2.88,
            decay: 1.51,
            speed: 1,
            persistence: 0.7,
        },
        {
            active: false,
            t: 0.125,
            globalScale: 1,
            scale: 2.88,
            decay: 1.51,
            speed: 1,
            persistence: 0.7,
        },
        {
            active: false,
            t: 0.25,
            globalScale: 1,
            scale: 2.88,
            decay: 1.51,
            speed: 1,
            persistence: 0.7,
        },
        {
            active: false,
            t: 0.375,
            globalScale: 1,
            scale: 2.88,
            decay: 1.51,
            speed: 1,
            persistence: 0.7,
        },
        {
            active: false,
            t: 0.5,
            globalScale: 1,
            scale: 2.88,
            decay: 1.51,
            speed: 1,
            persistence: 0.7,
        },
        {
            active: false,
            t: 0.625,
            globalScale: 1,
            scale: 2.88,
            decay: 1.51,
            speed: 1,
            persistence: 0.7,
        },
        {
            active: false,
            t: 0.75,
            globalScale: 1,
            scale: 2.88,
            decay: 1.51,
            speed: 1,
            persistence: 0.7,
        },
        {
            active: false,
            t: 0.875,
            globalScale: 1,
            scale: 2.88,
            decay: 1.51,
            speed: 1,
            persistence: 0.7,
        },
        {
            active: true,
            t: 1,
            globalScale: 1,
            scale: 2.88,
            decay: 1.51,
            speed: 1,
            persistence: 0.7,
        },
    ],
    animate: true,
    rotModel1X: 0,
    rotModel1Y: 0,
    rotModel1Z: 0,
    translateModel1X: 0,
    translateModel1Y: 0,
    translateModel1Z: 0,
    scaleModel1: 1,
    rotModel2X: 0,
    rotModel2Y: 0,
    rotModel2Z: 0,
    translateModel2X: 0,
    translateModel2Y: 0,
    translateModel2Z: 0,
    scaleModel2: 1,
    dofOn: false,
    bloomOn: false,
    godRaysOn: false,
    pixelRatio: 1,
    squashiness: 0.56,
    mesh: 0,
    model: 0,
    t: 0.165,
    easing: 'linear',
    animationSpeed: 1,
    orbitControls: true,
    timeSpeed: 12.5,
    lighting: 'original',
    useOneMaterialColor: true,
    baseColor: '#1976f0',
    baseColor2: '#1976f0',
    baseColor3: '#1976f0',
    baseColor4: '#1976f0',
    roughness: 0.8514,
    metallic: 0,
    ambientColor: '#1976f0',
    ambientIntensity: 0,
    effectColor: '#ff5403',
    postgrey: true,
    baseHue: 0,
    baseSaturation: 1,
    baseValue: 0.9,
    baseHue2: 0,
    baseSaturation2: 1,
    baseValue2: 1,
    baseHue3: 0,
    baseSaturation3: 1,
    baseValue3: 1,
    baseHue4: 0,
    baseSaturation4: 1,
    baseValue4: 1,
    specularStrength: 0,
    effectHue: 0.413,
    effectSaturation: 0,
    effectValue: 1,
    shadowDarkness: 0.7,
    lightIntensity: 4,
    lightRadius: 4,
    startPosX: 0,
    startPosY: 0,
    startPosZ: 0,
    endPosX: 0,
    endPosY: 0,
    endPosZ: 0,
    clearColor: '#F6F7FF',
    lightPosX: -680,
    lightPosY: 744,
    lightPosZ: 640,
    lightRotX: -0.8604017099145261,
    lightRotY: -0.6059390706565803,
    lightRotZ: -0.5848229423863701,
    cameraPosX: -299.4861645463908,
    cameraPosY: -0.9782261018723949,
    cameraPosZ: 137.45894476361798,
    rotationMode: 'cameraLightRotation',
    rotTargetPosX: 0,
    rotTargetPosY: 0,
    rotTargetPosZ: 0,
    rotSpeed: 0,
    focus: 1700.65,
    aperture: 0.00001,
    maxblur: 0.007,
    gradientOn: false,
    gradientAngle: 0,
    gradientRotate: false,
    gradientColorTop: '#ffffff',
    gradientColorBottom: '#000000',
    environmentOn: false,
    environmentIntensity: 0,
    envRoughness: 0.1,
    exposure: 2.075,
    godRayIntensity: 0.1,
    bgColor: '#000511',
    sunColor: '#ffee00',
    bloomThreshold: 0,
    bloomStrength: 0.284,
    bloomRadius: 0.1,
    lutEnabled: false,
    lut: 'Bourbon 64.CUBE',
    intensity: 1,
    use2DLut: false,
    drawRange: 2500,
    particleTexSize: 256,
    renderShadows: false,
    useSoftShadows: false,
    shadowRadius: 0.2,
    shadowBias: 0.005,
    numOfPoissonDisks: 6,
    renderShadowPerNFrame: 2,
    shadowParticleProportion: 0.75,
    cameraRotX: 0.007116376385145568,
    cameraRotY: -1.1404880091602463,
    cameraRotZ: 0.006467646429220488,
};
