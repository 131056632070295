<div
    class="bazis-modal-wrap"
    *transloco="let t"
>
    <ng-container
        *ngIf="{
            items: itemsToSign$ | async,
            filesIsUploaded: filesIsUploaded.$ | async
        } as data"
    >
        <!-- <ng-container *ngIf="!(currentStep === 3 && mqSrv.matchBreakpoint('xs'))"> -->
        <div
            class="bazis-modal__header_shadow"
            *ngIf="currentStep < 4"
        >
            <h4>
                {{ t(data.items.length > 1 ? 'crypto.docsSigning' : 'crypto.docSigning') }}
            </h4>
        </div>
        <!-- </ng-container> -->

        <!-- STEP 1-->
        <ng-container *ngIf="currentStep === 1">
            <div class="bazis-modal__content">
                <ng-container *ngIf="filesIsUploaded._ !== data.items.length">
                    <bazis-default-loader
                        [message]="
                            t(data.items.length > 1 ? 'crypto.loadedDocs' : 'crypto.loadedDoc')
                        "
                    ></bazis-default-loader>
                </ng-container>
                <ng-container *ngIf="filesIsUploaded._ === data.items.length">
                    <p class="bh-margin-bottom-5x">
                        {{ t(data.items.length > 1 ? 'crypto.checkDocs' : 'crypto.checkDoc') }}
                    </p>
                    <p class="bh-margin-bottom-3x bazis-p_minor bh-font-medium">
                        <span *ngIf="data.items.length > 1">
                            {{ t('crypto.files', { count: data.items.length }) }}:
                        </span>
                        <span *ngIf="data.items.length === 1"> {{ t('crypto.file') }}: </span>
                    </p>
                </ng-container>

                <div *ngFor="let item of data.items">
                    <ng-container *ngIf="item.document$ | async as doc">
                        <bazis-document-accordeon
                            [document]="doc"
                            *ngIf="filesIsUploaded._ === data.items.length"
                        ></bazis-document-accordeon>
                    </ng-container>
                </div>
            </div>

            <div class="bazis-modal__footer">
                <bazis-toolbar>
                    <div slot="start">
                        <bazis-button
                            [fill]="mqSrv.matchBreakpoint('xs') ? 'outline' : 'clear'"
                            color="action"
                            (click)="close()"
                        >
                            {{ t('action.cancel') }}
                        </bazis-button>
                    </div>
                    <div slot="end">
                        <div
                            [ngClass]="{
                                'bh-align-items-center bh-line-elements':
                                    mqSrv.matchBreakpoint('sm')
                            }"
                        >
                            <span
                                class="bazis-text-action bh-hide-sm-down bazis-p_minor"
                                color="success"
                                >{{ t('crypto.allRight') }},&nbsp;</span
                            >

                            <bazis-button
                                color="action"
                                [disabled]="data.filesIsUploaded !== data.items.length"
                                (click)="currentStep = currentStep + 1"
                            >
                                {{ t('action.sign') }}
                            </bazis-button>
                        </div>
                    </div>
                </bazis-toolbar>
            </div>
        </ng-container>

        <!-- STEP 2-->
        <ng-container *ngIf="currentStep === 2">
            <div class="bazis-modal__content">
                <div class="bh-margin-bottom-3x bh-font-medium">
                    <span *ngIf="data.items.length === 1">{{ t('crypto.file') }}</span>
                    <span *ngIf="data.items.length > 1">{{
                        t('crypto.remainToSign', {
                            remain: remainToSign$ | async,
                            total: data.items.length
                        })
                    }}</span>
                </div>
                <ul class="bazis-list">
                    <li
                        class="bazis-item"
                        *ngFor="let item of data.items"
                    >
                        <ng-container *ngIf="item.document$ | async as doc">
                            <bazis-file-inline [file]="doc"></bazis-file-inline>
                        </ng-container>
                    </li>
                </ul>

                <ng-container *ngIf="showCertificatesBlock">
                    <ng-container *ngIf="certificates$ | async">
                        <app-crypto-certificates
                            *ngIf="!pluginError"
                            [(certificate)]="certificate"
                            [certificates$]="certificates$"
                        ></app-crypto-certificates>
                        <app-crypto-info *ngIf="pluginError"> </app-crypto-info>
                    </ng-container>
                </ng-container>
            </div>

            <div class="bazis-modal__footer">
                <bazis-toolbar>
                    <bazis-button
                        slot="start"
                        [fill]="mqSrv.matchBreakpoint('xs') ? 'outline' : 'clear'"
                        color="action"
                        (click)="currentStep = currentStep - 1"
                    >
                        {{ t('action.back') }}
                    </bazis-button>
                    <ng-container *ngIf="certificate">
                        <div slot="end">
                            <div
                                [ngClass]="{
                                    'bh-align-items-center bh-line-elements':
                                        mqSrv.matchBreakpoint('sm')
                                }"
                            >
                                <span
                                    class="bazis-text-action bh-hide-sm-down bazis-text_minor"
                                    color="success"
                                    >{{ t('crypto.allRight') }},</span
                                >
                                <bazis-button
                                    color="action"
                                    (click)="sign()"
                                >
                                    {{ t('action.sign') }}
                                </bazis-button>
                            </div>
                        </div>
                    </ng-container>
                </bazis-toolbar>
            </div>
        </ng-container>

        <!-- STEP 3-->
        <ng-container *ngIf="currentStep === 3">
            <bazis-default-loader [message]="t('crypto.signLoading')"></bazis-default-loader>
            <div class="bazis-backdrop bh-hide-sm-up"></div>
        </ng-container>
        <!-- <div>signed {{ signed | json }}</div>-->
    </ng-container>

    <ng-container
        *ngIf="{
            signEntity: signEntity$ | async
        } as data"
    ></ng-container>
</div>
