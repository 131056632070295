import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, startWith } from 'rxjs/operators';
import { ROLE, SHARE_REPLAY_SETTINGS } from '@app/configuration.service';
import { SwAuthService } from '@shared/services/auth.service';
import { shareReplay } from 'rxjs';

@Component({
    selector: 'app-top-notifications',
    templateUrl: './top-notifications.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopNotificationsComponent implements OnDestroy, OnInit {
    notificationSettings = {
        agencyContract: {
            id: 'agencyContract',
            texts: {
                all: {
                    id: 'all',
                },
                profile: {
                    id: 'profile',
                    urlContain: '/profile/agency-contract',
                },
            },
        },
        buyerContract: {
            id: 'buyerContract',
            texts: {
                all: {
                    id: 'all',
                },
                profile: {
                    id: 'profile',
                    urlContain: '/profile/agency-contract',
                },
            },
        },
        partnerContract: {
            id: 'partnerContract',
            texts: {
                all: {
                    id: 'all',
                },
                profile: {
                    id: 'profile',
                    urlContain: '/profile/partner-contract',
                },
            },
        },
        requisites: {
            id: 'requisites',
            texts: {
                all: {
                    id: 'all',
                },
            },
        },
    };

    notificationsToShow = {};

    role$ = this.authService.role$;

    textSettings$ = this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(this.router),
        map((event: NavigationEnd) => {
            const resultSettings = {};
            Object.keys(this.notificationSettings).forEach((notificationId) => {
                Object.keys(this.notificationSettings[notificationId].texts).forEach((textId) => {
                    const textSettings = this.notificationSettings[notificationId].texts[textId];
                    if (!textSettings.urlContain) {
                        resultSettings[notificationId] = textId;
                    } else if (event.url.indexOf(textSettings.urlContain) > -1) {
                        if (textSettings.hide) {
                            delete resultSettings[notificationId];
                        } else {
                            resultSettings[notificationId] = textId;
                        }
                    }
                });
            });
            return resultSettings;
        }),
    );

    agencyContract$ = this.authService.agencyContract$;

    partnerContract$ = this.authService.partnerContract$;

    buyerContract$ = this.authService.buyerContract$;

    organization$ = this.authService.organizationInfoFull$.pipe(shareReplay(SHARE_REPLAY_SETTINGS));

    ROLE = ROLE;

    constructor(
        private authService: SwAuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this.updateNotifications();
    }

    ngOnDestroy(): void {}

    hideNotification(id) {
        const notifications = localStorage.getItem('notifications') || '';
        localStorage.setItem(`notifications`, `${notifications}${id},`);
        this.updateNotifications();
    }

    updateNotifications() {
        const hidden = localStorage.getItem('notifications') || '';
        const hiddenArr = hidden.split(',');

        this.notificationsToShow = Object.keys(this.notificationSettings)
            .filter((notification) => hiddenArr.indexOf(notification) === -1)
            .reduce((acc, current) => {
                acc[current] = true;
                return acc;
            }, {});
    }
}
