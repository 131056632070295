import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BazisModalService } from '@bazis/shared/services/modal.service';
import { EntData } from '@bazis/shared/models/srv.types';
@Component({
    selector: 'bazis-signatures',
    templateUrl: './signatures.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisSignaturesComponent implements OnInit {
    @Input() signInfo: EntData;

    @Input() document: EntData;

    @Input() documentName: string;

    @Input() site: string = location.host;

    isScrollingContent: boolean = false;

    openDropdownMenu = false;

    constructor(public modalService: BazisModalService) {}

    ngOnInit() {
        console.log(this.signInfo);
    }

    onContentScroll(ev) {
        const scrollPosition = ev.target.scrollTop;
        this.isScrollingContent = scrollPosition > 50 ? true : false;
    }
}
