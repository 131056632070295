<div
    *transloco="let t"
    class="bazis-control"
>
    <ng-container
        *ngIf="{
            required: required$ | async
        } as data"
    >
        <label
            class="bazis-control__label"
            [class.bazis-h6]="hasTitleMajor"
            *ngIf="titleKey"
        >
            {{ t(titleKey, titleParams) }}
            <span
                color="danger"
                *ngIf="data.required"
            >
                *
            </span>
        </label>

        <div
            class="bazis-tags-group"
            *ngFor="let value of values.$ | async"
        >
            <div
                class="bazis-tag"
                *ngIf="value | entity : entityType | async as entity"
            >
                <span>
                    <ng-container
                        *ngIf="infiniteListTpl"
                        [ngTemplateOutlet]="infiniteListTpl"
                        [ngTemplateOutletContext]="{ item: entity }"
                    ></ng-container>
                    <ng-container *ngIf="!infiniteListTpl">{{
                        entity.$snapshot?.name
                    }}</ng-container>
                </span>
                <button
                    (click)="toggleValue(value)"
                    *ngIf="!isDisabled"
                >
                    <bazis-icon
                        name="trash"
                        size="xxs"
                    ></bazis-icon>
                </button>
            </div>
        </div>
        <div
            class="bazis-list_control-dd-wrapper"
            [class.bh-events-none]="isDisabled"
            clickOutside
            (clickOutside)="hideList()"
        >
            <bazis-input-default
                [formControl]="searchControl"
                [rightIcon]="rightIcon"
                isRightIconClickable="true"
                (rightIconClick)="openModalList()"
                [noteKey]="noteKey"
                [tooltipKey]="tooltipKey"
                [placeholderKey]="placeholderKey"
                [noteParams]="noteParams"
                [tooltipParams]="tooltipParams"
                [tooltipSettings]="tooltipSettings"
                [hasEraser]="hasEraser"
                [required$]="required$"
                (touched)="markAsTouched()"
                (focused)="isFocused = true; showList = true"
                (blured)="isFocused = false"
                (click)="toggleList()"
            >
                <bazis-form-error [formControl]="ngControl.control"></bazis-form-error>
            </bazis-input-default>

            <bazis-infinite-list
                [hidden]="!showList"
                [searchSettings]="searchSettings"
                [emptyValue]="emptyValueEntity"
                [excludeIds$]="excludeIds$"
                emptyListKey="title.emptySearch"
                [search$]="searchControl.valueChanges"
            >
                <ng-template let-item="item">
                    <div
                        class="bazis-item"
                        [class.bazis-item--selected]="
                            valuesMap &&
                            valuesMap.has(
                                searchSettings.fieldForId
                                    ? item.$snapshot[searchSettings.fieldForId]?.id
                                    : item.id
                            )
                        "
                        (click)="
                            toggleValue(
                                searchSettings.fieldForId
                                    ? item.$snapshot[searchSettings.fieldForId]?.id
                                    : item.id
                            )
                        "
                    >
                        <ng-container
                            *ngIf="infiniteListTpl"
                            [ngTemplateOutlet]="infiniteListTpl"
                            [ngTemplateOutletContext]="{ item: item }"
                        ></ng-container>
                        <ng-container *ngIf="!infiniteListTpl">
                            {{ item.$snapshot.name }}
                        </ng-container>
                    </div>
                </ng-template>
            </bazis-infinite-list>
        </div>
    </ng-container>
</div>
