import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { EMPTY, from, shareReplay } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root',
})
export class IconService {
    public bazisIconContent = new Map();

    constructor(private sanitizer: DomSanitizer) {}

    loadIcon$(url) {
        if (!url) return EMPTY;
        if (!this.bazisIconContent.has(url)) {
            this.bazisIconContent.set(url, this._getSvgContent$(url));
        }
        return this.bazisIconContent.get(url);
    }

    private validateContent(svgContent) {
        const div = document.createElement('div');
        div.innerHTML = svgContent;
        // setup this way to ensure it works on our buddy IE
        for (let i = div.childNodes.length - 1; i >= 0; i--) {
            if (div.childNodes[i].nodeName.toLowerCase() !== 'svg') {
                div.removeChild(div.childNodes[i]);
            }
        }
        // must only have 1 root element
        const svgElm = div.firstElementChild;
        if (svgElm && svgElm.nodeName.toLowerCase() === 'svg') {
            const svgClass = svgElm.getAttribute('class') || '';
            svgElm.setAttribute('class', (svgClass + ' s-bazis-icon').trim());
            // root element must be an svg
            // lets double check we've got valid elements
            // do not allow scripts
            if (this.isValid(svgElm)) {
                return div.innerHTML;
            }
        }
        return '';
    }

    private isValid(elm) {
        if (elm.nodeType === 1) {
            if (elm.nodeName.toLowerCase() === 'script') {
                return false;
            }
            for (let i = 0; i < elm.attributes.length; i++) {
                const val = elm.attributes[i].value;
                if (this.isStr(val) && val.toLowerCase().indexOf('on') === 0) {
                    return false;
                }
            }
            for (let i = 0; i < elm.childNodes.length; i++) {
                if (!this.isValid(elm.childNodes[i])) {
                    return false;
                }
            }
        }
        return true;
    }

    private _getSvgContent$(url) {
        if (typeof fetch !== 'undefined' && typeof document !== 'undefined') {
            if (environment.version) {
                url +=
                    url.indexOf('?') > -1
                        ? `&v=${environment.version}`
                        : `?v=${environment.version}`;
            }
            return from(fetch(url)).pipe(
                mergeMap((rsp) => from(rsp.text())),
                map((svgContent) => {
                    if (!svgContent) return EMPTY;
                    return this.sanitizer.bypassSecurityTrustHtml(this.validateContent(svgContent));
                }),
                shareReplay(),
            );
        } else {
            return EMPTY;
        }
    }

    // public getAssetPath(path) {
    //     const assetUrl = new URL(path, `${window.location.origin}/assets/`);
    //     return assetUrl.origin !== window.location.origin ? assetUrl.href : assetUrl.pathname;
    // }

    isStr(val): boolean {
        return typeof val === 'string';
    }
}
