import { DoBootstrap, Injector, NgModule } from '@angular/core';

import { ToolbarElement } from './toolbar.component';
import { createCustomElement } from '@angular/elements';

import { CommonModule } from '@angular/common';
import { IconModule } from '../icon/icon.module';
import { ButtonModule } from '../button/button.module';
import { BadgeModule } from '../badge/badge.module';
import { TabsModule } from '../tabs/tabs.module';
import { ChipModule } from '../chip/chip.module';
import { LinkModule } from '../link/link.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { ToggleModule } from '../toggle/toggle.module';

@NgModule({
    declarations: [ToolbarElement],
    exports: [ToolbarElement],
    imports: [
        CommonModule,
        IconModule,
        ButtonModule,
        BadgeModule,
        TabsModule,
        ChipModule,
        LinkModule,
        ToggleModule,
        TooltipModule,
    ],
})
export class ToolbarModule implements DoBootstrap {
    constructor(private injector: Injector) {}

    ngDoBootstrap(): void {
        const el = createCustomElement(ToolbarElement, {
            injector: this.injector,
        });

        customElements.define('bazis-toolbar', el);
    }
}
