<div
    class="bazis-modal-wrap"
    *transloco="let t"
>
    <ng-container *ngIf="organization$ | async as organization">
        <div class="bazis-modal__header">
            <h1>{{ t('profile.personalInfo.modalTitle') }}</h1>
        </div>
        <div class="bazis-modal__content">
            <app-profile-requisites [organizationSnapshot]="organization"></app-profile-requisites>
            <app-profile-okveds [organizationSnapshot]="organization"></app-profile-okveds>
            <app-profile-licenses [orgOwnerId]="organizationId"></app-profile-licenses>
        </div>
        <div class="bazis-modal__footer">
            <bazis-button
                color="action"
                (click)="close()"
                >{{ t('action.close') }}</bazis-button
            >
        </div>
    </ng-container>
</div>
