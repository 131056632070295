import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { InfiniteListComponent } from '@bazis/shared/components/infinite-list.component';
import { EntData } from '@bazis/shared/models/srv.types';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';

@Component({
    selector: 'bazis-infinite-list-sort-selected',
    templateUrl: './infinite-list-sort-selected.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfiniteListSortSelectedComponent extends InfiniteListComponent implements OnInit {
    @Input() selectedList$: Observable<EntData[]>;

    @Input() selectedMap: Map<any, boolean> = new Map([]);

    @Input() isMultiple: boolean = false;

    @Input() hasApplyBtn: boolean = false;

    @Output() cleared = new EventEmitter();

    @Output() applied = new EventEmitter();

    showSelectedGroup: boolean = true;

    showSearchGroup: boolean = true;

    listExludeSelected$;

    selectedListId$: Observable<string[]> = new BehaviorSubject([]);

    ngOnInit(): void {
        super.ngOnInit();

        this.selectedListId$ = this.selectedList$.pipe(map((list) => list.map((item) => item.id)));

        this.listExludeSelected$ = combineLatest([this.list$, this.selectedListId$]).pipe(
            map(([list, selectedListId]) => {
                if (!selectedListId || selectedListId.length === 0) return list;
                return list.filter((v) => selectedListId.indexOf(v.id) === -1);
            }),
        );
    }

    toggleSelectedGroup() {
        this.showSelectedGroup = !this.showSelectedGroup;
    }

    toggleSearchGroup() {
        this.showSearchGroup = !this.showSearchGroup;
    }

    clearAll() {
        this.cleared.emit();
    }

    applyAll() {
        this.applied.emit();
    }
}
