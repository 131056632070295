import { ViewportScroller } from '@angular/common';
import { ViewEncapsulation, Component, Input } from '@angular/core';

@Component({
    selector: 'bazis-totop',
    templateUrl: './totop.component.html',
    styleUrls: ['totop.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class TotopElement {
    @Input() sizeBtn: 'xs' | 'small' | 'default' | 'large' = 'large';

    @Input() icon: string = 'arrow-up';

    @Input() sizeIcon: 'xxs' | 'xs' | 's' | 'md' | 'l' | 'xl' = 'l';

    constructor(public viewportScroller: ViewportScroller) {}

    scrollToTop() {
        window.scrollTo({ top: 0 });
    }
}
