<ng-container *transloco="let t">
    <ng-container
        *ngIf="{
            list: list$ | async
        } as data"
    >
        <ng-container *ngIf="data.list; else tplLoading">
            <p>{{ t('crypto.selectCertificate') }}:</p>
            <ng-container *ngFor="let cert of data.list">
                <div
                    class="bazis-radio bazis-radio_block"
                    [class.bh-events-none]="cert.disabled"
                >
                    <label (click)="select(cert)">
                        <input
                            type="radio"
                            name="radio_sert"
                            [disabled]="cert.disabled"
                            [checked]="certificate?.certificate?.objid === cert.certificate.objid"
                            (change)="preventDefault($event)"
                            (click)="preventDefault($event)"
                        />
                        <div class="bazis-radio__label">
                            <div class="bh-overhide">
                                <p>
                                    <span class="bh-font-medium">{{ cert?.valueMap.CN }}</span>
                                </p>
                                <p class="bazis-text_secondary">{{ cert?.valueMap.O }}</p>
                                <p class="bazis-text_secondary">
                                    {{ cert?.fromDate | localDate : 'shortDate' }},
                                    {{ cert?.toDate | localDate : 'shortDate' }}
                                </p>
                            </div>
                        </div>
                    </label>
                </div>
                <!-- <pre>{{ cert | json }} </pre> -->
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-template #tplLoading>
        <bazis-default-loader></bazis-default-loader>
    </ng-template>
</ng-container>
