<ng-container *ngIf="{ tablist: tablist$ | async } as data"> </ng-container>
<div class="bazis-tabs__wrapper">
    <div
        class="bazis-tabs__container"
        #tabsContainer
        [style.transform]="containerWidth < allTabsWidth ? 'translateX(' + shift + 'px)' : 'none'"
    >
        <ng-content></ng-content>
    </div>
</div>

<div
    *ngIf="containerWidth < allTabsWidth"
    class="bazis-btn-group bh-hide-sm-down"
>
    <div class="bazis-btn-group__item">
        <bazis-button
            [disabled]="shift === 0"
            (click)="slideStart()"
        >
            <bazis-icon name="angle-left"></bazis-icon>
        </bazis-button>
    </div>

    <div class="bazis-btn-group__item">
        <bazis-button
            [disabled]="isEnd"
            (click)="slideEnd()"
        >
            <bazis-icon name="angle-right"></bazis-icon>
        </bazis-button>
    </div>
</div>
