import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import moment from 'moment';
import { debounce, debounceTime, filter, map, mergeMap, tap } from 'rxjs/operators';
import { Observable, of, shareReplay, Subject } from 'rxjs';
import { SHARE_REPLAY_SETTINGS } from '@bazis/configuration.service';

@Component({
    selector: 'bazis-timepicker',
    templateUrl: './timepicker.component.html',
    styleUrls: ['timepicker.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimepickerElement implements OnInit, AfterViewInit {
    _value: Date = null;

    @Input() set value(newValue: Date) {
        this._value = newValue;
    }

    get value(): Date {
        return this._value;
    }

    // TODO: не используется
    @Input()
    maxTime;

    // TODO: не используется
    @Input()
    minTime;

    @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('hContainer') hhContainerRef: ElementRef;

    @ViewChild('mContainer') mmContainerRef: ElementRef;

    @ViewChild('hList') hhListRef: ElementRef;

    @ViewChild('mList') mmListRef: ElementRef;

    scroll$ = new Subject<{ type: 'mm' | 'hh'; value: string }>();

    scrollHH$: Observable<{ type: 'mm' | 'hh'; value: string }>;

    scrollMM$: Observable<{ type: 'mm' | 'hh'; value: string }>;

    hhValue = '00';

    mmValue = '00';

    hhArray = [];

    mmArray = [];

    @HostListener('scroll', ['$event'])
    handleHHScroll(event) {
        // console.log('handleHHScroll', event.target.scrollTop);
        this.hhValue = this.getValueByPosition(event.target.scrollTop, 'hh');
        this.emitUpdateValue();
        this.scroll$.next({ value: this.hhValue, type: 'hh' });
    }

    @HostListener('scroll', ['$event'])
    handleMMScroll(event) {
        // console.log('handleMMScroll', event.target.scrollTop);
        this.mmValue = this.getValueByPosition(event.target.scrollTop, 'mm');
        this.emitUpdateValue();
        this.scroll$.next({ value: this.mmValue, type: 'mm' });
    }

    // DO NOT FILL - auto calculate
    styleSettings = { hh: null, mm: null };

    constructor() {}

    ngOnInit(): void {
        for (let i = 0; i < 10; i++) {
            this.hhArray.push('0' + i);
        }
        for (let i = 10; i < 24; i++) {
            this.hhArray.push(i.toString());
        }
        for (let i = 0; i < 10; i++) {
            this.mmArray.push('0' + i);
        }
        for (let i = 10; i < 60; i++) {
            this.mmArray.push(i.toString());
        }
        if (this.value === null) {
            this.value = new Date();
        }

        this.scrollHH$ = this.generateScrollObservable$('hh');

        this.scrollMM$ = this.generateScrollObservable$('mm');

        const hh = moment(this.value).hour();
        const mm = moment(this.value).minute();

        this.hhValue = moment(this.value).hour() < 10 ? `0${hh}` : hh.toString();
        this.mmValue = moment(this.value).minute() < 10 ? `0${mm}` : mm.toString();
    }

    generateScrollObservable$(type) {
        return this.scroll$.asObservable().pipe(
            filter((scroll) => scroll.type === type),
            debounceTime(200),
            tap((scroll) => this.processScroll(scroll)),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );
    }

    processScroll(scroll) {
        this.calculateSettings(scroll.type);

        const top =
            this.getArrByType(scroll.type).indexOf(scroll.value) *
            this.styleSettings[scroll.type].itemHeight;

        if (top === this.styleSettings[scroll.type].containerElement.scrollTop) return;

        this.styleSettings[scroll.type].containerElement.scrollTo({
            top,
            behavior: 'smooth',
        });
    }

    ngAfterViewInit() {
        this.scrollTo(null, this.hhArray.indexOf(this.hhValue), 'hh', 'instant');
        this.scrollTo(null, this.mmArray.indexOf(this.mmValue), 'mm', 'instant');
    }

    getArrByType(type) {
        return type === 'hh' ? this.hhArray : this.mmArray;
    }

    calculateSettings(type, forceRecalculation = false) {
        if (this.styleSettings[type] && !forceRecalculation) return;
        const listEl = type === 'hh' ? this.hhListRef : this.mmListRef;
        const arr = type === 'hh' ? this.hhArray : this.mmArray;
        const listHeight = listEl.nativeElement.offsetHeight;
        const activeItemEl = listEl.nativeElement.querySelector('.bazis-item--active');
        const activeItemHeight = activeItemEl.offsetHeight;
        const itemEl = listEl.nativeElement.querySelector('.bazis-item:not(.bazis-item--active)');
        this.styleSettings[type] = {
            listHeight,
            itemHeight: (listHeight - activeItemHeight) / (arr.length - 1),
            revertItemHeight: 1 / itemEl.offsetHeight,
            containerElement:
                type === 'hh'
                    ? this.hhContainerRef.nativeElement
                    : this.mmContainerRef.nativeElement,
        };
        // console.log('styleSettings:', this.styleSettings[type]);
    }

    emitUpdateValue() {
        this.valueChange.emit(`${this.hhValue}:${this.mmValue}`);
    }

    getValueByPosition(y, type) {
        this.calculateSettings(type);
        let arr = type === 'hh' ? this.hhArray : this.mmArray;
        let index = Math.round(y * this.styleSettings[type].revertItemHeight);
        // console.log('index:', index, 'pos:', y);
        return arr[index];
    }

    scrollTo(event, index, type, behavior = 'smooth') {
        this.calculateSettings(type);
        // console.log(index, type, { ...this.styleSettings[type] });

        let containerRef = type === 'hh' ? this.hhContainerRef : this.mmContainerRef;
        containerRef.nativeElement.scrollTo({
            top: index * this.styleSettings[type].itemHeight,
            behavior,
        });
    }
}
