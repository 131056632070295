<ng-container *transloco="let t">
    <div class="bazis-list bazis-list_select">
        <ng-container *ngFor="let item of menu">
            <div
                class="bazis-item"
                (click)="item.clickFn()"
            >
                {{ t(item.titleKey, item.titleParams) }}
            </div>
        </ng-container>
    </div>
</ng-container>
