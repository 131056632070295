import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TabsElement } from './tabs.component';
import { createCustomElement } from '@angular/elements';
import { IconModule } from '@bazis/shared/components/icon/icon.module';
import { TranslocoModule } from '@jsverse/transloco';
import { BadgeModule } from '@bazis/shared/components/badge/badge.module';

@NgModule({
    declarations: [TabsElement],
    imports: [CommonModule, RouterModule, IconModule, TranslocoModule, BadgeModule],
    exports: [TabsElement],
})
export class TabsModule implements DoBootstrap {
    constructor(private injector: Injector) {}

    ngDoBootstrap(): void {
        const el = createCustomElement(TabsElement, {
            injector: this.injector,
        });

        customElements.define('bazis-tabs', el);
    }
}
