import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LayoutPressFooterElement } from './layout-press-footer.component';
import { createCustomElement } from '@angular/elements';

@NgModule({
    declarations: [LayoutPressFooterElement],
    imports: [CommonModule],
    exports: [LayoutPressFooterElement],
})
export class LayoutPressFooterModule implements DoBootstrap {
    constructor(private injector: Injector) {}

    ngDoBootstrap(): void {
        const el = createCustomElement(LayoutPressFooterElement, {
            injector: this.injector,
        });

        customElements.define('bazis-layout-press-footer', el);
    }
}
