import { ViewEncapsulation, Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'bazis-thumbnail',
    template: `<img
            *ngIf="isLoaded"
            [src]="src"
            [alt]="alt"
            (error)="isLoaded = false"
        />
        <bazis-icon
            *ngIf="!isLoaded"
            color="action"
            name="picture"
        ></bazis-icon>`,
    styleUrls: ['thumbnail.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class ThumbnailElement {
    @Input() src: string;

    @Input() alt: string;

    @Input() width: number;

    @Input() height: number;

    @Input() radius: number;

    @HostBinding('role') get roleElement() {
        return 'img';
    }

    @HostBinding('style') get thumbnailStyle() {
        return {
            [`--width`]: this.width + 'px',
            [`--height`]: this.height + 'px',
            [`--border-radius`]: this.radius + 'px',
        };
    }

    isLoaded = true;

    constructor() {}
}
