import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TotopElement } from './totop.component';
import { createCustomElement } from '@angular/elements';
import { IconModule } from '@bazis/shared/components/icon/icon.module';
import { ButtonModule } from '@bazis/shared/components/button/button.module';

@NgModule({
    declarations: [TotopElement],
    imports: [CommonModule, IconModule, ButtonModule],
    exports: [TotopElement],
})
export class TotopModule implements DoBootstrap {
    constructor(private injector: Injector) {}

    ngDoBootstrap(): void {
        const el = createCustomElement(TotopElement, {
            injector: this.injector,
        });

        customElements.define('bazis-totop', el);
    }
}
