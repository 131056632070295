import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CardValueElement } from './card-value.component';
import { createCustomElement } from '@angular/elements';

@NgModule({
    declarations: [CardValueElement],
    imports: [CommonModule],
    exports: [CardValueElement],
})
export class CardValueModule implements DoBootstrap {
    constructor(private injector: Injector) {}

    ngDoBootstrap(): void {
        const el = createCustomElement(CardValueElement, {
            injector: this.injector,
        });

        customElements.define('bazis-card-value', el);
    }
}
