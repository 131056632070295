import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { BazisContextMenuService } from '@bazis/shared/services/context-menu.service';

@Component({
    selector: 'bazis-context-menus',
    templateUrl: './context-menus.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextMenusComponent implements OnDestroy, OnInit {
    constructor(public contextMenuService: BazisContextMenuService) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}
}
