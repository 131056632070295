import { Inject, Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { HttpClient } from '@angular/common/http';
import { EMPTY, forkJoin, Observable, of, retry } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import merge from 'lodash.merge';

@Injectable({ providedIn: 'root' })
export class BazisTranslocoHttpLoader implements TranslocoLoader {
    private _bazisPredefined = [
        'crypto',
        'document',
        'form',
        'setupTelemetry',
        'chat',
        'analytics',
    ];

    constructor(
        private http: HttpClient,
        @Inject('SKIP_PROJECT_PATHS') private skipProjectPaths: string[],
    ) {}

    getTranslation(langPath: string) {
        let langParts = langPath.split('/');
        const currentLang = langParts.pop();

        let obs: Observable<any>[] = langParts.length
            ? []
            : [
                  this.http.get<Translation>(`/assets/i18n/${currentLang}.json`),
                  this.http.get<Translation>(`/assets/bazis-i18n/${currentLang}.json`),
              ];

        langParts.forEach((path) => {
            if (this.skipProjectPaths.indexOf(path) > -1) {
                obs.push(of({}));
            } else {
                obs.push(this.http.get<Translation>(`/assets/i18n/${path}/${currentLang}.json`));
            }

            if (this._bazisPredefined.indexOf(path) > -1) {
                obs.push(
                    this.http.get<Translation>(`/assets/bazis-i18n/${path}/${currentLang}.json`),
                );
            } else {
                obs.push(of({}));
            }
        });

        return forkJoin([...obs]).pipe(
            map(([translation, bazisTranslation]) => merge(bazisTranslation, translation)),
            retry(3),
            catchError((e) => EMPTY),
        );
    }
}
