import { ChangeDetectionStrategy, Component, Input, OnInit, OnDestroy } from '@angular/core';
import { BazisModalService } from '@bazis/shared/services/modal.service';

@Component({
    selector: 'bazis-simple-modal',
    templateUrl: './simple-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleModalComponent implements OnInit, OnDestroy {
    @Input()
    message = '';

    @Input()
    titleKey = '';

    constructor(private modalService: BazisModalService) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}

    closeModal() {
        this.modalService.dismiss();
    }
}
