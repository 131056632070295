<ng-container *ngIf="viewportScroller.getScrollPosition()[1] > 300">
    <bazis-button
        part="button"
        [size]="sizeBtn"
        (click)="scrollToTop()"
    >
        <bazis-icon
            part="icon"
            slot="icon-only"
            [size]="sizeIcon"
            [icon]="icon"
        ></bazis-icon>
    </bazis-button>
</ng-container>
