// Angular
import { Pipe, PipeTransform } from '@angular/core';
import { BazisConfigurationService } from '@bazis/configuration.service';

@Pipe({
    name: 'scheduleTime',
})
export class ScheduleTimePipe implements PipeTransform {
    constructor(private configurationService: BazisConfigurationService) {}

    /**
     * Transform
     *
     * @param value: number
     */
    transform(value: number): string {
        if (!value && value !== 0) return this.configurationService.emptyInputValue;
        const min = value % 60;
        const minStr = min < 10 ? `0${min}` : `${min}`;
        const h = Math.floor(value / 60);
        const hourStr = h < 10 ? `0${h}` : `${h}`;

        return `${hourStr}:${minStr}`;
    }
}
