import { Component, Input, OnInit } from '@angular/core';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { shareReplay } from 'rxjs';
import { ConfigurationService, SHARE_REPLAY_SETTINGS } from '@app/configuration.service';
import { BazisModalService } from '@bazis/shared/services/modal.service';
import { map } from 'rxjs/operators';
import { EntData } from '@bazis/shared/models/srv.types';

@Component({
    selector: 'app-modal-organization-requisites',
    templateUrl: './modal-organization-requisites.component.html',
})
export class ModalOrganizationRequisitesComponent implements OnInit {
    @Input() organizationId;

    organization$;

    licenses$;

    emptyInputValue = this.configurationService.emptyInputValue;

    constructor(
        private entityService: BazisEntityService,
        private modalService: BazisModalService,
        private configurationService: ConfigurationService,
    ) {}

    ngOnInit() {
        this.organization$ = this.entityService.getOrganizationEntity$(this.organizationId).pipe(
            map((organization: EntData) => {
                const info = organization.$snapshot;
                const fullName =
                    info.name_full && info.name_short
                        ? `${info.name_full} (${info.name_short})`
                        : info.name_full || info.name_short;
                return {
                    ...info,
                    fullName,
                };
            }),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );
    }

    close() {
        this.modalService.dismiss();
    }
}
