import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { fileDownloaderFn } from '@bazis/utils';

@Component({
    selector: 'bazis-signature',
    templateUrl: './signature.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisSignatureComponent {
    @Input()
    signatureId: string;

    showSignature = false;

    constructor() {}

    downloadSign(signature) {
        fileDownloaderFn(`data:application/zip;base64,${signature}`, 'Электронная_подпись.sig');
    }
}
