import { TranslocoModule, provideTransloco, provideTranslocoTranspiler } from '@jsverse/transloco';
import { NgModule } from '@angular/core';
import { environment } from '@environments/environment';
import { AVAILABLE_LANGUAGES, SELECTED_LANGUAGE } from '@app/configuration.service';
import { BazisTranslocoHttpLoader } from '@bazis/shared/services/transloco-loader.service';
import {
    MessageFormatTranspiler,
    provideTranslocoMessageformat,
} from '@jsverse/transloco-messageformat';

@NgModule({
    exports: [TranslocoModule],
    providers: [
        provideTranslocoMessageformat(),
        provideTransloco({
            config: {
                availableLangs: AVAILABLE_LANGUAGES,
                defaultLang: SELECTED_LANGUAGE,
                fallbackLang: AVAILABLE_LANGUAGES[0],
                prodMode: environment.production,
            },
            loader: BazisTranslocoHttpLoader,
        }),
        provideTranslocoTranspiler(MessageFormatTranspiler),
        { provide: 'SKIP_PROJECT_PATHS', useValue: ['crypto', 'setupTelemetry'] },
    ],
})
export class TranslocoRootModule {}
