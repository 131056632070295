import { Inject, Injectable, InjectionToken } from '@angular/core';
import { BazisSrvService } from '@bazis/shared/services/srv.service';
import { debounceTime, map, tap } from 'rxjs/operators';
import { of, shareReplay, switchMap, combineLatest, withLatestFrom } from 'rxjs';
import { SHARE_REPLAY_SETTINGS } from '@bazis/configuration.service';
import { TemplateObservable } from '@bazis/shared/classes/template-observable';
import { BazisAuthService } from '@bazis/shared/services/auth.service';

export const ALL_SEARCH_MODELS_DATA = new InjectionToken<any[]>('', {
    providedIn: 'root',
    factory: () => [],
});

export const ROLE_SEARCH_MODEL_IDS_DATA = new InjectionToken<{ [index: string]: any }>('', {
    providedIn: 'root',
    factory: () => ({}),
});

@Injectable({
    providedIn: 'root',
})
export class BazisSearchService {
    search = new TemplateObservable('');

    model = new TemplateObservable('all');

    page = new TemplateObservable(0);

    isLoading = new TemplateObservable(false);

    private _pageLimit = 20;

    private _debounceTime = 500;

    searchModelsMap = this.allSearchModels.reduce((acc, current) => {
        acc[current.id] = current;
        return acc;
    }, {});

    roleModels = Object.keys(this.roleModelIds).reduce((acc, current) => {
        acc[current] = this.roleModelIds[current].map((model) => this.searchModelsMap[model]);
        return acc;
    }, {});

    searchModels$ = this.authService.role$.pipe(
        tap((v) => {
            this.model.set('all');
            this.page.set(0);
        }),
        map((role) => this.roleModels[role]),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    canSearch$ = this.searchModels$.pipe(
        map((models) => models.length > 0),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    results$ = combineLatest([this.model.$, this.search.$]).pipe(
        tap(() => {
            this.isLoading.set(true);
        }),
        debounceTime(this._debounceTime),
        switchMap(([model, search]) => {
            const params: any = { query: search };
            if (model !== 'all') params.model_name = model;
            return search
                ? this.srv.commonGetRequest$('common/search', params)
                : of({ result: [] });
        }),
        tap((r) => {
            this.setPage(0);
            this.isLoading.set(false);
        }),
        withLatestFrom(this.searchModels$),

        map(([response, searchModels]) =>
            this._filterResultsByModels(searchModels, response.result),
        ),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    shortListResults$ = this.results$.pipe(
        map((v) => v.slice(0, 2)),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    pageResults$ = combineLatest([this.results$, this.page.$]).pipe(
        map(([results, page]) =>
            results.slice(page * this._pageLimit, (page + 1) * this._pageLimit),
        ),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    panelCount$ = combineLatest([this.search.$, this.searchModels$]).pipe(
        debounceTime(this._debounceTime),
        switchMap(([search, searchModels]) => {
            return search
                ? this.srv.groupRequest$(
                      searchModels.map((model) => {
                          const params: any = { query: search };
                          if (model.id !== 'all') params.model_name = model.id;

                          return {
                              entityType: 'common.search',
                              requestType: 'any',
                              meta: [],
                              params,
                              method: 'GET',
                          };
                      }),
                  )
                : of(searchModels.map((model) => ({ result: [] })));
        }),
        withLatestFrom(this.searchModels$),
        map(([response, searchModels]) => {
            return searchModels.map((model, index) => {
                return {
                    id: model.id,
                    count:
                        this._filterResultsByModels(searchModels, response[index].result).length ||
                        0,
                };
            });
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    pagination$ = combineLatest([this.results$, this.page.$]).pipe(
        map(([results, page]) => {
            return {
                offset: page * this._pageLimit,
                limit: this._pageLimit,
                count: results.length,
            };
        }),
    );

    constructor(
        protected srv: BazisSrvService,
        protected authService: BazisAuthService,
        @Inject(ALL_SEARCH_MODELS_DATA) public allSearchModels,
        @Inject(ROLE_SEARCH_MODEL_IDS_DATA) public roleModelIds,
    ) {}

    setModel(id) {
        this.model.set(id);
    }

    setPage(page) {
        this.page.set(page);
    }

    setSearch(text) {
        this.search.set(text);
    }

    protected _filterResultsByModels(models, results) {
        const modelsMap = models.reduce((acc, current) => {
            acc[current.id] = true;
            return acc;
        }, {});
        return results
            .filter((v) => modelsMap[v.model_name])
            .map((item) => {
                return {
                    ...item,
                    description: Object.values(item.query_data).join(', '),
                };
            });
    }

    reset() {
        this.setPage(0);
        this.setSearch('');
        this.setModel('all');
    }
}
