import { DoBootstrap, Injector, NgModule } from '@angular/core';

import { IconElement } from './icon.component';
import { createCustomElement } from '@angular/elements';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [IconElement],
    imports: [CommonModule],
    exports: [IconElement],
})
export class IconModule implements DoBootstrap {
    constructor(private injector: Injector) {}

    ngDoBootstrap(): void {
        const el = createCustomElement(IconElement, {
            injector: this.injector,
        });

        customElements.define('bazis-icon', el);
    }
}
