import { Component, Injectable } from '@angular/core';
import moment from 'moment';
import { TemplateObservable } from '@bazis/shared/classes/template-observable';

import { combineLatest, Observable, of, shareReplay, switchMap, withLatestFrom } from 'rxjs';
import { BazisAuthService } from '@bazis/shared/services/auth.service';
import { SHARE_REPLAY_SETTINGS } from '@bazis/configuration.service';
import { catchError, debounceTime, filter, map, startWith, tap } from 'rxjs/operators';
import { BazisSrvService } from '@bazis/shared/services/srv.service';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import {
    AnalyticDashboard,
    AnalyticGroup,
    AnalyticGroupData,
    AnalyticSettings,
} from '@bazis/analytics/shared/models/analytic.types';
import { BazisHorizontalPercentageBarsComponent } from '@bazis/analytics/shared/components/horizontal-percentage-bars/horizontal-percentage-bars.component';
import { BazisAnalyticsStatusCircleDiagramComponent } from '@bazis/analytics/shared/components/status-circle-diagram/status-circle-diagram.component';
import { BazisDateDiagramComponent } from '@bazis/analytics/shared/components/date-diagram/date-diagram.component';

@Injectable({ providedIn: 'root' })
export class BazisAnalyticService {
    date: TemplateObservable<string> = new TemplateObservable(this._today());

    analyticSettings: TemplateObservable<AnalyticSettings> = new TemplateObservable(null);

    selectedSection: TemplateObservable<string> = new TemplateObservable(null);

    selectedGroups: TemplateObservable<{ [index: string]: string[] }> = new TemplateObservable({});

    dashboard: TemplateObservable<AnalyticDashboard> = new TemplateObservable(undefined);

    colors = {
        primary: '#00A2AD',
        secondary: '#43B1F2',
        tertiary: '#817AF9',
        action: '#164982',
        info: '#C4C4C4',
        success: '#78C649',
        warning: '#F6BF36',
        danger: '#ED1A34',
        light: '#f4f5f8',
        medium: '#92949c',
        dark: '#111214',
    };

    reports: {
        buttonSettings: {
            titleKey: string;
            color?: string;
            iconStart?: string;
            iconEnd?: string;
            fill?: 'solid' | 'outline' | 'clear';
            size?: 'xs' | 'small' | 'default' | 'large';
        };
        component: any;
        componentProperties: any;
    }[] = [];

    private _bazisDisplayComponents = {
        horizontalPercentageBar: BazisHorizontalPercentageBarsComponent,
        statusCircleDiagram: BazisAnalyticsStatusCircleDiagramComponent,
        dateDiagram: BazisDateDiagramComponent,
    };

    protected appDisplayComponents = {};

    displayComponents = {
        ...this._bazisDisplayComponents,
        ...this.appDisplayComponents,
    };

    private _bazisDataTransformers = {
        dates: this.dateTransformer,
    };

    protected appDataTransformers = {};

    dataTransformers = {
        ...this._bazisDataTransformers,
        ...this.appDataTransformers,
    };

    dashboards$ = this.authService.user$.pipe(
        switchMap(() =>
            this.entityService.getEntityList$('analytic/analytics/analytical_dashboard', {
                limit: 1000,
            }),
        ),
        map((response) => {
            const dashboards = response.list;

            const settings = dashboards.map((dashboard) => {
                const group = dashboard.$snapshot.group_info.reduce((acc, current) => {
                    return {
                        ...acc,
                        [current.field_label]: {
                            id: current.field_label,
                            title: current.name,
                            filterTitle: current.filter_name || current.name,
                            componentType: current.visualization_type,
                            dataType: current?.visualization_settings?.dataType,
                            componentProperties:
                                current?.visualization_settings?.componentProperties || {},
                            tabTitle: current?.visualization_settings?.tabTitle || current.name,
                        },
                    };
                }, {});
                const config = dashboard.$snapshot.config_groups;
                if (config?.templateGroup) {
                    Object.keys(config.templateGroup).forEach((key) => {
                        if (
                            !config.templateGroup[key].title &&
                            config.templateGroup[key].groupIds?.length > 0
                        ) {
                            config.templateGroup[key].title =
                                group[config.templateGroup[key].groupIds[0]].title;
                        }
                        if (!config.templateGroup[key].mode) {
                            config.templateGroup[key].mode =
                                config.templateGroup[key].groupIds?.length === 1
                                    ? 'default'
                                    : 'tabs';
                        }
                    });
                }

                return {
                    id: dashboard.id,
                    label: dashboard.$snapshot.label,
                    title: dashboard.$snapshot.name,
                    availableGroups: dashboard.$snapshot.group_info.map((v) => v.field_label),
                    availableSections: dashboard.$snapshot.section_info.map((v) => v.field_label),
                    sections: dashboard.$snapshot.section_info.reduce((acc, current) => {
                        return { ...acc, [current.field_label]: current };
                    }, {}),
                    group,
                    template: config?.template,
                    templateGroup: config?.templateGroup,
                };
            });
            this.dashboard.set(settings.length > 0 ? settings[0] : null);
            this.selectedSection.set(settings.length > 0 ? settings[0].availableSections[0] : null);
            return settings;
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    // personal settings
    userFilters$ = this.authService.user$.pipe(
        map((user) => {
            return {};
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    sections$ = combineLatest([
        this.date.$,
        this.dashboard.$,
        this.selectedGroups.$,
        this.userFilters$,
    ]).pipe(
        filter(([date, dashboard, selectedGroups, userFilters]) => date && !!dashboard),
        debounceTime(0),
        switchMap(([date, dashboard, selectedGroups, userFilters]) =>
            this._getSectionData(date, dashboard, selectedGroups, userFilters),
        ),
        map((response) => (response ? response[0] : null)),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    groups$ = combineLatest([
        this.date.$,
        this.dashboard.$,
        this.selectedSection.$,
        this.selectedGroups.$,
        this.userFilters$,
    ]).pipe(
        filter(
            ([date, dashboard, section, selectedGroups, userFilters]) =>
                date && !!dashboard && !!section,
        ),
        debounceTime(0),
        switchMap(([date, dashboard, section, selectedGroups, userFilters]) => {
            return combineLatest(
                dashboard.availableGroups.map((group: string) =>
                    this._getGroupData(
                        date,
                        dashboard,
                        section,
                        group,
                        selectedGroups,
                        userFilters,
                    ),
                ),
            ).pipe(
                filter((response) => response.findIndex((v) => !v) === -1),
                withLatestFrom(this.dashboard.$),
                map(([response, dashboard]) => {
                    const result = {};
                    dashboard.availableGroups.forEach((groupId, index) => {
                        result[groupId] =
                            dashboard.group[groupId].dataType &&
                            this.dataTransformers[dashboard.group[groupId].dataType]
                                ? this.dataTransformers[dashboard.group[groupId].dataType](
                                      response[index],
                                  )
                                : response[index];
                    });
                    return result;
                }),
                startWith(undefined),
            );
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    constructor(
        private authService: BazisAuthService,
        private srv: BazisSrvService,
        private entityService: BazisEntityService,
    ) {}

    setAnalyticSettings() {
        this.selectedSection.set(null);
        this.selectedGroups.set({});
        this.date.set(this._today());
    }

    setDate(date) {
        this.date.set(date || this._today());
    }

    setDashboard(dashboard: AnalyticDashboard) {
        this.dashboard.set(dashboard);
        this.setSection(dashboard.availableSections[0]);
        this.setSelectedGroup(null, null);
    }

    setSection(sectionId) {
        if (this.selectedSection._ === sectionId) return;
        this.selectedSection.set(sectionId);
    }

    setSelectedGroup(value, groupSettings: AnalyticGroup) {
        const selectedGroup = { ...this.selectedGroups._ };

        if (value === null) {
            if (groupSettings === null) {
                this.selectedGroups.set({});
                return;
            }
            delete selectedGroup[groupSettings.id];
            return;
        }

        if (!selectedGroup[groupSettings.id]) {
            selectedGroup[groupSettings.id] = [];
        }

        const index = selectedGroup[groupSettings.id].indexOf(value);
        if (index === -1) {
            if (groupSettings.selectionType === 'radio') {
                selectedGroup[groupSettings.id] = [];
            }
            selectedGroup[groupSettings.id].push(value);
            this.selectedGroups.set(selectedGroup);
            return;
        }

        if (groupSettings.selectionType === 'radio') {
            delete selectedGroup[groupSettings.id];
        } else {
            selectedGroup[groupSettings.id] = selectedGroup[groupSettings.id].filter(
                (v) => v !== value,
            );
        }

        this.selectedGroups.set(selectedGroup);
    }

    private _getSectionData(
        date: string,
        dashboard: AnalyticDashboard,
        selectedGroups: any,
        userFilters: any,
    ) {
        return this.srv
            .commonGetRequest$(`analytic/analytics/analytical_dashboard/${dashboard.id}/get_data`, {
                dt_event: date,
                ...this._selectedGroupsToParams(selectedGroups),
                ...selectedGroups,
            })
            .pipe(
                startWith(undefined),
                catchError((e) => of(null)),
            );
    }

    private _getGroupData(
        date: string,
        dashboard: AnalyticDashboard,
        section: string,
        group: string,
        selectedGroups: any,
        userFilters: any,
    ) {
        const currentSelectedGroups = {
            ...this._selectedGroupsToParams(selectedGroups),
            ...userFilters,
        };
        delete currentSelectedGroups[`${group}__in`];

        let params: any = { group, dt_event: date, ...currentSelectedGroups, limit: 1000 };
        if (section) {
            params.section = section;
        }

        return this.srv
            .commonGetRequest$(
                `analytic/analytics/analytical_dashboard/${dashboard.id}/get_data`,
                params,
            )
            .pipe(
                startWith(undefined),
                catchError((e) => of(null)),
            );
    }

    private _selectedGroupsToParams(selectedGroups) {
        return Object.keys(selectedGroups).reduce(
            (acc, current) => ({ ...acc, [`${current}__in`]: selectedGroups[current].join(',') }),
            {},
        );
    }

    private _today() {
        return moment().format('YYYY-MM-DD');
    }

    dateTransformer(data: AnalyticGroupData[]) {
        const dataMap = data.reduce(
            (acc, current) => ({ ...acc, [current.id]: +current.value }),
            {},
        );

        const dataYears = new Set(data.map((v) => +v.id.substring(0, 4)));
        const currentYear = moment().year();
        dataYears.add(currentYear);
        const years = Array.from(dataYears).sort();

        const result = {};
        years.forEach((year: number) => {
            const yearData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].reduce(
                (acc, current) => ({ ...acc, [current]: { max: 0 } }),
                {},
            );

            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].forEach((month) => {
                const daysInMonth = moment(`${year}-${month}`, 'YYYY-M').daysInMonth();
                const monthStr = month < 10 ? `0${month}` : month;
                for (let day = 1; day <= daysInMonth; day++) {
                    const dayStr = day < 10 ? `0${day}` : day;
                    yearData[month][day] = dataMap[`${year}-${monthStr}-${dayStr}`] || 0;
                    yearData[month].max =
                        yearData[month][day] > yearData[month].max
                            ? yearData[month][day]
                            : yearData[month].max;
                }
            });

            result[year] = yearData;
        });
        return result;
    }
}
