import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { InfiniteListComponent } from '@bazis/shared/components/infinite-list.component';

@Component({
    selector: 'bazis-loadmore-list',
    templateUrl: './loadmore-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadMoreListComponent extends InfiniteListComponent {
    @HostBinding('class') get hostStyle() {
        return {
            'bazis-list': false,
            'bazis-list_control-dd': false,
        };
    }
}
