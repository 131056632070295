import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { TemplateObservable } from '@bazis/shared/classes/template-observable';
import { BehaviorSubject } from 'rxjs';

interface TabItem {
    id: string | number;
    title?: string;
    titleKey?: string;
    titleParams?: any;
    entityType?: string;
    routerLink?: string;
    isExactLink?: boolean;
    disabled?: boolean;
    // name иконки или src
    icon?: string;
    iconPosition?: 'start' | 'end';
    underconstruction?: boolean;
    hidden?: boolean;
}

@Component({
    selector: 'bazis-collapsible-to-more',
    templateUrl: './collapsible-to-more.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsibleToMoreComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    @Input()
    tabList: TabItem[];

    @Input()
    selectedTab = new TemplateObservable(null);

    @Input()
    hideByZeroCount = false;

    @Input()
    needScroll = true;

    @Output()
    selectedTabChange = new EventEmitter();

    @Input() tabTemplate: TemplateRef<any>;

    // @Input() tabsLoaded: boolean;

    @ViewChild('wrapper') set wrapperEl(el: ElementRef) {
        if (!el) return;
        this.wrapper = el.nativeElement;
    }

    @ViewChild('endPart') set endPartEl(el: ElementRef) {
        if (!el) return;
        this.endPart = el.nativeElement;
    }

    @ViewChild('bazisTabs', { read: ElementRef, static: false }) set bazisTabsEl(el: ElementRef) {
        if (!el) return;
        this.bazisTabs = el;

        this._bazisTabsResizeObserver = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                const width = entry.borderBoxSize
                    ? entry.borderBoxSize[0].inlineSize
                    : entry.contentRect.width;
                this.collapsibleTabs(width);
            });
        });
        this._bazisTabsResizeObserver.observe(this.bazisTabs.nativeElement);
    }

    @HostListener('window:resize') onResize() {
        this.availableSpace =
            this.wrapper.offsetWidth - this.endPart.offsetWidth - this.moreTabWidth;

        this.collapsibleTabs(this.bazisTabs.nativeElement.offsetWidth);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.tabList?.previousValue && changes.tabList?.currentValue) {
            this.breaks = [];
        }
    }

    wrapper;

    endPart;

    bazisTabs;

    hasMore$ = new BehaviorSubject(false);

    breaks = [];

    availableSpace;

    protected moreTabWidth = 132;

    _bazisTabsResizeObserver;

    // введено чтобы changeDetection в bazis-tabs срабатывал при не очевидных изменениях свойств tabList
    forceChangeCounter: number = 0;

    hiddenItemsCounter = 0;

    isShakeTabs$ = new TemplateObservable(true);

    constructor() {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        this.availableSpace =
            this.wrapper.offsetWidth - this.endPart.offsetWidth - this.moreTabWidth;
    }

    ngOnDestroy(): void {
        if (this._bazisTabsResizeObserver) {
            this._bazisTabsResizeObserver.unobserve(this.bazisTabs.nativeElement);
        }
    }

    collapsibleTabs(tabsWidth) {
        if (!this.tabList) return;

        const lengthBreaks = this.breaks.length;

        if (this.breaks.length > 0) tabsWidth = tabsWidth - this.moreTabWidth;

        if (tabsWidth > this.availableSpace && this.tabList.length) {
            this.hasMore$.next(true);

            const lastIndexVisible =
                this.tabList.findIndex((tabItem) => tabItem.hidden === true) !== -1
                    ? this.tabList.findIndex((tabItem) => tabItem.hidden === true) - 1
                    : this.tabList.length - 1;
            if (lastIndexVisible > 0) {
                // Record the width of the list
                this.breaks.push(tabsWidth);
                // Move item to the hidden list
                this.tabList[lastIndexVisible].hidden = true;
                this.forceChangeCounter++;
            }
        } else {
            if (lengthBreaks === 0) {
                this.isShakeTabs$.set(false);
                this.hasMore$.next(false);
                return;
            }

            // There is space for another item in the nav
            while (this.breaks.length && this.availableSpace > this.breaks[0]) {
                // Move the item to the visible list
                let firstIndexHidden = this.tabList.findIndex((tab) => tab.hidden === true);
                this.tabList[firstIndexHidden].hidden = false;
                this.breaks.shift();
                this.forceChangeCounter++;
            }

            const hasHiddenItem = this.tabList.some((tab) => tab.hidden === true);

            if (!hasHiddenItem) this.hasMore$.next(false);
        }

        this.hiddenItemsCounter = this.breaks.length;

        if (lengthBreaks === this.breaks.length) {
            this.isShakeTabs$.set(false);
        } else {
            this.isShakeTabs$.set(true);
        }

        // console.log('[BREAKS]', this.breaks.length);
    }

    changeSelectedTab(e) {
        this.selectedTabChange.emit(e);
        // this.selectedTab = e;
        // this.selectedTab.set(e)
        // console.log('collapsible, changeSelectedTab', this.selectedTab, e);
    }
}
