import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'top',
            anchorScrolling: 'enabled',
            scrollOffset: [0, 64], // [x, y]
            preloadingStrategy: NoPreloading,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
