import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'bazis-step',
    templateUrl: './step.component.html',
    styleUrls: ['./step.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class StepElement {
    // перенос строк для метки шага
    @Input() oneline: boolean = false;

    // положение значка относительно метки
    @Input() position: 'start' | 'end' = 'start';

    // направление следования значка и метки - вертикальное или горизонтальное
    @Input() direction: 'row' | 'column' = 'row';

    // тип значка
    @Input() typeSign: 'icon' | 'number' | 'other' | 'none' = 'icon';

    // заполненость значка
    @Input() fillSign: 'solid' | 'outline' | 'clear' | 'double' = 'solid';

    // значек иконки, ключ иконки или путь до иконки, используется при typeSign = 'icon'
    @Input() iconSign: string = 'check';

    // скрывать значок, через visible=hidden, необходимо сохранять промежуток
    @Input() signHidden: boolean = false;

    // замьюченность шага
    @Input() mute: boolean = false;

    // активность шага
    @Input() active: boolean = false;

    @HostBinding('class') get stepStyle() {
        return {
            [`bazis-step_${this.fillSign}`]: true,
            [`bazis-step_${this.typeSign}`]: true,
            'bazis-step_column': this.direction === 'column',
            'bazis-step_reverse': this.position === 'end',
            'bazis-step_nowrap': this.oneline,
            'bazis-step--muted': this.mute,
            'bazis-step--active': this.active,
        };
    }

    constructor() {}
}
