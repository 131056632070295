import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';
import moment from 'moment';
import { FormControl } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { shareReplay } from 'rxjs';
import { SHARE_REPLAY_SETTINGS } from '@app/configuration.service';

@Component({
    selector: 'bazis-analytics-date-diagram',
    templateUrl: './date-diagram.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisDateDiagramComponent implements OnInit {
    @Input()
    groupData: { [item: string]: { [item: string]: { [item: string]: number } } };

    @Input()
    selectedValues: string[];

    @Output()
    select = new EventEmitter();

    monthes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    days = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        26, 27, 28, 29, 30, 31,
    ];

    get years() {
        return Object.keys(this.groupData);
    }

    get yearsList() {
        return this.years.map((year) => {
            return { id: `${year}`, name: year };
        });
    }

    get formattedSelected() {
        return this.selectedValues
            ? this.selectedValues.map((date) =>
                  date
                      .split('-')
                      .map((v) => +v)
                      .join('-'),
              )
            : [];
    }

    monthesTabs = this.monthes.map((month) => {
        return {
            id: month,
            titleKey: `months.long.${month}`,
        };
    });

    currentMonth = moment().month();

    currentYear = moment().year();

    currentDay = moment().date();

    selectedYear = new FormControl(`${this.currentYear}`);

    selectedMonth = this.currentMonth + 1;

    changes$ = this.selectedYear.valueChanges.pipe(
        tap((year) => {
            this.selectedMonth = +year === +this.currentYear ? this.currentMonth + 1 : 1;
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    get max() {
        return this.groupData[this.selectedYear.value][this.selectedMonth].max > 5
            ? this.groupData[this.selectedYear.value][this.selectedMonth].max
            : 5;
    }

    get daysTotal() {
        return Object.keys(this.groupData[this.selectedYear.value][this.selectedMonth]).length - 1;
    }

    constructor() {}

    ngOnInit(): void {}

    selectDate(day) {
        const month = this.selectedMonth > 9 ? this.selectedMonth : `0${this.selectedMonth}`;
        day = day > 9 ? day : `0${day}`;
        this.select.emit(`${this.selectedYear.value}-${month}-${day}`);
    }
}
