import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ValidationErrorMessages } from '@bazis/form/models/form-element.types';

@UntilDestroy()
@Component({
    selector: 'bazis-display-error',
    templateUrl: './display-error.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisDisplayErrorComponent {
    @HostBinding('class') get hostStyle() {
        return {
            'bazis-display-error': true,
            [`bazis-display-error_icon`]: this.hasIcon,
        };
    }

    @Input()
    errorKey;

    @Input()
    errorParams;

    @Input()
    error;

    @Input()
    validationErrorMessages: ValidationErrorMessages;

    @Input()
    hasIcon = false;
}
