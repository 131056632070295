import { Injectable } from '@angular/core';
import { EntData, EntList } from '@bazis/shared/models/srv.types';
import { BazisSrvService } from '@bazis/shared/services/srv.service';
import {
    combineLatest,
    merge,
    mergeMap,
    Observable,
    of,
    shareReplay,
    Subject,
    switchMap,
    throwError,
    withLatestFrom,
} from 'rxjs';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { catchError, debounceTime, filter, map, take, tap } from 'rxjs/operators';
import { SHARE_REPLAY_SETTINGS } from '@bazis/configuration.service';
import { BazisToastService } from '@bazis/shared/services/toast.service';
import { BazisDocumentService } from '@bazis/shared/services/document.service';
import { buildFilterStr, fileDownloaderFn } from '@bazis/utils';
import { BazisWebSocketService } from '@bazis/shared/services/web-socket.service';
import { BazisAuthService } from '@bazis/shared/services/auth.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class BazisPaymentService {
    updatePaymentData$: Subject<boolean> = new Subject();

    socketPaymentInfo$ = this.socketService.message$.pipe(
        filter((v) => v && v?.$snapshot?.notice_type === 'notice.payment'),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    paymentAccount$: Observable<EntData> = merge(
        this.authService.accountType$,
        this.updatePaymentData$,
        this.socketPaymentInfo$,
    ).pipe(
        debounceTime(0),
        filter((v) => !!v),
        withLatestFrom(this.authService.accountType$, this.authService.organizationId$),
        switchMap(([initiator, accountType, organizationId]) => {
            return accountType !== 'none'
                ? this.entityService.getEntityList$(`payment.payment_account_${accountType}`, {
                      params: { filter: buildFilterStr({ org_owner: organizationId }) },
                      limit: 1,
                  })
                : of(null);
        }),
        map((account: EntList) => {
            if (!account || !account.list[0]) return null;
            return account.list[0];
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    constructor(
        protected documentService: BazisDocumentService,
        protected entityService: BazisEntityService,
        protected srv: BazisSrvService,
        protected authService: BazisAuthService,
        protected toastService: BazisToastService,
        protected socketService: BazisWebSocketService,
        protected router: Router,
    ) {
        this.paymentAccount$.subscribe();
    }

    depositInvoice$(amount) {
        return this.srv
            .sendFormRequest$('payment/payment_account_customer/deposit_invoice', {
                amount: amount,
            })
            .pipe(
                map((entity) => {
                    fileDownloaderFn(
                        entity.data.attributes.file_upload_url,
                        'deposit-invoice-' + `${amount}`.replace('.', ','),
                    );
                }),
                catchError((error) => {
                    this.toastService.create({
                        titleKey: 'profile.account.error.depositErrorTitle',
                        messageKey: this.srv.generateErrorMessage(error),
                        type: 'error',
                    });
                    return of(error);
                }),
            );
    }

    withdrawInvoice$(sum) {
        return this.srv
            .sendFormRequest$(
                'payment/payment_account_customer/withdraw_invoice',
                { amount: sum },
                true,
            )
            .pipe(
                switchMap((entity) => {
                    return this.documentService
                        .createGroupToSignBySettings([
                            {
                                entityId: entity.id,
                                entityType: entity.$snapshot.type,
                                document: entity,
                                signBodyPayload: { note: 'Вывод средств' },
                                withoutIncludedDocuments: true,
                            },
                        ])
                        .pipe(
                            filter((remaining: []) => !remaining || !remaining.length),
                            mergeMap((remaining) => {
                                if (!remaining || remaining.length > 0) {
                                    return throwError(null);
                                }
                                if (remaining.length === 0) {
                                    this.toastService.create({
                                        titleKey: 'profile.account.success.withdrawSuccessTitle',
                                        type: 'success',
                                    });
                                    this.updatePaymentData$.next(true);
                                    return of(remaining);
                                }
                            }),
                        );
                }),
                catchError((error) => {
                    this.toastService.create({
                        titleKey: 'profile.account.error.withdrawErrorTitle',
                        messageKey: error
                            ? this.srv.generateErrorMessage(error)
                            : 'profile.account.error.withdrawSignErrorMessage',
                        type: 'error',
                    });
                    return of(error);
                }),
            );
    }

    processOnlinePayment$(entity: EntData, forceMethodType = 'url') {
        return of(entity).pipe(
            filter(
                (entity) =>
                    !!entity &&
                    entity.$snapshot.acquiring_readiness === 'ready' &&
                    entity.$snapshot.acquiring_methods?.length > 0,
            ),
            tap((entity: EntData) => {
                this.pay(entity, forceMethodType);
                return;
            }),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );
    }

    getTransactionsList$(entity): Observable<EntList> {
        return this.entityService.getEntityList$('payment.payment_acquiring', {
            params: { filter: buildFilterStr({ entity_id: entity.id }) },
            limit: 100,
        });
    }

    pay(entity, method = 'URL') {
        if (method === 'URL') {
            this._payByUrl(entity);
        }
    }

    protected _payByUrl(entity) {
        const url = entity.$snapshot.acquiring_methods.find((v) => v.type === 'URL')?.value;

        // TODO: show toast with absent url error
        if (!url) return;

        window.location.href = url;
    }
}
