import { Injectable } from '@angular/core';
import { ContextMenu, ContextMenuOptions } from '@bazis/shared/models/context-menu.types';
import { v4 as uuidv4 } from 'uuid';
import { TemplateObservable } from '@bazis/shared/classes/template-observable';

@Injectable({
    providedIn: 'root',
})
export class BazisContextMenuService {
    private _defaultParams: ContextMenuOptions = {
        component: null,
        componentProperties: null,
        keyboardClose: true,
        cssClass: '',
        id: '',
    };

    menus = new TemplateObservable([]);

    constructor() {}

    create(params: Partial<ContextMenuOptions>) {
        const contextMenuParams: ContextMenuOptions = {
            ...this._defaultParams,
            ...params,
            id: params.id || uuidv4(),
        };

        const contextMenu: ContextMenu = {
            options: contextMenuParams,
            onDidDismiss: () => {
                return new Promise((resolve) => {
                    contextMenu.dismiss = resolve;
                });
            },
        };

        const menus = this.menus._;
        menus.push(contextMenu);

        this.menus.set(menus);

        return contextMenu;
    }

    dismiss(result = null, contextMenu = null) {
        // console.log(result);
        let menus = this.menus._;
        if (!contextMenu && menus.length > 0) contextMenu = menus[menus.length - 1];

        if (!contextMenu) return;
        if (contextMenu.dismiss) contextMenu.dismiss(result);
        menus = menus.filter((v) => v.options.id !== contextMenu.options.id);
        this.menus.set(menus);
    }
}
