import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ConfigurationService } from '@app/configuration.service';

@Component({
    selector: 'app-profile-requisites',
    templateUrl: './requisites.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequisitesComponent {
    @Input()
    organizationSnapshot;

    emptyInputValue = this.configurationService.emptyInputValue;
    constructor(private configurationService: ConfigurationService) {}

    ngOnInit() {}
}
