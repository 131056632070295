import { ChangeDetectionStrategy, Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BazisMediaQueryService } from '@bazis/shared/services/media-query.service';
import { BazisModalService } from '@bazis/shared/services/modal.service';

@Component({
    selector: 'bazis-dummy-modal',
    templateUrl: './dummy-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DummyModalComponent implements OnInit, OnDestroy {
    @HostListener('window:resize') onResize() {
        if (this.mqSrv.matchBreakpoint('xl')) {
            this.modalService.dismiss();
        }
    }

    constructor(
        private modalService: BazisModalService,
        private mqSrv: BazisMediaQueryService,
        private router: Router,
    ) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}

    close() {
        if (this.mqSrv.matchBreakpoint('xl')) {
            this.modalService.dismiss();
        }
        if (this.mqSrv.inIntervalBreakpoint('xs', 'xl')) {
            this.router.navigate(['/'], {
                replaceUrl: true,
            });
            this.modalService.dismiss();
        }
    }
}
