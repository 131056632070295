import { ChangeDetectionStrategy, Component, Input, Self, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, merge, shareReplay } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { ValidationErrorMessages } from '@bazis/form/models/form-element.types';
import { SHARE_REPLAY_SETTINGS } from '@bazis/configuration.service';

@UntilDestroy()
@Component({
    selector: 'bazis-form-error',
    templateUrl: './form-error.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisFormErrorComponent implements ControlValueAccessor, OnInit {
    /* если надо переопределить текст ошибки
     * предполагается в формате
     * {required: 'Custom error text', email: 'custom email error text'}
     */
    @Input() validationErrorMessages: ValidationErrorMessages = {};

    get errors(): string[] {
        return this.ngControl.control.errors ? Object.keys(this.ngControl.control.errors) : [];
    }

    constructor(@Self() public ngControl: NgControl) {
        ngControl.valueAccessor = this;
    }

    ngOnInit() {}

    public registerOnChange(fn: any): void {}

    public registerOnTouched(fn: any): void {}

    public setDisabledState(isDisabled: boolean): void {}

    public writeValue(value: any): void {}

    public markAsTouched() {}
}
