import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { ConfigurationService } from '@app/configuration.service';
import { map, tap } from 'rxjs/operators';
import { buildFilterStr } from '@bazis/utils';
import { SearchSettings } from '@bazis/shared/models/srv.types';
import { TemplateObservable } from '@bazis/shared/classes/template-observable';

@Component({
    selector: 'app-profile-licenses',
    templateUrl: './licenses.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LicensesComponent implements OnInit {
    @Input()
    orgOwnerId = null;

    searchSettings: SearchSettings;

    emptyInputValue = this.configurationService.emptyInputValue;

    licenses$;

    isLoading = new TemplateObservable(true);

    constructor(
        private entityService: BazisEntityService,
        private configurationService: ConfigurationService,
    ) {}

    ngOnInit() {
        const filter: any = { status: 'active' };
        // const filterOrgOwnerId: any = { org_owner: this.orgOwnerId };
        if (this.orgOwnerId) filter.org_owner = this.orgOwnerId;

        this.searchSettings = {
            entityType: 'license.license_address',
            params: { filter: buildFilterStr(filter) },
        };

        this.licenses$ = this.entityService
            .getEntityList$('license.license', {
                params: { filter: buildFilterStr(filter) },
                limit: 100,
            })
            .pipe(
                tap(() => {
                    this.isLoading.set(true);
                }),
                map((v) => v.list),
                tap(() => {
                    this.isLoading.set(false);
                }),
            );
    }
}
