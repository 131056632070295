<div class="testModal">test modal with title as &#64;Input() "{{ title }}"</div>
<div class="testModal">test modal with message as &#64;Input() "{{ message }}"</div>
<button (click)="closeModal()">
    close with result from component, that was opened inside modal
</button>

<!-- <div class="bazis-modal-wrap">
    <div class="bazis-modal__header">
        <h1>Заголовок модального окна</h1>
    </div>

    <div class="bazis-modal__content">
        Тело модального окна
    </div>

    <div class="bazis-modal__footer">
        Подвал модального окна
    </div>
</div> -->
