import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-profile-okveds',
    templateUrl: './okveds.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OkvedsComponent {
    @Input()
    organizationSnapshot;
    showAllOkveds = false;
    constructor() {}

    ngOnInit() {}
}
