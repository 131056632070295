<ng-container *ngIf="settings.mode === 'full' && groupData">
    <div
        *ngFor="let item of groupData"
        class="bazis-percentage-block"
        [class.bazis-percentage-block--active]="
            selectedValues && selectedValues.indexOf(item.id) > -1
        "
        (click)="select.emit(item.id)"
    >
        <div class="bh-flex bh-justify-content-between bh-margin-bottom-1x">
            <span class="bazis-percentage-block__count">{{
                item.value | localNumber : '1.0-3'
            }}</span>
            <span class="bazis-percentage-block__percent bazis-h6 bh-no-margin">
                {{ item.fraction | localNumber : '1.0-2' }}%
            </span>
        </div>
        <div class="bh-ellipsis">
            {{ item.name || ('analytics.groups.emptyName' | transloco) }}
        </div>
        <ng-container
            [ngTemplateOutlet]="bar"
            [ngTemplateOutletContext]="{
                fraction: item.fraction
            }"
        ></ng-container>
    </div>
    <ng-container
        *ngIf="groupData?.length === 0"
        [ngTemplateOutlet]="empty"
    >
    </ng-container>
</ng-container>

<ng-container *ngIf="settings.mode === 'short' && groupData">
    <div
        *ngFor="let item of groupData; let index = index"
        class="bazis-percentage-block"
        [class.bazis-percentage-block--active]="
            selectedValues && selectedValues.indexOf(item.id) > -1
        "
        (click)="select.emit(item.id)"
        #refTooltip
    >
        <div class="bh-flex bh-justify-content-between">
            <span class="bh-ellipsis">
                {{ item.name || ('analytics.groups.emptyName' | transloco) }}
            </span>
            <span>{{ item.value | localNumber : '1.0-3' }}</span>
        </div>
        <ng-container
            [ngTemplateOutlet]="bar"
            [ngTemplateOutletContext]="{
                fraction: item.fraction
            }"
        ></ng-container>

        <bazis-tooltip
            *ngIf="item.name"
            [reference]="refTooltip"
            [placement]="index === 0 ? 'bottom' : 'top'"
            [arrow]="false"
            [isStatic]="true"
            [offset]="[0, -12]"
        >
            {{ item.name }}
        </bazis-tooltip>
    </div>
    <ng-container
        *ngIf="groupData?.length === 0"
        [ngTemplateOutlet]="empty"
    >
    </ng-container>
</ng-container>

<ng-template
    #bar
    let-fraction="fraction"
>
    <div class="bazis-percentage-bar_mini">
        <div
            class="bazis-percentage-bar__part"
            [class.bazis-percentage-bar__part_full]="fraction === 1"
            color="action"
            [style.width]="fraction + '%'"
            [style.--min-width]="fraction ? '1px' : '0'"
        ></div>
    </div>
</ng-template>

<ng-template #empty>
    <div *transloco="let t"> {{ t('analytics.groups.empty') }}</div>
</ng-template>
