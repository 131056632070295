<ng-container *ngIf="isPreview; else tplGallery">
    <div
        class="bazis-gallery-preview"
        [ngClass]="cssClass"
    >
        <bazis-skeleton [inMedia]="true"></bazis-skeleton>
    </div>
</ng-container>

<ng-template #tplGallery>
    <div
        class="bazis-list bazis-list_gallery"
        [ngClass]="cssClass"
    >
        <div class="bazis-item">
            <div class="bazis-figure">
                <bazis-skeleton [inMedia]="true"></bazis-skeleton>
            </div>
        </div>
    </div>
</ng-template>
