import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { EntityFormControl, EntityFormGroup, FormFieldConfig } from '@bazis/form/models/form.types';
import { BazisFormService } from '@bazis/form/services/form.service';
import { BazisModalService } from '@bazis/shared/services/modal.service';
import {
    BehaviorSubject,
    merge,
    Observable,
    of,
    shareReplay,
    switchMap,
    withLatestFrom,
} from 'rxjs';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { FormControl, Validators } from '@angular/forms';
import { catchError, debounceTime, filter, map, tap } from 'rxjs/operators';
import { SHARE_REPLAY_SETTINGS } from '@app/configuration.service';
import { TemplateObservable } from '@bazis/shared/classes/template-observable';
import { DateValidators } from '@bazis/form/validators/dateValidators';
import { EntData } from '@bazis/shared/models/srv.types';
import { BazisSrvService } from '@bazis/shared/services/srv.service';
import { BazisToastService } from '@bazis/shared/services/toast.service';
import moment from 'moment';

@Component({
    selector: 'app-modal-report',
    templateUrl: './modal-report.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [BazisFormService],
})
export class ModalReportComponent implements OnInit {
    reports$: Observable<EntData[]> = this.entityService
        .getEntityList$('analytic.analytics.analytical_report_template', { limit: 100 })
        .pipe(
            map((v) => v.list),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );

    maxDate = moment().hours(0).minutes(0).seconds(0).milliseconds(0).format('YYYY-MM-DD');

    fieldConfigs: { [index: string]: FormFieldConfig } = {
        dt: {
            titleKey: 'analytics.modal.dateGenerateReport',
            anySettings: {
                maxDate: this.maxDate,
            },
        },
        dt_start: {
            anySettings: {
                maxDate: this.maxDate,
            },
        },
        dt_finish: {
            anySettings: {
                maxDate: this.maxDate,
            },
        },
        region: {
            titleKey: 'analytics.modal.region',
            anySettings: {
                searchSettings: {
                    entityType: 'classifier.region',
                    params: {
                        sort: 'name',
                    },
                },
                multiple: false,
            },
        },
        loading_region: {
            titleKey: 'analytics.modal.regionLoading',
            anySettings: {
                searchSettings: {
                    entityType: 'classifier.region',
                    params: {
                        sort: 'name',
                    },
                },
                multiple: false,
            },
        },
        unloading_region: {
            titleKey: 'analytics.modal.regionUnloading',
            anySettings: {
                searchSettings: {
                    entityType: 'classifier.region',
                    params: {
                        sort: 'name',
                    },
                },
                multiple: false,
            },
        },
    };

    reportType = new FormControl(null);

    isDateRequired = false;

    required$ = of(true);

    form$ = this.reportType.valueChanges.pipe(
        withLatestFrom(this.reports$),
        map(([report, reports]) => {
            const reportEntity = reports.find((v) => v.id === report);
            const form = new EntityFormGroup({});
            Object.keys(this.fieldConfigs)
                .filter((field) => !!reportEntity.$snapshot[field])
                .forEach((field) => {
                    const control = new EntityFormControl();
                    control.$config = this.fieldConfigs[field];
                    if (reportEntity.$snapshot[field] === 'required') {
                        control.$config.required = new TemplateObservable(true);
                        control.addValidators(Validators.required);
                    }
                    form.addControl(field, control);
                });

            this.isDateRequired =
                ['dt', 'dt_start', 'dt_finish'].findIndex(
                    (field) => reportEntity.$snapshot[field] === 'required',
                ) > -1;

            if (reportEntity.$snapshot.dt_start && reportEntity.$snapshot.dt_finish) {
                form.addValidators(
                    DateValidators.intervalDateTwoControlValidatorFn(
                        'dt_start',
                        'dt_finish',
                        'YYYY-MM-DD',
                    ),
                );
            }
            return form;
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    changes$ = this.form$.pipe(
        switchMap((form) => merge(form.statusChanges, form.valueChanges)),
        debounceTime(0),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    generate$ = new BehaviorSubject(false);

    generation$ = this.generate$.pipe(
        filter((v) => !!v),
        withLatestFrom(this.form$),
        switchMap(([v, form]) => {
            const value = Object.keys(form.controls).reduce((acc, fieldKey) => {
                if (form.controls[fieldKey].value !== null)
                    acc[fieldKey] = form.controls[fieldKey].value;
                return acc;
            }, {});
            return this.srv
                .downloadFile$(
                    `analytic/analytics/analytical_report_template/${this.reportType.value}/download_report`,
                    'report.xlsx',
                    value,
                )
                .pipe(
                    tap((v) => {
                        this.generate$.next(false);
                    }),
                    catchError((e) => {
                        this.generate$.next(false);
                        // this.toastService.create({
                        //     type: 'error',
                        //     titleKey: 'toast.analyticReportError.title',
                        //     messageKey: 'toast.analyticReportError.message',
                        //     messageParams: { message: this.srv.generateErrorMessage(e) },
                        // });
                        return of(null);
                    }),
                );
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    constructor(
        private modalService: BazisModalService,
        public formService: BazisFormService,
        public entityService: BazisEntityService,
        public toastService: BazisToastService,
        public srv: BazisSrvService,
    ) {}

    ngOnInit() {}

    close() {
        this.modalService.dismiss();
    }
}
