import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'dateDiff',
})
export class DateDiffPipe implements PipeTransform {
    transform(from: string, to: string, fromFormat: string = '', toFormat: string = ''): number {
        if (!from || !to) return null;

        const fromMoment = moment(from, fromFormat);
        const toMoment = moment(to, toFormat);
        if (fromMoment.isValid() && toMoment.isValid())
            return moment.duration(toMoment.diff(fromMoment)).asDays();

        return null;
    }
}
