import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'bazis-trip-line',
    templateUrl: './trip-line.component.html',
    styleUrls: ['./trip-line.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class TripLineElement {
    @Input() hasArrow: boolean = false;

    @Input() hasPoints: boolean = true;

    @Input() onlyArrow: boolean = false;

    @Input() sign: string = '';

    constructor() {}
}
