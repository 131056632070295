<ng-container *transloco="let t">
    <ng-container *ngIf="{ address: licenseAddress$ | async } as data">
        <ng-container *ngIf="data.address">
            <p *ngIf="data.address.$snapshot?.address">
                <bazis-link
                    size="m"
                    (click)="toggleDetails()"
                    >{{ data.address.$snapshot.address || '??'
                    }}<bazis-icon
                        size="s"
                        [name]="showDetails ? 'angle-small-up' : 'angle-small-down'"
                        slot="end"
                    ></bazis-icon>
                </bazis-link>
            </p>
        </ng-container>

        <ng-container *ngIf="showDetails">
            <bazis-toolbar class="sl-toolbar_table">
                <form slot="start">
                    <bazis-input-default
                        [formControl]="searchControl"
                        placeholderKey="profile.personalInfo.licenses.placeholderSearch"
                        rightIcon="search"
                        isRightIconClickable="true"
                    ></bazis-input-default>
                </form>
            </bazis-toolbar>

            <app-list-structure
                [listSettings]="listSettings$ | async"
                listPaginationType="secondary"
                [singleInRowTpl]="singleInRowTpl"
                [singleTableHeader]="singleTableHeader"
                [singleTableSkeleton]="singleTableSkeleton"
                [singleInRowMobileTpl]="singleInRowMobileTpl"
                [showBreadcrumbs]="false"
                [showFilters]="false"
                [showSearch]="false"
                [showSortSelect]="false"
            ></app-list-structure>
        </ng-container>

        <ng-template
            #singleInRowTpl
            let-entity="entity"
        >
            <bazis-row>
                <bazis-col size="8">
                    <span
                        class="bh-text-dotted"
                        *ngIf="entity.fkko | entity | async as fkkoEntity; else tplLoading"
                    >
                        <span #tooltipTarget>
                            {{
                                t('profile.personalInfo.licenses.title.fkko', {
                                    number: fkkoEntity.$snapshot.number
                                })
                            }}
                            <bazis-tooltip
                                [reference]="tooltipTarget"
                                placement="bottom-start"
                                [isStatic]="true"
                                class="bh-popper-z-index"
                            >
                                {{ fkkoEntity.$snapshot.number }} -
                                {{ fkkoEntity.$snapshot.name }}
                            </bazis-tooltip>
                        </span>
                    </span>
                </bazis-col>
                <bazis-col size="16">
                    <div class="bh-flex bh-flex-wrap bh-justify-content-between">
                        <span
                            style="width: 33.33%"
                            class="bh-padding-bottom-3x"
                            *ngFor="let activity of entity.activities"
                        >
                            <bazis-icon
                                size="s"
                                name="check"
                                color="primary"
                            ></bazis-icon
                            >&nbsp;
                            {{ activity | enum : entity.type : 'activities' }}
                        </span>
                    </div>
                </bazis-col>
            </bazis-row>
        </ng-template>

        <ng-template
            #singleInRowMobileTpl
            let-entity="entity"
        >
            <app-profile-license-address-mobile
                [entity]="entity"
                class="sl-card-mobile-item"
            ></app-profile-license-address-mobile>
        </ng-template>

        <ng-template #singleTableHeader>
            <bazis-row class="sl-row_thead">
                <bazis-col
                    size="8"
                    class="sl-col_th"
                >
                    {{ t('profile.personalInfo.licenses.title.wasteType') }}
                </bazis-col>
                <bazis-col
                    size="16"
                    class="sl-col_th"
                >
                    {{ t('profile.personalInfo.licenses.title.activity') }}
                </bazis-col>
            </bazis-row>
        </ng-template>

        <ng-template #singleTableSkeleton>
            <bazis-row class="bh-hide-xl-down">
                <bazis-col size="8">
                    <bazis-skeleton></bazis-skeleton>
                </bazis-col>
                <bazis-col size="16">
                    <bazis-skeleton></bazis-skeleton>
                </bazis-col>
            </bazis-row>

            <div class="sl-card-mobile-item bh-hide-xl-up">
                <div class="sl-card-mobile">
                    <div class="sl-card-mobile__body">
                        <dl class="bazis-dl_easy">
                            <dt>{{ t('profile.personalInfo.licenses.title.wasteType') }}</dt>
                            <dd>
                                <bazis-skeleton></bazis-skeleton>
                            </dd>
                            <dt>{{ t('profile.personalInfo.licenses.title.activity') }}</dt>
                            <dd>
                                <bazis-skeleton></bazis-skeleton>
                            </dd>
                        </dl>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template #tplLoading>
            <bazis-skeleton></bazis-skeleton>
        </ng-template>
    </ng-container>
</ng-container>
