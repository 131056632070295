import {
    ViewEncapsulation,
    Component,
    Input,
    ViewChild,
    ElementRef,
    HostListener,
    Renderer2,
    HostBinding,
    Self,
    OnInit,
    AfterViewInit,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';

@Component({
    selector: 'bazis-range',
    templateUrl: './range.component.html',
    styleUrls: ['range.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class RangeElement implements OnInit, AfterViewInit {
    @ViewChild('range') rangeEl: ElementRef;

    // Minimum integer value of the range.
    @Input() min: number = 0;

    // Maximum integer value of the range.
    @Input() max: number = 100;

    @Input() step: number = 1;

    @Input() disabled: boolean = false;

    @Input() pin: boolean = true;

    @Input() pinDimension: string;

    @Input() value: number | string;

    // onChange = (_: any) => {};

    @HostListener('input', ['$event']) public handleInput(): void {
        this.setStyle(this.rangeEl.nativeElement.value);
        // this.onChange(this.rangeEl.nativeElement.value);
    }

    @HostBinding('style')
    style = {};

    constructor(private renderer: Renderer2) {}

    ngOnInit(): void {
        this.disabled = this.disabled !== undefined && this.disabled !== false;
        // this.checked = this.checked !== undefined && this.checked !== false;
    }

    ngAfterViewInit(): void {
        this.setStyle(this.rangeEl.nativeElement.value);
    }

    setStyle(value) {
        this.style = {
            '--value': value,
            '--min': this.min,
            '--max': this.max,
        };
    }

    // writeValue(value): void {
    //     this.value = value;
    //     this.setStyle(value);
    // }

    // public registerOnChange(fn: any): void {
    //     this.onChange = fn;
    // }

    // registerOnTouched(fn: any): void {}

    // setDisabledState?(isDisabled: boolean): void {}
}
