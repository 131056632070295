import { BehaviorSubject, Observable, shareReplay } from 'rxjs';

export class TemplateObservable<T> {
    private value$: BehaviorSubject<T>;

    private _value: T;

    $: Observable<T>;

    get _() {
        return this._value;
    }

    private set _(value) {
        this._value = value;
    }

    constructor(defaultValue: T = null, refCount = true) {
        this._value = defaultValue;
        this.value$ = new BehaviorSubject<T>(this._value);
        this.$ = this.value$.asObservable().pipe(shareReplay({ bufferSize: 1, refCount }));
    }

    set(value: T) {
        this._value = value;
        this.value$.next(this._value);
    }
}
