<div class="bazis-timepicker">
    <div class="bazis-timepicker__content">
        <span
            part="highlight"
            class="bazis-timepicker__highlight"
        >
        </span>
        <div class="bazis-timepicker__list-wrapper">
            <div class="bazis-timepicker__list">
                <div
                    #hContainer
                    class="bazis-time-container"
                    (scroll)="handleHHScroll($event)"
                >
                    <ul
                        #hList
                        part="hour-list"
                    >
                        <li
                            *ngFor="let item of hhArray; let i = index"
                            class="bazis-item"
                            [class.bazis-item--active]="item === hhValue"
                            (click)="scrollTo($event, i, 'hh')"
                        >
                            <span>{{ item }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div
            part="separator"
            class="bazis-timepicker__separator"
            >:</div
        >
        <div class="bazis-timepicker__list-wrapper">
            <div class="bazis-timepicker__list">
                <div
                    #mContainer
                    class="bazis-time-container"
                    (scroll)="handleMMScroll($event)"
                >
                    <ul
                        #mList
                        part="minute-list"
                    >
                        <li
                            *ngFor="let item of mmArray; let i = index"
                            class="bazis-item"
                            [class.bazis-item--active]="item === mmValue"
                            (click)="scrollTo($event, i, 'mm')"
                        >
                            <span>{{ item }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="scrollHH$ | async"></ng-container>
<ng-container *ngIf="scrollMM$ | async"></ng-container>
