import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SkeletonElement } from './skeleton.component';
import { createCustomElement } from '@angular/elements';

@NgModule({
    declarations: [SkeletonElement],
    imports: [CommonModule],
    exports: [SkeletonElement],
})
export class SkeletonModule implements DoBootstrap {
    constructor(private injector: Injector) {}

    ngDoBootstrap(): void {
        const el = createCustomElement(SkeletonElement, {
            injector: this.injector,
        });

        customElements.define('bazis-skeleton', el);
    }
}
