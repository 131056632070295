import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { ConfigurationService, SHARE_REPLAY_SETTINGS } from '@app/configuration.service';
import { Observable, debounceTime, map, shareReplay } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ListSettings } from '@bazis/list/models/list.types';
import { BazisListService } from '@bazis/list/services/list.service';
import { startWith } from 'rxjs/operators';

@Component({
    selector: 'app-profile-license-address',
    templateUrl: './license-address.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [BazisListService],
})
export class LicenseAddressComponent implements OnInit {
    @Input()
    id = null;

    emptyInputValue = this.configurationService.emptyInputValue;

    showDetails = false;

    searchControl = new FormControl('');

    listSettings$: Observable<ListSettings> = this.searchControl.valueChanges.pipe(
        debounceTime(500),
        startWith(''),
        map((search) => {
            const filter = { address: this.id, fkko__$search: search };
            return {
                entityType: 'license.license_waste',
                pagination: {
                    offset: 0,
                    limit: 20,
                    count: 0,
                },
                reflectInUrl: false,
                defaultSort: '-dt_created',
                defaultStaticFilter: '',
                sortSettings: [],
                staticFilters: [],
                filters: {},
                params: filter,
            };
        }),
    );

    licenseAddress$;

    constructor(
        private entityService: BazisEntityService,
        private configurationService: ConfigurationService,
        private listService: BazisListService,
    ) {}

    ngOnInit() {
        this.licenseAddress$ = this.entityService
            .getEntity$('license.license_address', this.id)
            .pipe(shareReplay(SHARE_REPLAY_SETTINGS));
    }

    trackById(index, entity) {
        return entity.id;
    }

    toggleDetails() {
        this.showDetails = !this.showDetails;
        this.searchControl.setValue('');
    }
}
