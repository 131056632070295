<div
    class="bazis-cookie"
    *transloco="let t"
>
    <div
        *ngIf="showCookie"
        class="bazis-container"
    >
        <div class="bazis-block bazis-block_shadow">
            <div class="bh-flex">
                <bazis-icon name="cookie" size="md"></bazis-icon>
                <div
                    [innerHTML]="t('cookie', {link})"
                ></div>
            </div>
            <bazis-button
                color="action"
                (click)="accept()"
            >
                {{ t('action.accept') }}
            </bazis-button>
        </div>
    </div>
</div>
