import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { DatepickerElement } from './datepicker.component';
import { createCustomElement } from '@angular/elements';
import { IconModule } from '@bazis/shared/components/icon/icon.module';
import { ButtonModule } from '@bazis/shared/components/button/button.module';
import { TranslocoModule } from '@jsverse/transloco';

@NgModule({
    declarations: [DatepickerElement],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        IconModule,
        ButtonModule,
        TranslocoModule,
    ],
    exports: [DatepickerElement],
})
export class DatepickerModule implements DoBootstrap {
    constructor(private injector: Injector) {}

    ngDoBootstrap(): void {
        const el = createCustomElement(DatepickerElement, {
            injector: this.injector,
        });

        customElements.define('bazis-datepicker', el);
    }
}
