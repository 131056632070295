import { Injectable } from '@angular/core';
import { ModalReportComponent } from '@pages/analytics/components/modal-report/modal-report.component';
import { BazisAnalyticService } from '@bazis/analytics/shared/service/analytic.service';

@Injectable({ providedIn: 'root' })
export class SwAnalyticService extends BazisAnalyticService {
    colors = {
        primary: '#00A2AD',
        secondary: '#43B1F2',
        tertiary: '#817AF9',
        action: '#164982',
        info: '#C4C4C4',
        success: '#78C649',
        warning: '#F6BF36',
        danger: '#ED1A34',
        light: '#f4f5f8',
        medium: '#92949c',
        dark: '#111214',
    };

    reports = [
        {
            buttonSettings: {
                titleKey: 'analytics.actions.generateReport',
                color: 'action',
            },
            component: ModalReportComponent,
            componentProperties: {},
        },
    ];
}
