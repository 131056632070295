<div
    *transloco="let t"
    class="bazis-modal-wrap"
>
    <div class="bazis-modal__content">
        <bazis-dummy-mobile></bazis-dummy-mobile>
    </div>
    <div class="bazis-modal__footer">
        <bazis-toolbar>
            <bazis-button
                slot="end"
                color="action"
                (click)="close()"
            >
                {{ t('action.close') }}
            </bazis-button>
        </bazis-toolbar>
    </div>
</div>
