import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import HPie from 'highcharts/modules/variable-pie';
import { AnalyticGroupData } from '@bazis/analytics/shared/models/analytic.types';
import { BazisAnalyticService } from '@bazis/analytics/shared/service/analytic.service';

HPie(Highcharts);

@Component({
    selector: 'bazis-analytics-status-circle-diagram',
    templateUrl: './status-circle-diagram.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisAnalyticsStatusCircleDiagramComponent implements OnInit {
    @Input()
    settings: any;

    @Input()
    groupData: AnalyticGroupData[];

    @Input()
    selectedValues: string[];

    @Output()
    select = new EventEmitter();

    COLORS = this.analyticsService.colors;

    get chart() {
        const chart = new Chart({
            chart: {
                height: 200,
                width: 200,
                spacingTop: 0,
                spacingRight: 0,
                spacingBottom: 0,
                spacingLeft: 0,
                margin: [0, 0, 0, 0],
            },
            tooltip: {
                enabled: true,
                headerFormat: '',
                pointFormat:
                    '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
                    '<span>{point.y}</span>',
            },
            title: {
                text: null,
            },
            legend: {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
            series: [
                {
                    id: 'statuses',
                    type: 'variablepie',
                    minPointSize: 10,
                    innerSize: '65%',
                    zMin: 0,
                    borderRadius: 5,
                    borderWidth: 2,
                    dataLabels: {
                        enabled: false,
                    },
                    allowPointSelect: true,
                    data: this.groupData?.map((item) => ({
                        id: item.id,
                        name: item.name,
                        y: +item.value,
                        z:
                            this.selectedValues && this.selectedValues.indexOf(item.id) > -1
                                ? 30
                                : 6,
                        selected: this.selectedValues && this.selectedValues.indexOf(item.id) > -1,
                    })),
                    point: {
                        events: {
                            click: (event) => {
                                this.select.emit((event.point as any)?.id);
                            },
                        },
                    },
                    colors: this.groupData?.map((item) => this.COLORS[item.color_label]),
                },
            ],
        });
        return chart;
    }

    constructor(private analyticsService: BazisAnalyticService) {}

    ngOnInit(): void {}
}
