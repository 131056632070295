import { OptionsGeneric, Modifier } from '@popperjs/core';
import { IConfig } from 'ngx-mask';
import { EntData } from '@bazis/shared/models/srv.types';

export type DatePrecision = 'years' | 'months' | 'days';
export type TimePrecision = 'hours' | 'minutes' | 'seconds';

export const DISPLAY_DATE_FORMATS = {
    years: 'YYYY',
    months: 'MM.YYYY',
    days: 'DD.MM.YYYY',
};
export const DISPLAY_TIME_FORMATS = {
    hours: 'HH',
    minutes: 'HH:mm',
    seconds: 'HH:mm:ss',
};
export const DISPLAY_DATE_TIME_FORMATS = {
    years: 'YYYY',
    months: 'MM.YYYY',
    days: 'DD.MM.YYYY',
    hours: 'DD.MM.YYYY HH',
    minutes: 'DD.MM.YYYY HH:mm',
    seconds: 'DD.MM.YYYY HH:mm:ss',
};

export const READONLY_DATE_FORMATS = {
    years: 'yyyy',
    months: 'MM.yyyy',
    days: 'dd.MM.yyyy',
    hours: 'dd.MM.yyyy HH',
    minutes: 'dd.MM.yyyy HH:mm',
    seconds: 'dd.MM.yyyy HH:mm:ss',
};

export const popperDateTimeOptions: Partial<OptionsGeneric<Partial<Modifier<any, any>>>> = {
    // options
    placement: 'bottom-start',
    strategy: 'fixed',
    modifiers: [
        {
            name: 'preventOverflow',
            options: {
                padding: { bottom: 300 },
            },
        },
        {
            name: 'flip',
            options: {
                padding: { bottom: 300 },
                fallbackPlacements: ['top-start', 'right', 'left', 'auto'],
            },
        },
        {
            name: 'offset',
            options: {
                offset: [0, 4],
            },
        },
        {
            name: 'computeStyles',
            options: {
                gpuAcceleration: false, // true by default
            },
        },
    ],
};

export type MaskSettings = Partial<IConfig> & {
    mask: string | undefined | null;
    pipeMask?: string | undefined | null;
};

export interface DateTimeintervalSettings {
    fromFieldName: string;
    toFieldName: string;
    inputFormat?: string;
    defaultInterval?: boolean;
}

export interface DateTimeintervalSettingsParts {
    fromDateFieldName: string;
    fromTimeFieldName: string;
    toTimeFieldName: string;
    dateFormat: string;
    timeFormat: string;
}

export interface DateIntervalSettingsParts {
    fromDateFieldName: string;
    toDateFieldName: string;
}

export interface TimeintervalSettingsParts {
    fromTimeFieldName: string;
    toTimeFieldName: string;
    timeFormat: string;
}

// Для input-file
export interface FileProgress {
    file: File;
    state: {
        progress: number;
        message: string;
        status: string;
        id?: string;
        ent?: EntData;
        attributes?: any;
    };
}

export type FileDisplayType = 'document' | 'image' | 'image-list' | 'default';

// настройки типов файлов и поддерживаемых расширений
export type FileType = 'any' | 'image' | 'word' | 'excel' | 'pdf' | 'text' | 'doc' | 'xml' | 'zip';
const FILE_IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'png'];
const FILE_WORD_EXTENSIONS = ['doc', 'docx', 'rtf'];
const FILE_EXCEL_EXTENSIONS = ['xls', 'xlsx'];
const FILE_TEXT_EXTENSIONS = ['txt'];
const FILE_PDF_EXTENSIONS = ['pdf'];
const FILE_XML_EXTENSIONS = ['xml'];
const FILE_ZIP_EXTENSIONS = ['zip'];
const FILE_DOC_EXTENSIONS = [...FILE_WORD_EXTENSIONS, ...FILE_PDF_EXTENSIONS];
export const FILE_EXTENSIONS = {
    image: FILE_IMAGE_EXTENSIONS,
    word: FILE_WORD_EXTENSIONS,
    excel: FILE_EXCEL_EXTENSIONS,
    pdf: FILE_PDF_EXTENSIONS,
    text: FILE_TEXT_EXTENSIONS,
    doc: FILE_DOC_EXTENSIONS,
    xml: FILE_XML_EXTENSIONS,
    zip: FILE_ZIP_EXTENSIONS,
    any: [
        ...FILE_DOC_EXTENSIONS,
        ...FILE_IMAGE_EXTENSIONS,
        ...FILE_EXCEL_EXTENSIONS,
        ...FILE_TEXT_EXTENSIONS,
    ],
};
// https://en.wikipedia.org/wiki/List_of_file_signatures - source for codes
export const EXTENTIONS_MIME_TYPES = {
    jpg: ['FFD8FF'],
    jpeg: ['FFD8FF'],
    png: ['89504E47'],
    tiff: ['4D4D002A'],
    tif: ['49492A00', '49492A0'],
    doc: ['D0CF11E0'],
    docx: ['504B0304', '504B34'],
    rtf: ['7B5C7274'],
    xls: ['D0CF11E0'],
    xlsx: ['504B0304', '504B34'],
    txt: ['464F524D', 'EFBBBF', 'FFFE', 'FEFF', '0000', '0EFEFF'],
    pdf: ['25504446'],
    xml: ['3C3F786D'],
    zip: ['504B0304', '504B34'],
};

export type ValidationErrorMessages = {
    [index: string]: string;
};

export type ContactsData = {
    first_name: string;
    last_name: string;
    middle_name?: string;
    phone: string;
    phone_additional?: string;
    position?: string;
    email: string;
};

export type ScheduleItemData = {
    work: {
        from: number;
        to: number;
    };
    rest: {
        from: number;
        to: number;
    };
};
export type Schedule = {
    mo?: ScheduleItemData;
    tu?: ScheduleItemData;
    we?: ScheduleItemData;
    th?: ScheduleItemData;
    fr?: ScheduleItemData;
    sa?: ScheduleItemData;
    su?: ScheduleItemData;
};

export type TooltipSettings = {
    targetSide: 'left' | 'right';
    eventType?: 'hover' | 'click';
    placement?:
        | 'auto'
        | 'auto-start'
        | 'auto-end'
        | 'top'
        | 'top-start'
        | 'top-end'
        | 'bottom'
        | 'bottom-start'
        | 'bottom-end'
        | 'right'
        | 'right-start'
        | 'right-end'
        | 'left'
        | 'left-start'
        | 'left-end';
};
