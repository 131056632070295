import { AbstractControl, ValidatorFn } from '@angular/forms';
import moment from 'moment';

export class DateValidators {
    public static invalidDateValidatorFn(format): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value) return null;
            const invalidDate = !moment(control.value, format, true).isValid();
            return invalidDate ? { invalidDate: { value: control.value } } : null;
        };
    }

    public static minDateValidatorFn(format, precision, minDate): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const controlValueFormatedDate = moment(control.value, format, true).format();
            const isMinDate =
                minDate && moment(minDate).isAfter(controlValueFormatedDate, precision);
            return isMinDate ? { isMinDate: { valid: control.value } } : null;
        };
    }

    public static minTimeValidatorFn(
        dateControl,
        dateFormat,
        timeFormat,
        datePrecision,
        timePrecision,
        minDate,
    ): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const controlValueFormatedDate = moment(dateControl.value, dateFormat, true).format();
            if (moment(minDate).isSame(controlValueFormatedDate, datePrecision)) {
                const controlValueFormatedTime = moment(
                    dateControl.value + 'T' + control.value,
                    dateFormat + 'T' + timeFormat,
                    true,
                ).format();
                const isMinTime =
                    minDate && moment(minDate).isAfter(controlValueFormatedTime, timePrecision);

                return isMinTime ? { isMinTime: { valid: control.value } } : null;
            }
            return null;
        };
    }

    public static maxDateValidatorFn(format, precision, maxDate): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const controlValueFormatedDate = moment(control.value, format, true).format();
            const isMaxDate =
                maxDate && moment(maxDate).isBefore(controlValueFormatedDate, precision);
            return isMaxDate ? { isMaxDate: { valid: control.value } } : null;
        };
    }

    // Валидатор интервала дат для двух полей
    public static intervalDateTwoControlValidatorFn(
        fieldKeyStart: string,
        fieldKeyEnd: string,
        format: string,
        validatorErr = 'invalidIntervalDate',
    ): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const invalidIntervalDate =
                control.value[fieldKeyEnd] !== null &&
                control.value[fieldKeyStart] !== null &&
                moment(control.value[fieldKeyEnd], format, true) <
                    moment(control.value[fieldKeyStart], format, true);
            return invalidIntervalDate ? { [`${validatorErr}`]: { value: control.value } } : null;
        };
    }

    // Валидатор интервала дат, для одного поля
    public static intervalDateValidatorFn(delimiter: string, dateFormat: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value) {
                return null;
            }
            const parts = control.value.split(delimiter);
            const errorValue = { invalidIntervalDate: { value: control.value } };

            let startDate: string = parts[0];
            let endDate: string = parts[1];
            if (
                startDate.replace(/_/g, '').replace(/\./g, '') === '' &&
                endDate.replace(/_/g, '').replace(/\./g, '') === ''
            ) {
                return null;
            }

            if (
                !startDate ||
                !endDate ||
                !moment(startDate, dateFormat).isValid() ||
                !moment(endDate, dateFormat).isValid()
            ) {
                return errorValue;
            }
            return moment(startDate, dateFormat).isAfter(moment(endDate, dateFormat))
                ? errorValue
                : null;
        };
    }

    public static intervalMinDateValidatorFn(
        delimiter: string,
        dateFormat: string,
        precision,
        minDate,
    ): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value) return null;
            const parts = control.value.split(delimiter);
            let startDate: string = parts[0];
            if (!moment(startDate, dateFormat).isValid()) return null;
            const controlValueFormatedDate = moment(startDate, dateFormat, true).format();
            const isMinDate =
                minDate && moment(minDate).isAfter(controlValueFormatedDate, precision);
            return isMinDate ? { isMinDate: { valid: control.value } } : null;
        };
    }

    public static intervalMaxDateValidatorFn(
        delimiter: string,
        dateFormat: string,
        precision,
        maxDate,
    ): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value) return null;
            const parts = control.value.split(delimiter);
            let endDate: string = parts[1];
            let startDate: string = parts[0];
            const validate = (str) => {
                const controlValueFormatedDate = moment(str, dateFormat, true).format();
                const isMaxDate =
                    maxDate && moment(maxDate).isBefore(controlValueFormatedDate, precision);
                return isMaxDate ? { isMaxDate: { valid: control.value } } : null;
            };
            if (!endDate || !moment(endDate, dateFormat).isValid()) {
                return validate(startDate);
            }
            return validate(endDate);
        };
    }

    public static invalidTimeValidatorFn(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const errorValue = { invalidTime: { value: control.value } };
            if (control.value === null || control.value === '') return null;
            let hh = control.value.substring(0, 2);
            let mm = control.value.substring(3, 5);
            if (hh === '__' && mm === '__') {
                return null;
            }
            const hhNumber = +hh;
            const mmNumber = +mm;
            if (isNaN(hhNumber) || isNaN(mmNumber) || hh.length < 2 || mm.length < 2) {
                return errorValue;
            }
            return hhNumber > 23 || mmNumber > 59 ? errorValue : null;
        };
    }

    public static intervalSeparateTimeValidatorFn(
        fieldKeyStart: string,
        fieldKeyEnd: string,
    ): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const errorValue = { invalidIntervalTime: { value: control.value } };
            let startH: number =
                control.value[fieldKeyStart] !== null
                    ? +control.value[fieldKeyStart].substring(0, 2)
                    : null;
            let startM: number =
                control.value[fieldKeyStart] !== null
                    ? +control.value[fieldKeyStart].substring(3, 5)
                    : null;
            let endH: number =
                control.value[fieldKeyEnd] !== null
                    ? +control.value[fieldKeyEnd].substring(0, 2)
                    : null;
            let endM: number =
                control.value[fieldKeyEnd] !== null
                    ? +control.value[fieldKeyEnd].substring(3, 5)
                    : null;
            const invalidIntervalTime =
                control.value[fieldKeyEnd] !== null &&
                control.value[fieldKeyEnd] !== '' &&
                control.value[fieldKeyStart] !== null &&
                control.value[fieldKeyStart] !== '' &&
                (endH < startH || (endH === startH && endM < startM));

            return invalidIntervalTime ? errorValue : null;
        };
    }

    public static intervalTimeValidatorFn(delimiter: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value) {
                return null;
            }
            const parts = control.value.split(delimiter);
            const errorValue = { invalidIntervalTime: { value: control.value } };
            if (parts.length !== 2) {
                return errorValue;
            }

            let startH = parts[0].substring(0, 2);
            let startM = parts[0].substring(3, 5);
            let endH = parts[1].substring(0, 2);
            let endM = parts[1].substring(3, 5);

            if (startH === '__' && startM === '__' && endH === '__' && endM === '__') {
                return null;
            }
            startH = +startH;
            startM = +startM;
            endH = +endH;
            endM = +endM;
            if (isNaN(startM) || isNaN(startH) || isNaN(endH) || isNaN(endM)) {
                return errorValue;
            }

            if (startH > 23 || endH > 23 || endM > 59 || startM > 59) {
                return errorValue;
            }

            return endH < startH || (endH === startH && endM < startM) ? errorValue : null;
        };
    }
}
