import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, shareReplay, combineLatest } from 'rxjs';
import { EntityFormControl } from '@bazis/form/models/form.types';
import { map, tap } from 'rxjs/operators';
import { SHARE_REPLAY_SETTINGS } from '@bazis/configuration.service';
import { AbstractControl } from '@angular/forms';
import { ViewportScroller } from '@angular/common';

export type EntityStatusListItem = {
    title?: string;
    titleKey?: string;
    titleKeyParam?: any;
    titleEntity?: TitleEntity;
    defaultTitle?: string;
    defaultTitleKey?: string;
    defaultTitleKeyParam?: any;
    controls?: (EntityFormControl | AbstractControl)[];
    isValid?: boolean;
    isRequired$?: Observable<boolean>;
    isRequired?: boolean;
    isEmpty?: boolean;
    anchor?: string;
    children?: EntityStatusListItem[];
};

export type TitleEntity = {
    id?: string;
    entityType?: string;
    titleField?: string;
    titleEntity?: TitleEntity;
};

@Component({
    selector: 'bazis-entity-status-list',
    templateUrl: './entity-status-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityStatusListComponent implements OnDestroy, OnInit {
    @Input()
    list$: Observable<EntityStatusListItem[]>;

    formattedList$;

    constructor(protected scrollerService: ViewportScroller) {}

    ngOnInit(): void {
        this.formattedList$ = this.list$.pipe(
            map((list) => {
                const formatList = (list, anchorStart = 'field') => {
                    return list.map((item, index) => {
                        let isRequired$ = of(false);
                        if (item.isRequired) {
                            isRequired$ = of(item.isRequired);
                        } else if (item.controls) {
                            isRequired$ = combineLatest([
                                ...item.controls.map(
                                    (control) => control?.$config?.required?.$ || of(false),
                                ),
                            ]).pipe(
                                map((arr: boolean[]) =>
                                    arr.reduce((acc, current) => acc || current, false),
                                ),
                            );
                        }

                        const anchor =
                            item.anchor !== undefined
                                ? item.anchor
                                : item.titleKey
                                ? anchorStart + '-' + item.titleKey.split('.').reverse()[0]
                                : anchorStart === 'field'
                                ? null
                                : anchorStart + '-' + index;

                        // TODO: clr after using in all lists

                        return {
                            ...item,
                            isValid:
                                item.isValid === undefined && item.controls
                                    ? item.controls.reduce(
                                          (acc, current) => acc && this.isValidControl(current),
                                          true,
                                      )
                                    : item.isValid,
                            isEmpty: item.controls
                                ? item.controls.reduce(
                                      (acc, current) => acc && this.isEmptyControl(current),
                                      true,
                                  )
                                : item.isEmpty,
                            isRequired$,
                            anchor,
                            children:
                                item.controls && item.children
                                    ? formatList(item.children, anchor)
                                    : item.children,
                        };
                    });
                };
                return formatList(list).filter(
                    (v) => !v.controls || v.controls.filter((v) => !!v).length > 0,
                );
            }),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );
    }

    isValidControl(control) {
        return !control || !!control.valid || control.disabled;
    }

    isEmptyControl(control) {
        if (!control) return false;
        const value = control.getRawValue();
        return Array.isArray(value)
            ? value.length === 0
            : (!value || JSON.stringify(value) === '{}') && value !== 0 && value !== false;
    }

    stepClicked(item) {
        if (!item.anchor) return;
        this.scrollerService.scrollToAnchor(item.anchor);
    }

    ngOnDestroy(): void {}
}
