import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'bazis-gallery-skeleton',
    templateUrl: './gallery-skeleton.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GallerySkeletonComponent implements OnInit {
    @Input() isPreview: boolean;

    @Input() cssClass: string;

    constructor() {}

    ngOnInit() {}
}
