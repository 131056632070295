import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'plural',
})
export class PluralPipe implements PipeTransform {
    transform(number: number): string {
        const tens = number % 100;
        if (tens > 4 && tens < 20) return 'many';
        const rest = number % 10;
        if (rest === 0 || rest > 4) return 'many';
        if (rest === 1) return 'one';
        return 'few';
    }
}
