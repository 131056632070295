<ng-container *transloco="let t">
    <section
        *ngIf="
            organizationSnapshot.main_okved?.length > 0 || organizationSnapshot.okveds?.length > 0
        "
    >
        <hr />
        <h6>
            {{ t('profile.personalInfo.organization.title.okveds') }}
        </h6>

        <div
            *ngIf="organizationSnapshot.main_okved?.length > 0"
            class="bazis-browse-default"
        >
            <div class="bazis-browse-default__title">
                {{ t('profile.personalInfo.organization.title.mainOkveds') }}
            </div>
            <dl class="bazis-dl_inline">
                <ng-container *ngFor="let okved of organizationSnapshot.main_okved">
                    <dt>{{ okved.code }}</dt>
                    <dd>{{ okved.name }}</dd>
                </ng-container>
            </dl>
        </div>

        <div
            *ngIf="organizationSnapshot.okveds?.length > 0"
            class="bazis-browse-default"
        >
            <div class="bazis-browse-default__title">
                {{ t('profile.personalInfo.organization.title.additionalOkveds') }}
            </div>
            <dl class="bazis-dl_inline">
                <ng-container *ngFor="let okved of organizationSnapshot.okveds; let i = index">
                    <ng-container *ngIf="showAllOkveds || i < 5">
                        <dt>{{ okved.code }}</dt>
                        <dd>{{ okved.name }}</dd>
                    </ng-container>
                </ng-container>
            </dl>
            <div
                *ngIf="organizationSnapshot.okveds.length > 5"
                class="bh-margin-top-4x"
            >
                <ng-container *ngIf="showAllOkveds">
                    <bazis-link (click)="showAllOkveds = false">{{
                        t('profile.personalInfo.organization.title.hideAllOkveds')
                    }}</bazis-link>
                </ng-container>
                <ng-container *ngIf="!showAllOkveds">
                    <bazis-link (click)="showAllOkveds = true">{{
                        t('profile.personalInfo.organization.title.showAllOkveds')
                    }}</bazis-link>
                </ng-container>
            </div>
        </div>
    </section>
</ng-container>
