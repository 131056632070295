import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ContactsData } from '@bazis/form/models/form-element.types';

@Component({
    selector: 'bazis-card-contact',
    templateUrl: './card-contact.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardContactComponent {
    @Input() contact: ContactsData;

    @HostBinding('class') hostClassComponent = 'bazis-contact';

    constructor() {}
}
