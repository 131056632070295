import { Directive, ElementRef, Output, EventEmitter, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[clickOutside]',
})
export class ClickOutsideDirective {
    @Input() excludeElement: ElementRef;

    @Input() excludeElements: ElementRef[];

    constructor(private _elementRef: ElementRef) {}

    @Output()
    public clickOutside = new EventEmitter();

    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement) {
        const clickedInside = this._elementRef.nativeElement.contains(targetElement);
        if (this.excludeElement && targetElement === this.excludeElement.nativeElement) {
            return;
        }
        if (
            this.excludeElements &&
            this.excludeElements.find((el) => el?.nativeElement === targetElement)
        ) {
            return;
        }
        if (!clickedInside) {
            this.clickOutside.emit(null);
        }
    }
}
