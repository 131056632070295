<ng-container *ngIf="{ loaders: loaderService.loaders.$ | async } as data">
    <div
        *ngIf="data.loaders.length > 0"
        class="bazis-loader"
    >
        <svg class="bazis-loader__spinner">
            <circle
                [attr.cx]="centerX"
                [attr.cy]="centerY"
                [attr.r]="radius"
                fill="none"
                stroke-width="1"
            ></circle>
        </svg>
        <p *ngFor="let loader of data.loaders">
            {{ loader.options.message }}
        </p>
    </div>
</ng-container>
