import { Directive, ElementRef, HostListener, NgZone, OnDestroy } from '@angular/core';
import { BehaviorSubject, fromEvent, Subscription, takeUntil } from 'rxjs';
import { tap, switchMap } from 'rxjs/operators';

@Directive({
    selector: 'input[type=number]',
})
export class FixNumberInputDirective implements OnDestroy {
    @HostListener('keydown', ['$event']) onKeyDown(ev) {
        this.fixingNumericFieldInFF(ev);
    }

    clipboardKeys$: BehaviorSubject<string> = new BehaviorSubject('');

    private subs = new Subscription();

    constructor(elRef: ElementRef<HTMLInputElement>, zone: NgZone) {
        const el = elRef.nativeElement;
        const focus$ = fromEvent(el, 'focus');
        const blur$ = fromEvent(el, 'blur');

        // when input is focused, start listening to the scroll of element. On this event blur and
        // re-focus on the next tick. This allows for the page scroll to still happen, but the unwanted
        // input number change is prevented.
        // Stop listening to the scroll when focus is lost
        const preventWheel$ = focus$.pipe(
            switchMap(() => {
                return fromEvent(el, 'wheel', { passive: false }).pipe(
                    tap(() => {
                        zone.runOutsideAngular(() => {
                            el.blur();
                            setTimeout(() => {
                                el.focus();
                            }, 0);
                        });
                    }),
                    takeUntil(blur$),
                );
            }),
        );

        this.subs.add(preventWheel$.subscribe());
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    private fixingNumericFieldInFF(ev) {
        // console.log(ev.key, ev);
        const regex = new RegExp(/(^[0-9\,\.\-]+$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/);

        this.limitDots(ev);
        this.fixNegativeNumber(ev);

        // originalTarget поддерживается только FF
        if (ev.originalTarget && ev.target.type === 'number' && ev.key && !ev.key.match(regex)) {
            ev.preventDefault();
        } else {
            this.clipboardKeys$.next(ev.key);
        }
    }

    private limitDots(ev) {
        const regexClipboard = new RegExp(/[\,\.]+$/);

        // console.log(ev.key, this.clipboardKeys$.value.match(regexClipboard));
        // если уже содержит точку или запятую, то не давать ввести новую точку или запятую
        if (
            ev.originalTarget &&
            ev.key &&
            ev.key.search(/[\,\.]+$/) !== -1 &&
            (ev.target.value.includes('.') ||
                ev.target.value.includes(',') ||
                this.clipboardKeys$.value.match(regexClipboard))
        ) {
            ev.preventDefault();
        }
    }

    private fixNegativeNumber(ev) {
        if (
            ev.originalTarget &&
            ev.key &&
            ev.key.search(/[\-]+$/) !== -1 &&
            (ev.target.value.includes('-') || this.clipboardKeys$.value === '-')
        ) {
            console.log('fixNegativeNumber', ev.target.value);
            ev.preventDefault();
        }
    }
}
