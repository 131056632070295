import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
    selector: '[scroll]',
})
export class ScrollDirective implements OnDestroy, OnInit {
    @Input()
    debouceTime = 100;

    @Input()
    bottomPercentage = 0.95;

    @Input()
    topPercentage = 0.05;

    @Output()
    public scrollToBottom = new EventEmitter<boolean>();

    @Output()
    public scrollToTop = new EventEmitter<boolean>();

    @Output()
    public scrollTopPosition = new EventEmitter<number>();

    onScrollBottom$: Subject<boolean> = new Subject();

    onScrollTop$: Subject<boolean> = new Subject();

    scrollPosition$: Subject<number> = new Subject();

    protected _prevScrollTop;

    @HostListener('scroll', ['$event'])
    private onScroll($event: Event) {
        const el = this.el.nativeElement;
        const top = Math.abs(el.scrollTop);
        const direction = top > this._prevScrollTop ? 'bottom' : 'top';
        this._prevScrollTop = top;
        if (el.scrollHeight <= el.offsetHeight) return;

        this.scrollPosition$.next(top);

        if (
            direction === 'bottom' &&
            el.scrollHeight - top - el.offsetHeight < el.offsetHeight * (1 - this.bottomPercentage)
        ) {
            this.onScrollBottom$.next(true);
            return;
        }
        if (direction === 'top' && top < el.offsetHeight * this.topPercentage) {
            this.onScrollTop$.next(true);
        }
    }

    constructor(private el: ElementRef) {}

    ngOnInit() {
        this.onScrollBottom$
            .pipe(
                debounceTime(100),
                tap(() => {
                    this.scrollToBottom.emit(true);
                }),
                untilDestroyed(this),
            )
            .subscribe();

        this.onScrollTop$
            .pipe(
                debounceTime(100),
                tap(() => {
                    this.scrollToTop.emit(true);
                }),
                untilDestroyed(this),
            )
            .subscribe();

        this.scrollPosition$
            .pipe(
                debounceTime(100),
                tap((v) => {
                    this.scrollTopPosition.emit(v);
                }),
                untilDestroyed(this),
            )
            .subscribe();
    }

    ngOnDestroy() {}
}
