import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { BazisToastService, TOAST_GROUPS } from '@bazis/shared/services/toast.service';
import { map } from 'rxjs/operators';

@Component({
    selector: 'bazis-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['toast.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent implements OnDestroy, OnInit {
    // TODO: подумать можно ли переделать в web-компонент

    groupNames = TOAST_GROUPS;

    currentGroups$ = this.toastService.toastGroups.$.pipe(
        map((groups) => {
            return this.groupNames.filter((group) => groups[group].length);
        }),
    );

    constructor(public toastService: BazisToastService) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}

    dismiss(id, group) {
        this.toastService.dismiss(id, group);
    }
}
