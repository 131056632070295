import { ViewEncapsulation, Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'bazis-skeleton',
    template: `<span>&nbsp;</span>`,
    styleUrls: ['skeleton.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class SkeletonElement {
    @Input() animated: boolean = true;

    @Input() animatedType?: 'shineLines' | 'shimmer' = 'shineLines';

    @Input() inline: boolean = false;

    @Input() inMedia: boolean = false;

    @Input() height: number | string;

    @Input() width: number | string;

    @Input() widthP: number | string;

    @Input() top: number | string;

    @Input() bottom: number | string;

    @HostBinding('class') get skeletonStyle() {
        return {
            ['bazis-skeleton--animated']: this.animated,
            ['bazis-skeleton_in-media']: this.inMedia,
            ['bazis-skeleton_inline']: this.inline,
            [`bazis-skeleton_${this.animatedType}`]: !!this.animatedType,
        };
    }

    @HostBinding('style.--margin-top') get topPosition() {
        if (this.top !== undefined) return `${this.top}px`;
    }

    @HostBinding('style.--margin-bottom') get bottomPosition() {
        if (this.bottom !== undefined) return `${this.bottom}px`;
    }

    @HostBinding('style.--height') get heightSize() {
        if (this.height !== undefined) return `${this.height}px`;
    }

    @HostBinding('style.--width') get widthSize() {
        if (this.width !== undefined) return `${this.width}px`;
    }

    @HostBinding('style.--width-percent') get widthSizeInPercent() {
        if (this.widthP !== undefined) return `${this.widthP}%`;
    }

    constructor() {}
}
