import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

export class EmailValidators {
    // add additional check for dot existance after @
    public static emailValidatorFn(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value) return null;

            let error = Validators.email(control);
            if (error) return error;

            return /\S+@\S+\.\S+/.test(control.value) ? null : { email: { value: control.value } };
        };
    }
}
