<div
    *transloco="let t"
    class="bazis-list sl-list_easy"
>
    <ng-container *ngIf="{ licenses: licenses$ | async, isLoading: isLoading.$ | async } as data">
        <ng-container *ngIf="!data.isLoading; else tplLoading">
            <div
                *ngFor="let license of data.licenses"
                class="bazis-item"
            >
                <h6>
                    {{
                        t('profile.personalInfo.licenses.title.license', {
                            number: license.$snapshot.number
                        })
                    }}
                </h6>
                <bazis-grid class="sl-grid_accordion">
                    <bazis-row>
                        <bazis-col
                            sizeXs="12"
                            sizeSm="6"
                        >
                            <bazis-browse-default
                                titleKey="profile.personalInfo.licenses.title.number"
                                [value]="license.$snapshot.number || emptyInputValue"
                            ></bazis-browse-default>
                        </bazis-col>
                        <bazis-col
                            sizeXs="12"
                            sizeSm="6"
                        >
                            <bazis-browse-default
                                titleKey="profile.personalInfo.licenses.title.status"
                                [value]="
                                    (license.$snapshot.status
                                        | enum : 'license.license' : 'status') || emptyInputValue
                                "
                            ></bazis-browse-default>
                        </bazis-col>
                    </bazis-row>
                    <bazis-row>
                        <bazis-col sizeXs="12">
                            <bazis-browse-default
                                titleKey="profile.personalInfo.licenses.title.issuer"
                                [value]="license.$snapshot.issuer || emptyInputValue"
                            ></bazis-browse-default>
                        </bazis-col>
                    </bazis-row>
                </bazis-grid>

                <section>
                    <hr />
                    <bazis-browse-default
                        titleKey="profile.personalInfo.licenses.title.addresses"
                    ></bazis-browse-default>
                    <bazis-loadmore-list
                        [searchSettings]="{
                            entityType: 'license.license_address',
                            params: { 'filter=license': license.id }
                        }"
                    >
                        <ng-template let-item="item">
                            <app-profile-license-address
                                [id]="item.id"
                            ></app-profile-license-address>
                        </ng-template>
                    </bazis-loadmore-list>
                </section>
            </div>
            <div *ngIf="data.licenses && data.licenses.length === 0">
                {{ t('profile.personalInfo.licenses.title.empty') }}
            </div>
        </ng-container>

        <ng-template #tplLoading>
            <div class="bazis-item">
                <h6>
                    <bazis-skeleton></bazis-skeleton>
                </h6>
                <bazis-grid class="sl-grid_accordion">
                    <bazis-row>
                        <bazis-col
                            sizeXs="12"
                            sizeSm="6"
                        >
                            <bazis-skeleton
                                [height]="16"
                                [width]="50"
                                [bottom]="4"
                            ></bazis-skeleton>
                            <bazis-skeleton
                                [height]="20"
                                [width]="200"
                            ></bazis-skeleton>
                        </bazis-col>
                        <bazis-col
                            sizeXs="12"
                            sizeSm="6"
                        >
                            <bazis-skeleton
                                [height]="16"
                                [width]="60"
                                [bottom]="4"
                            ></bazis-skeleton>
                            <bazis-skeleton
                                [height]="20"
                                [width]="200"
                            ></bazis-skeleton>
                        </bazis-col>
                    </bazis-row>
                    <bazis-row>
                        <bazis-col sizeXs="12">
                            <bazis-skeleton
                                [height]="16"
                                [width]="100"
                                [bottom]="4"
                            ></bazis-skeleton>
                            <bazis-skeleton
                                [height]="20"
                                [width]="300"
                            ></bazis-skeleton>
                        </bazis-col>
                    </bazis-row>
                </bazis-grid>
            </div>
        </ng-template>
    </ng-container>
</div>
