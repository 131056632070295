<div
    *ngIf="segmentList?.length"
    class="bazis-segment__content"
    [class.bazis-segment__content_equal]="isEqualParts"
    [class.bazis-segment__content_scrollable]="scrollable"
>
    <ng-container *ngFor="let segment of segmentList">
        <div
            *ngIf="
                !hideByZeroCount || segment.id === selectedSegment || segment.titleParams?.count > 0
            "
            class="bazis-segment__item"
            [ngClass]="{
                'bazis-segment__item--selected': segment.id === selectedSegment,
                hidden: segment.hidden,
                'bazis-segment__item--disabled': segment.disabled
            }"
            (click)="selectSegment(segment.id)"
        >
            <button
                part="native"
                class="bazis-btn-native"
                [disabled]="segment.disabled"
            >
                <ng-container
                    [ngTemplateOutlet]="segmentContent"
                    [ngTemplateOutletContext]="{
                        segment: segment
                    }"
                ></ng-container>
            </button>
            <div
                part="indicator"
                class="bazis-segment__indicator"
            >
                <div
                    part="indicator-background"
                    class="bazis-segment__indicator__bg"
                >
                </div>
            </div>
        </div>
    </ng-container>
</div>

<ng-template
    #segmentContent
    let-segment="segment"
>
    <span class="bazis-btn__inner">
        <bazis-icon
            *ngIf="segment.icon"
            [icon]="segment.icon"
        ></bazis-icon>
        <span *ngIf="segment.title">
            {{ segment.title }}
        </span>
        <span *ngIf="segment.titleKey && !segment.entityType">
            {{ segment.titleKey | transloco : segment.titleParams }}
        </span>
        <span *ngIf="segment.entityType && segmentTemplate">
            <ng-container
                [ngTemplateOutlet]="segmentTemplate"
                [ngTemplateOutletContext]="{
                    id: segment.id,
                    entityType: segment.entityType,
                    titleParams: segment.titleParams,
                    titleKey: segment.titleKey
                }"
            ></ng-container>
        </span>
    </span>
</ng-template>
