<div
    [ngClass]="[
        'bazis-loader_' + theme,
        type === 'inline' ? 'bazis-loader_inline' : 'bazis-loader',
        cssClass
    ]"
>
    <svg class="bazis-loader__spinner">
        <circle
            [attr.cx]="centerX"
            [attr.cy]="centerY"
            [attr.r]="radius"
            fill="none"
            stroke-width="1"
        ></circle>
    </svg>
    <p
        *ngIf="message"
        [innerHTML]="message"
    ></p>
</div>
