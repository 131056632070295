import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'bazis-layout-press-footer',
    templateUrl: './layout-press-footer.component.html',
    styleUrls: ['layout-press-footer.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutPressFooterElement implements OnInit {
    constructor() {}

    ngOnInit() {}
}
