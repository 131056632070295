<div
    class="bazis-modal-wrap"
    [class.bazis-modal--scrolling]="isScrollingContent"
    *transloco="let t"
>
    <div class="bazis-modal__header">
        <h1 class="bh-ellipsis">{{
            document ? document.$snapshot.name : t('document.modal.h1')
        }}</h1>
    </div>

    <div
        (scroll)="onContentScroll($event)"
        class="bazis-modal__content"
    >
        <bazis-document-accordeon
            [document]="document"
            [isOpen]="true"
        ></bazis-document-accordeon>

        <p class="bazis-p_major bh-margin-vertical-8x">
            <span
                [innerHTML]="
                    t('document.modal.description', {
                        documentName: document?.$snapshot?.name || documentName
                    })
                "
            ></span>
            <a [href]="'https://' + site">{{ site }}</a>
        </p>

        <div class="bazis-list bazis-list_e-sign">
            <div
                *ngFor="let signatory of signInfo.$snapshot.signatories"
                class="bazis-item"
            >
                <div class="bh-flex">
                    <span
                        class="bazis-e-sign"
                        color="primary"
                    >
                        <bazis-icon
                            size="s"
                            src="/assets/icons/other/e-sign.svg"
                        ></bazis-icon>
                    </span>
                    <dl class="bazis-dl_inline">
                        <dt>{{ t('document.modal.organization') }}</dt>
                        <dd>{{ signatory.attributes.org_owner.attributes.name }}</dd>
                        <dt>{{ t('document.modal.subscribed') }}</dt>
                        <dd>{{ signatory.attributes.author.attributes.username }}</dd>
                        <dt>{{ t('document.modal.certificate') }}</dt>
                        <dd>
                            <bazis-link
                                borderStyle="none"
                                target="_blank"
                                [href]="signatory.certificate.file"
                                download
                            >
                                <bazis-icon
                                    slot="start"
                                    name="download"
                                    size="s"
                                ></bazis-icon>
                                {{ t('action.download') }}
                            </bazis-link>
                        </dd>
                        <dt>{{ t('document.modal.docuSignDocument') }}</dt>
                        <dd>
                            <bazis-link
                                borderStyle="none"
                                target="_blank"
                                [href]="signatory.document.file"
                                download
                            >
                                <bazis-icon
                                    slot="start"
                                    name="download"
                                    size="s"
                                ></bazis-icon>
                                {{ t('action.download') }}
                            </bazis-link>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    </div>
</div>
