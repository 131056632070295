<div
    class="bazis-modal-wrap"
    *transloco="let t"
>
    <ng-container
        *ngIf="{
            items: itemsToSign$ | async,
            currentStep: step$ | async,
            indexToSign: indexToSign$ | async,
            filesIsUploaded: filesIsUploaded.$ | async
        } as data"
    >
        <div
            class="bazis-modal__header_shadow"
            *ngIf="data.currentStep < 4"
        >
            <h4>
                {{ t(data.items.length > 1 ? 'crypto.docsSigning' : 'crypto.docSigning') }}
            </h4>
        </div>
        <!-- STEP 1-->
        <ng-container *ngIf="data.currentStep === 0">
            <div class="bazis-modal__content">
                <ng-container *ngIf="filesIsUploaded._ !== data.items.length">
                    <bazis-default-loader
                        [message]="
                            t(data.items.length > 1 ? 'crypto.loadedDocs' : 'crypto.loadedDoc')
                        "
                    ></bazis-default-loader>
                </ng-container>
                <ng-container
                    *ngIf="filesIsUploaded._ === data.items.length && data.items.length > 1"
                >
                    <p class="bh-margin-bottom-5x">
                        {{ t(data.items.length > 1 ? 'crypto.checkDocs' : 'crypto.checkDoc') }}
                    </p>
                    <p class="bh-margin-bottom-3x bazis-p_minor bh-font-medium">
                        <span *ngIf="data.items.length > 1">
                            {{ t('crypto.files', { count: data.items.length }) }}:
                        </span>
                        <span *ngIf="data.items.length === 1"> {{ t('crypto.file') }}: </span>
                    </p>
                </ng-container>

                <div *ngFor="let item of data.items">
                    <ng-container *ngIf="item.document$ | async as doc">
                        <bazis-document-accordeon
                            [document]="doc"
                            *ngIf="filesIsUploaded._ === data.items.length && data.items.length > 0"
                        ></bazis-document-accordeon>
                    </ng-container>
                </div>
            </div>

            <div class="bazis-modal__footer">
                <bazis-toolbar>
                    <div slot="start">
                        <bazis-button
                            [fill]="mqSrv.matchBreakpoint('xs') ? 'outline' : 'clear'"
                            color="action"
                            (click)="close()"
                        >
                            {{ t('action.cancel') }}
                        </bazis-button>
                    </div>
                    <div
                        slot="end"
                        *ngIf="data.items.length > 1"
                    >
                        <div
                            [ngClass]="{
                                'bh-align-items-center bh-line-elements':
                                    mqSrv.matchBreakpoint('sm')
                            }"
                        >
                            <span
                                class="bazis-text-action bh-hide-sm-down bazis-p_minor"
                                color="success"
                                >{{ t('crypto.allRight') }},&nbsp;</span
                            >

                            <bazis-button
                                color="action"
                                [disabled]="data.filesIsUploaded !== data.items.length"
                                (click)="step$.next(1)"
                            >
                                {{ t('crypto.signWithDocuSign') }}
                            </bazis-button>
                        </div>
                    </div>
                </bazis-toolbar>
            </div>
        </ng-container>

        <ng-container *ngIf="data.currentStep === 1">
            <div class="bazis-modal__content">
                <div class="bh-margin-bottom-3x bh-font-medium">
                    <span *ngIf="data.items.length === 1">{{ t('crypto.file') }}</span>
                    <span *ngIf="data.items.length > 1">
                        {{
                            t('crypto.remainToSign', {
                                remain: data.items.length - data.indexToSign,
                                total: data.items.length
                            })
                        }}
                    </span>
                </div>

                <ng-container
                    *ngIf="data.items[data.indexToSign].document$ | async as doc; else loadingDoc"
                >
                    <ul class="bazis-list">
                        <li class="bazis-item">
                            <bazis-file-inline [file]="doc"></bazis-file-inline>
                        </li>
                    </ul>

                    <div
                        class="bazis-docusign"
                        [id]="'docusign-' + data.items[data.indexToSign].entityId"
                    >
                        <bazis-default-loader
                            [message]="t('crypto.docuSignPreparing')"
                        ></bazis-default-loader>
                    </div>
                    <ng-container *ngIf="signingDoc$ | async"></ng-container>
                </ng-container>

                <ng-template #loadingDoc> {{ t('crypto.docLoading') }}</ng-template>
            </div>
        </ng-container>

        <ng-container *ngIf="data.currentStep === 2">
            <div class="bazis-modal__content">
                <ng-container *ngIf="allChecked$ | async"></ng-container>

                <bazis-default-loader
                    [message]="t('crypto.waitDocuSignConfirmation')"
                ></bazis-default-loader>
            </div>
        </ng-container>
    </ng-container>
</div>
