<ng-container *transloco="let t">
    <div
        *ngIf="transit | transit | async as transitEntity"
        class="bazis-alert"
        [formGroup]="form"
    >
        <div class="bazis-alert__wrapper">
            <div
                *ngIf="transitEntity.icon"
                class="bazis-alert__icon"
            >
                <bazis-sign-status [color]="transitEntity.color">
                    <bazis-icon
                        slot="icon"
                        [icon]="transitEntity.icon"
                    ></bazis-icon>
                </bazis-sign-status>
            </div>

            <div class="bazis-alert__content">
                <div class="bazis-alert__title bh-margin-bottom-3x">
                    {{ transitEntity.name }}
                </div>
                <div
                    *ngIf="controlNames.length > 0"
                    class="bazis-alert__message"
                >
                    <ng-container *ngFor="let controlName of controlNames">
                        <section *ngIf="form.controls[controlName]">
                            <div *ngIf="dysplayPayloadMap[controlName].component === 'input'">
                                <bazis-input-default
                                    [title]="hint ? hint : dysplayPayloadMap[controlName].title"
                                    [required$]="controlRequirements[controlName]"
                                    [formControl]="form.controls[controlName]"
                                ></bazis-input-default>
                            </div>
                            <div *ngIf="dysplayPayloadMap[controlName].component === 'options'">
                                <bazis-input-options
                                    [title]="hint ? hint : dysplayPayloadMap[controlName].title"
                                    [multiple]="dysplayPayloadMap[controlName].isMultiple"
                                    [options]="dysplayPayloadMap[controlName].optionList"
                                    [required$]="controlRequirements[controlName]"
                                    [formControl]="form.controls[controlName]"
                                ></bazis-input-options>
                            </div>
                            <div
                                *ngIf="dysplayPayloadMap[controlName].component === 'file'"
                                class="bh-margin-top-6x"
                            >
                                <bazis-input-file
                                    [buttonKey]="'form.title.inputFile.atachFiles'"
                                    [directExtentions]="dysplayPayloadMap[controlName].extentions"
                                    [max]="dysplayPayloadMap[controlName].maxItems"
                                    [required$]="controlRequirements[controlName]"
                                    [formControl]="form.controls[controlName]"
                                ></bazis-input-file>
                            </div>
                        </section>
                    </ng-container>
                </div>

                <div
                    *ngIf="controlNames.length === 0"
                    class="bazis-alert__message"
                >
                    {{ hint || transitEntity.hint }}
                </div>

                <div class="bazis-alert__buttons">
                    <bazis-button
                        [color]="
                            transitEntity.color && transitEntity.color !== 'success'
                                ? transitEntity.color
                                : 'action'
                        "
                        (click)="send()"
                        [disabled]="form.invalid"
                    >
                        {{ t('action.confirm') }}
                    </bazis-button>
                    <bazis-button
                        fill="clear"
                        color="action"
                        (click)="cancel()"
                    >
                        {{ t('action.cancel') }}
                    </bazis-button>
                </div>
            </div>

            <button
                class="bazis-alert__close"
                (click)="cancel()"
            >
                <bazis-icon
                    name="cross-small"
                    size="xs"
                ></bazis-icon>
            </button>
        </div>
    </div>
</ng-container>
