import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'bazis-sign-status',
    template: `
        <slot name="icon"></slot>
        <slot name="loader"></slot>
    `,
    styleUrls: ['sign-status.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class SignStatusElement {
    constructor() {}
}
